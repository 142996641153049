<template>
  <div class="">
    <div class="position-fixed md-md-none colored-line z-2">
      <div class="line-1"></div>
      <div class="line-2"></div>
      <div class="line-3"></div>
      <div class="line-4"></div>
    </div>
    <div class="flexigo" :class="{ 'white-style': isWhite, 'in-frame': isInFrame }">
      <side-navigation v-if="!isInFrame" />
      <flexigo-loader :isLoading="isLoading" />
      <div v-show="!isLoading" class="main-content">
        <topbar v-if="!isInFrame" />
        <router-view class="content-area full-height-with-topbar" :class="{ 'm-0': isInFrame }" />
      </div>
    </div>
    <simple-dialog />
  </div>
</template>

<script>
import sideNavigation from '@/modules/Flexigo/components/navigation/side-menu.vue';
import topbar from '@/modules/Flexigo/components/navigation/topbar.vue';
import simpleDialog from '@/modules/Flexigo/components/simple-dialog/Dialog.vue';
import flexigoLoader from '../common/components/flexigoLoader.vue';
import loginRedirect from '../modules/Flexigo/screens/Login/loginRedirect';

export default {
  name: 'FlexigoShell',
  components: { sideNavigation, topbar, simpleDialog, flexigoLoader },
  mixins: [loginRedirect],

  data() {
    return {
      currentComponent: 'router-view',
    };
  },
  computed: {
    isWhite() {
      return this.$store.state.isBackgroundWhite;
    },
    isInFrame() {
      const registerMenuItems = ['SelectMainCampus', 'ApproveKvkk', 'HomeAddress'];
      if (registerMenuItems.includes(this.$route.name)) return true;
      return window.top !== window.self;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  async created() {
    console.log('Flexigo shell initiated');
    console.log(process.env.BASE_URL);
    try {
      this.$store.commit('SET_IS_LOADING', true);
      await this.$store.dispatch('SET_COMPANY_SETTINGS');
      await this.$store.dispatch('SET_USER_DETAILS');

      await Promise.allSettled([
        this.$store.dispatch('SET_CAMPUSES'),
        this.$store.dispatch('SET_MAIN_MENU', {
          lang: this.$i18n.locale,
          isRider: this.$store.getters.userDetails.isRider,
        }),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      this.$store.commit('SET_IS_LOADING', false);
    }
    const { userDetails } = this.$store.state;
    const redirectedPage = this.checkRiderRedirects(userDetails);
    if (redirectedPage) {
      this.$router.push({ name: redirectedPage });
    }
    // this.$store.dispatch('SET_WORKGROUPS');
  },
};
</script>

<style lang="scss">
@import '../sass/flexigo/base.scss';
.white-style {
  background-color: white;
  height: 100vh;
}
</style>
