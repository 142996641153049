<template>
  <div>
    <dashboard-admin v-if="isAdmin" />
    <dashboard v-else />
  </div>
</template>

<script>
import Dashboard from './Dashboard.vue';
import DashboardAdmin from './DashboardAdmin.vue';
import registrationService from '../../services/registration';

export default {
  components: { Dashboard, DashboardAdmin },
  data() {
    return {
      isAdmin: false,
    };
  },
  async mounted() {
    this.verify();
  },
  methods: {
    async verify() {
      try {
        this.$store.commit('SET_IS_LOADING', true);
        const result = await registrationService.verify();
        this.isAdmin = result.response.accountType === 'CORPORATE_ADMIN';
      } catch (error) {
        console.log('Error');
        console.log(error);
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
      }
    },
  },
};
</script>

<style></style>
