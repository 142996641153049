<template>
  <div class="mt-3 row">
    <div class="col devices pe-2">
      <div>
        <div class="box-header p-3">{{ $t('flexigo.dashboard.telematicsDevices') }}</div>
        <div class="box-content p-3">
          <div class="row devices-status">
            <div class="col">
              <div class="d-flex align-items-center justify-content-center">
                <div class="text-center">
                  <div class="dot green"></div>
                  <!-- <div class="device-count" :class="{'has-cursor-pointer underline': telematics.active}"
                       v-on="telematics.active ? { click: () => directVehicles('OK') } : {}">
                    {{ telematics.active }}
                  </div> -->
                  <div class="device-count">
                    {{ telematics.active }}
                  </div>
                  <div class="devices-status-desc">{{ $t('flexigo.common.active') }}</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="d-flex align-items-center justify-content-center">
                <div class="text-center">
                  <div class="dot gray"></div>
                  <!-- <div class="device-count" :class="{'has-cursor-pointer underline': telematics.noDevice}"
                       v-on="telematics.noDevice ? { click: () => directVehicles('CIHAZ_YOK') } : {}">
                    {{ telematics.noDevice }}
                  </div> -->
                  <div class="device-count">
                    {{ telematics.noDevice }}
                  </div>
                  <div class="devices-status-desc">{{ $t('flexigo.dashboard.noDevice') }}</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="d-flex align-items-center justify-content-center">
                <div class="text-center">
                  <div class="dot red"></div>
                  <!-- <div class="device-count" :class="{'has-cursor-pointer underline': telematics.noConnection}"
                       v-on="telematics.noConnection ? { click: () => directVehicles('BAGLANTI_YOK') } : {}">
                    {{ telematics.noConnection }}
                  </div> -->
                  <div class="device-count">
                    {{ telematics.noConnection }}
                  </div>
                  <div class="devices-status-desc">{{ $t('flexigo.dashboard.noConnection') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col actions ps-2">
      <div>
        <div class="box-header p-3">{{ $t('flexigo.dashboard.actionRequired') }}</div>
        <div class="box-content">
          <div class="row">
            <div class="col py-3 border-end">
              <div class="action-list ps-3">
                <table cellspacing="0" cellpadding="0" border="0">
                  <tbody>
                    <tr :class="{'has-cursor-pointer': actionsRequired.openTickets}"
                        v-on="actionsRequired.openTickets ? { click: () => direct('Tickets', 'nearbyStop') } : {}">
                      <td class="fitwidth text-end pe-2"><strong>{{ actionsRequired.openTickets || 0 }}</strong></td>
                      <td>{{ $t('flexigo.dashboard.openTickets') }}</td>
                    </tr>
                    <tr :class="{'has-cursor-pointer': actionsRequired.nearbyStopRequests}"
                        v-on="actionsRequired.nearbyStopRequests ? { click: () => direct('requestApprovalsGrid', 'nearbyStop') } : {}">
                      <td class="fitwidth text-end pe-2"><strong>{{ actionsRequired.nearbyStopRequests || 0 }}</strong></td>
                      <td>{{ $t('flexigo.dashboard.nearbyStopRequests') }}</td>
                    </tr>
                    <tr :class="{'has-cursor-pointer': actionsRequired.changeStopRequests}"
                        v-on="actionsRequired.changeStopRequests ? { click: () => direct('requestApprovalsGrid', 'routeChange') } : {}">
                      <td class="fitwidth text-end pe-2"><strong>{{ actionsRequired.changeStopRequests || 0 }}</strong></td>
                      <td>{{ $t('flexigo.dashboard.changeStopRequests') }}</td>
                    </tr>
                    <!-- <tr :class="{'has-cursor-pointer': actionsRequired.driversOverTheAgeLimit}"
                        v-on="actionsRequired.driversOverTheAgeLimit ? { click: () => direct('legacy') } : {}">
                      <td class="fitwidth text-end pe-2"><strong>{{ actionsRequired.driversOverTheAgeLimit  || 0 }}</strong></td>
                      <td>{{ $t('flexigo.dashboard.driversOverAgeLimit') }}</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col p-3">
              <div class="action-list">
                <table cellspacing="0" cellpadding="0" border="0">
                  <tbody>
                    <tr :class="{'has-cursor-pointer': actionsRequired.driverVehicleApproval}"
                        v-on="actionsRequired.driverVehicleApproval ? { click: () => direct('requestApprovalsGrid','vehicleDriverChange') } : {}">
                      <td class="fitwidth text-end pe-2"><strong>{{ actionsRequired.driverVehicleApproval || 0 }}</strong></td>
                      <td>{{ $t('flexigo.dashboard.driverVehicleApprovals') }}</td>
                    </tr>
                    <tr :class="{'has-cursor-pointer': actionsRequired.documentApprovals}"
                        v-on="actionsRequired.documentApprovals ? { click: () => direct('requestApprovalsGrid','documents') } : {}">
                      <td class="fitwidth text-end pe-2"><strong>{{ actionsRequired.documentApprovals || 0 }}</strong></td>
                      <td>{{ $t('flexigo.dashboard.documentApprovals') }}</td>
                    </tr>
                    <tr :class="{'has-cursor-pointer': actionsRequired.paymentApprovals}"
                        v-on="actionsRequired.paymentApprovals ? { click: () => direct('requestApprovalsGrid', 'payments') } : {}">
                      <td class="fitwidth text-end pe-2"><strong>{{ actionsRequired.paymentApprovals || 0 }}</strong></td>
                      <td>{{ $t('flexigo.dashboard.paymentApprovals') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DevicesAndActions',
  props: {
    telematics: {
      type: Object,
      required: true,
      default: () => {},
    },
    actionsRequired: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      window,
    };
  },
  methods: {
    direct(comp, tab) {
      window.open(this.$router.resolve({ name: comp, query: { tab, lang: this.$i18n.locale }, params: { route: 'app.route.drivers' } }).href, '_blank');
    },
    directVehicles(deviceStatus) {
      this.$route.query.deviceStatus = deviceStatus;
      window.open(this.$router.resolve({ name: 'legacy', query: this.$route.query, params: { route: 'app.route.vehicles' } }).href, '_blank');
    },
  },
};
</script>
<style scoped>
.devices > div, .actions > div{
   /* min-height: 172px; */
   border: 1px solid #e6eaee;
   border-radius: 4px;
   background-color: white;
}

.box-header{
   font-size: 1rem;
   font-weight: 600;
   border-bottom: 1px solid #e6eaee;
}
.devices-status > div > div{
   min-height: 86px;
   border: 1px solid #e6eaee;
   border-radius: 4px;
}

.dot{
   border-radius: 50%;
    width: 12px;
    height: 12px;
    display: inline-block;
}
.green {
   background-color: #4fde7b;
}
.gray {
   background-color: #7f858f;
}
.red {
   background-color: #fc4848;
}
.device-count{
   font-size: 18px;
   font-weight: 600;
}

.devices-status-desc{
   font-size: 12px;
   font-weight: 500;
}
.action-list{
   height: 87px;
   overflow: auto;
}

td.fitwidth {
    width: 3rem;
    white-space: nowrap;
}
</style>
