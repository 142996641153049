import Vue from 'vue';
import Router from 'vue-router';

import DashboardWrapper from './screens/Dashboard/DashboardWrapper.vue';
import FlexiVanpoolDashboard from './screens/Dashboard/FlexiVanpoolDashboard.vue';
import FlexigoDashboardUS from './screens/Dashboard/FlexiShuttleDashboardUs.vue';
// import FlexicarDashboard from './screens/Dashboard/FlexicarDashboard.vue';
import store from './store/store';
import LoginRedirector from './components/legacy-login-redirector';
import Login from './screens/Login/login.vue';
import { checkRiderRedirects } from './screens/Login/loginRedirect';
import { isEmpty } from '../../helpers/utility';

// import Test from './screens/Workgroups/Workgroups.vue';

Vue.use(Router);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/auth/update-password/:token',
    name: 'updatePassword',
    component: () => import(/* webpackChunkName: "auth" */ './screens/Login/new-password/UpdatePassword.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/shuttle/dahsboard',
    name: 'Home',
    component: DashboardWrapper,
  },
  {
    path: '/dashboard-us',
    name: 'FlexiShuttleDashboardUs',
    component: FlexigoDashboardUS,
  },
  {
    path: '/vanpool/dashboard',
    name: 'FlexiVanpoolDashboard',
    component: FlexiVanpoolDashboard,
  },
  {
    path: '/workgroup/:type?/:id',
    name: 'WorkgroupDetail',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/RoutesAndWorkgroupDetail.vue'),
  },
  {
    path: '/workgroups/:type?',
    name: 'Workgroups',
    component: () => import(/* webpackChunkName: "workgroups" */ './screens/Workgroups/Workgroups.vue'),
  },
  {
    path: '/onboarding/dashboard',
    name: 'OnboardingDashboard',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/Onboarding/Dashboard.vue'),
  },
  {
    path: '/company-list',
    name: 'CompanyList',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/Company/CompanyList.vue'),
  },
  {
    path: '/onboarding/register',
    name: 'OnboardingRegistration',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/Onboarding/Registration.vue'),
  },
  {
    path: '/onboarding/employees/:personnelId?',
    name: 'OnboardingEmployees',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/Onboarding/EmployeesWrapper.vue'),
  },
  {
    path: '/employee/register',
    name: 'employeeRegistration',
    component: () =>
      import(/* webpackChunkName: "onboarding" */ './screens/employeeRegistration/employeeRegistration.vue'),
  },
  {
    path: '/employee/dashboard',
    name: 'employeeDashboard',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/employeeRegistration/Dashboard.vue'),
  },
  {
    path: '/employee/setHome',
    name: 'employeeSetHome',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/employeeRegistration/homeAddress.vue'),
  },
  {
    path: '/employee/survey',
    name: 'employeesurvey',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/employeeRegistration/EmployeeSurvey.vue'),
  },
  {
    path: '/employee/editProfile',
    name: 'employeeeditProfile',
    component: () =>
      import(/* webpackChunkName: "onboarding" */ './screens/employeeRegistration/EmployeeEditProfile.vue'),
  },
  {
    path: '/employee/changePassword',
    name: 'employeechangePassword',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/employeeRegistration/ChangePassword.vue'),
  },
  {
    path: '/employee/updateHomeAddress',
    name: 'employeeupdateHomeAddress',
    component: () =>
      import(/* webpackChunkName: "onboarding" */ './screens/employeeRegistration/UpdateHomeAddress.vue'),
  },
  {
    path: '/onboarding/splash',
    name: 'OnboardingSplash',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/Onboarding/OptimizationSplash.vue'),
  },
  // deprecated, will be removed
  // {
  //   path: '/routeList',
  //   name: 'RouteList',
  //   component: () => import(/* webpackChunkName: "routes" */ './screens/RouteList/RouteList.vue'),
  // },
  {
    path: '/routeDetail/:id/:openEdit?',
    name: 'RouteDetail',
    component: () => import(/* webpackChunkName: "routes" */ './screens/RouteList/RouteDetail.vue'),
  },
  {
    path: '/employeeList',
    name: 'EmployeeList',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/EmployeeList/EmployeeList.vue'),
  },
  {
    path: '/splash',
    name: 'Splash',
    // route level code-splitting
    // this generates a separate chunk (test.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "other" */ './screens/Splash.vue'),
  },
  {
    path: '/routes/:type?',
    name: 'Routes',
    component: () => import(/* webpackChunkName: "routes" */ './screens/RoutesAndWorkgroupDetail.vue'),
  },
  {
    path: '/route-status/:type?',
    name: 'RouteStatuses',
    component: () => import(/* webpackChunkName: "routes" */ './screens/Routes/RouteStatuses.vue'),
  },
  {
    path: '/company/:id?/:subcompanyId?',
    name: 'Company',
    component: () => import(/* webpackChunkName: "company" */ './screens/Company/Company.vue'),
  },
  {
    path: '/carpool/user-status',
    name: 'CarpoolUserStatus',
    component: () => import(/* webpackChunkName: "carpool" */ './screens/Carpool/UserStatus.vue'),
  },
  {
    path: '/carpool/approved-matches',
    name: 'CarpoolApprovedMatches',
    component: () => import(/* webpackChunkName: "carpool" */ './screens/Carpool/ApprovedMatches.vue'),
  },
  {
    path: '/carpool/carpool-grid',
    name: 'carpoolGrid',
    component: () => import(/* webpackChunkName: "carpool" */ './screens/Carpool/carpoolGrid.vue'),
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: () => import(/* webpackChunkName: "tickets" */ './screens/Tickets/Tickets.vue'),
  },
  {
    path: '/survey',
    name: 'Survey',
    component: () => import(/* webpackChunkName: "survey" */ './screens/Survey/Survey.vue'),
  },
  {
    path: '/static/flexigo-app',
    name: 'FlexigoApp',
    component: () => import(/* webpackChunkName: "static" */ './components/static/flexigo-app.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/flexiride/reservation-confirmation/:id',
    name: 'ReservationConfirmation',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/ReservationConfirmation.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/flexiride/reservation-review/:id',
    name: 'RequestConfirmation',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/RequestConfirmation.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/flexiride/reservation-approve/:id',
    name: 'RequestApprove',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/RequestApprove.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/flexiride/planning',
    name: 'FlexiridePlanning',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/Planning.vue'),
  },
  {
    path: '/flexiride/requests',
    name: 'FlexirideRequests',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/Requests.vue'),
  },
  {
    path: '/flexiride/tracking',
    name: 'FlexirideTracking',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/Tracking/Tracking.vue'),
  },
  {
    path: '/drivers',
    name: 'DriverList',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Driver/DriverList.vue'),
  },
  {
    path: '/vehicles',
    name: 'VehicleList',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Vehicle/VehicleList.vue'),
  },
  {
    path: '/employeeList/fleximileage-grid',
    name: 'flexiMileageGrid',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/EmployeeList/flexiMileageGrid.vue'),
  },
  {
    path: '/requests/approvals-grid',
    name: 'requestApprovalsGrid',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/requestApprovals/requestApprovalsGrid.vue'),
  },
  {
    path: '/flexiride/dashboard',
    name: 'flexiRideDashboard',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Dashboard/flexiRideDashboard.vue'),
  },
  {
    path: '/flexiride/drivers-check-list',
    name: 'driversCheckList',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/driversCheckList.vue'),
  },
  {
    path: '/flexiride/drivers',
    name: 'FlexirideDrivers',
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Corporate/screens/Drivers.vue'),
  },
  {
    path: '/flexiride/vehicles',
    name: 'FlexirideVehicles',
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Corporate/screens/Vehicles.vue'),
  },

  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Login/register/register.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/sse-test',
    name: 'sseTest',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/sseTest.vue'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "legacy" */ './screens/Notifications/Notifications.vue'),
  },
  {
    path: '/legacy/:route',
    name: 'legacy',
    component: () => import(/* webpackChunkName: "legacy" */ './screens/LegacyScreen.vue'),
  },
  // {
  //   path: '/flexicar/dashboard',
  //   name: 'FlexicarDashboard',
  //   component: FlexicarDashboard,
  // },
  {
    path: '/flexicar/dashboard',
    name: 'FlexicarDashboard',
    //   component: FlexicarDashboard,
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Corporate/screens/Dashboard.vue'),
  },
  {
    path: '/flexicar/usages',
    name: 'FlexicarUsages',
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Flexigo/screens/Flexicar/Rentals.vue'),
  },
  {
    path: '/flexicar/drivers',
    name: 'FlexicarDrivers',
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Corporate/screens/Drivers.vue'),
  },
  {
    path: '/flexicar/vehicles',
    name: 'FlexicarVehicles',
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Flexigo/screens/Flexicar/Vehicles.vue'),
  },
  {
    path: '/flexicar/vehicle/:id',
    name: 'FlexicarVehicleDetail',
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Flexigo/screens/Flexicar/Vehicle.vue'),
    // meta: { requiresPermission: 'vehicles:view' },
  },
  {
    path: '/flexicar/parkings',
    name: 'FlexicarParkings',
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Carshare/screens/Parkings.vue'),
  },
  {
    path: '/flexicar/requests',
    name: 'FlexicarRequests',
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Flexigo/screens/Flexicar/Requests.vue'),
  },
  {
    path: '/flexicar/planning',
    name: 'FlexicarPlanning',
    component: () => import(/* webpackChunkName: "flexicar" */ '@/modules/Corporate/screens/Scheduler.vue'),
  },
  {
    path: 'optimization/detail/:id',
    name: 'OptimizationDetail',
    component: () => import(/* webpackChunkName: "optimization" */ './screens/Optimization/OptimizationDetail.vue'),
  },
  {
    path: '/user/change-password',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "user" */ './screens/User/ChangePassword.vue'),
  },
  {
    path: '/dispatch/:type?',
    name: 'Dispatch',
    component: () => import(/* webpackChunkName: "dispatch" */ './screens/Dispatch/liveTracking.vue'),
  },
  {
    path: '/reservations/:type?',
    name: 'Reservations',
    component: () => import(/* webpackChunkName: "dispatch" */ './screens/Routes/Reservations.vue'),
  },
  {
    path: '/driver-vehicle-matching',
    name: 'DriverVehicleMatching',
    component: () => import(/* webpackChunkName: "dispatch" */ './screens/DriverVehicleMatchings.vue'),
  },
  {
    path: '/flexiride/pool/requests',
    name: 'FlexiridePoolRequests',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/Pool/Requests.vue'),
  },
  {
    path: '/flexiride/pool/planning',
    name: 'FlexiRidePoolPlanning',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/Pool/Planning.vue'),
  },
  {
    path: '/flexiride/pool/tracking',
    name: 'FlexiRidePoolTracking',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Flexiride/Pool/Tracking.vue'),
  },
  {
    path: '/route/schedules',
    name: 'RouteSchedules',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Routes/RouteSchedules.vue'),
  },
  {
    path: '/route/search/:type?',
    name: 'RouteSearch',
    component: () => import(/* webpackChunkName: "flexiride" */ './screens/Routes/RouteSearch.vue'),
  },
  {
    path: '/login-redirector',
    name: 'LoginRedirector',
    component: LoginRedirector,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "onboarding" */ './screens/Reports/reportsHome.vue'),
  },
  // TODO: these routes should be moved to the rider and components should be child routes
  {
    path: '/rider/shared-ride',
    name: 'SharedRideRequest',
    component: () => import(/* webpackChunkName: "rider" */ './screens/Rider/sharedRide/SharedRideRequest.vue'),
  },
  {
    path: '/rider/my-address-list',
    name: 'MyAddressList',
    component: () => import(/* webpackChunkName: "rider" */ './screens/Rider/myAddress/MyAddressList.vue'),
  },
  {
    path: '/rider/feedback',
    name: 'FeedbackList',
    component: () => import(/* webpackChunkName: "rider" */ './screens/Rider/Feedback/FeedbackList.vue'),
  },
  {
    path: '/rider/home-address',
    name: 'HomeAddress',
    component: () => import(/* webpackChunkName: "rider" */ './screens/Rider/address/selectHomeAddress.vue'),
  },
  {
    path: '/rider/approve-kvkk',
    name: 'ApproveKvkk',
    component: () => import(/* webpackChunkName: "rider" */ './screens/Rider/kvkk/kvkkApproval.vue'),
  },
  {
    path: '/rider/select-campus',
    name: 'SelectMainCampus',
    component: () => import(/* webpackChunkName: "rider" */ './screens/Login/register/selectMainCampus.vue'),
  },

  // keep this route at the end
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      isPublic: true,
    },
  },
];

const router = new Router({
  // When history mode is enabled, per entrypoint routes and params wont work
  // to enable it you have to disable it, or maybe configure server
  // to use routes for specific files disable history mode and use /#/routes
  // mode: 'history',
  // base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes,
});

// set this to get the name from appName variable
router.beforeEach((to, from, next) => {
  const { userDetails } = store.state;
  if (!isEmpty(userDetails)) {
    const redirectRoute = checkRiderRedirects(userDetails);
    if (redirectRoute && redirectRoute !== to.name && redirectRoute !== from.name) {
      next({ name: redirectRoute });
      return;
    }
  }

  if (from.name === 'Company') {
    const { isUnsaved } = store.getters;
    if (isUnsaved) {
      store.commit('SET_ROUTE_NEXT', to);
      next(false);
    } else {
      store.commit('SET_ROUTE_NEXT', null);
      next();
    }
    return;
  }

  store.commit('SET_CURRENT_ROUTER_COMPONENT', to.name);
  if (to.meta.isPublic) {
    store.commit('SET_LAYOUT', 'blank');
  } else {
    store.commit('SET_LAYOUT', 'flexigo');
  }

  // this is will reinstated when localizations are ready
  // if (to.meta.name) document.title = `Flexigo - ${to.meta.name}`;
  next();
});

export default router;
