import { format, nextMonday, add, getDay, addDays } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { WALKING_DISTANCE_TRESHOLD } from '@/modules/Flexigo/helpers/constants';
import { formatNumber, isLocaleEnUs } from '../../../helpers/utility';
import { intToTime } from '../../../helpers/dateTime';

export function getEmissionType(rating) {
  if (rating <= 50) return 'a';
  if (rating <= 150) return 'b';
  if (rating <= 250) return 'c';
  if (rating <= 450) return 'd';
  if (rating <= 400) return 'e';
  if (rating <= 450) return 'f';
  if (rating > 450) return 'g';
  if (rating === null) return 'none';
  return 'none';
}

// DO NOT USE THIS FUNCTION, IT WILL BE REMOVED
export function getPersonnelMarkers(personnels, locations, personnelMarkerType = null) {
  personnels.forEach((el) => {
    let personnelType = null;
    if (el.station?.id) personnelType = 'green';
    if (!el.station?.id) personnelType = 'orange';
    if (el.station?.id && el.personnel.walkingDistanceInMeter > 500) personnelType = 'red';
    if (el.personnel?.isVanpoolDriver) personnelType = 'purple-dotted';
    if (personnelMarkerType) personnelType = personnelMarkerType;
    locations.push({
      position: {
        lat: Number(el.personnel?.homeLocation.latitude),
        lng: Number(el.personnel?.homeLocation.longitude),
      },
      icon: {
        url: `${process.env.BASE_URL}icons/oval-${personnelType}.svg`,
        // labelOrigin: new this.google.maps.Point(15, -5),
      },
      name: `${el.personnel.name} ${el.personnel.surname}`,
      routeId: el.route.id,
      stationId: el?.station?.id,
      personnelId: el.personnel.id,
      infoData: {
        serviceLine: el.route.name,
        station: el.station.description,
        walkingDistance: `${
          el.personnel.walkingDistanceInMeter ? el.personnel.walkingDistanceInMeter.toFixed(1) : '0'
        }`, // TODO: Company-settings
        walkingDistanceInMeter: el.personnel.walkingDistanceInMeter,
        //  this.$i18n.locale === 'tr' ? `${el.personnel.walkingDistanceInMeter ? (el.personnel.walkingDistanceInMeter / 1000).toFixed(1) : '0'} km` : `${(el.personnel.walkingDistanceInMeter / 1.609).toFixed(1)} mi`,
        destinationTravelTime: el.personnel.destinationTravelTimeInMin,
        timeCoefficient: el.personnel.timeCoefficient ? el.personnel.timeCoefficient.toFixed(2) : '',
      },
      animation: 2,
      isVanpoolDriver: el.personnel?.isVanpoolDriver,
    });
  });
}

function createPersonnelMarker(personnelData, personnelMarkerType = undefined) {
  if (!personnelData) return undefined;
  let personnelType = null;
  // if (personnelData?.personnel?.isVanpoolDriver) personnelType = 'purple-dotted';
  if (personnelData?.station?.id) personnelType = 'green';
  if (personnelData?.personnel?.walkingDistanceInMeter > WALKING_DISTANCE_TRESHOLD) personnelType = 'red';
  if (!personnelData?.station?.id) personnelType = 'orange';
  if (personnelMarkerType) personnelType = personnelMarkerType;

  return {
    position: {
      lat: Number(personnelData.personnel.homeLocation.latitude),
      lng: Number(personnelData.personnel.homeLocation.longitude),
    },
    icon: {
      url: `${process.env.BASE_URL}icons/oval-${personnelType}.svg`,
      // labelOrigin: new this.google.maps.Point(15, -5),
    },
    name: `${personnelData.personnel.name} ${personnelData.personnel.surname}`,
    routeId: personnelData.route.id,
    stationId: personnelData?.station?.id,
    personnelId: personnelData.personnel.id,
    infoData: {
      routeName: personnelData.route.name,
      stop: personnelData?.station?.description,
      distanceToStop: `${
        personnelData.personnel.walkingDistanceInMeter ? personnelData.personnel.walkingDistanceInMeter.toFixed(1) : '0'
      }`, // TODO: Company-settings
      walkingDistanceInMeter: personnelData.personnel.walkingDistanceInMeter,
      //  this.$i18n.locale === 'tr' ? `${personnel.walkingDistanceInMeter ? (personnel.walkingDistanceInMeter / 1000).toFixed(1) : '0'} km` : `${(personnel.walkingDistanceInMeter / 1.609).toFixed(1)} mi`,
      destinationTravelTime: `${personnelData?.personnel?.destinationTravelTimeInMin || ''}`,
      timeCoefficient: formatNumber(personnelData.personnel.timeCoefficient, navigator.language),
    },
    animation: 2,
    isVanpoolDriver: personnelData.personnel.isVanpoolDriver,
  };
}

export function createPersonnelMarkers(personnels, personnelMarkerType = undefined) {
  return personnels.map((el) => createPersonnelMarker(el, personnelMarkerType));
}

export function getDirectionIcon(direction) {
  let iconUrl = '';
  if (direction < 0) {
    iconUrl = '';
  }
  if (direction <= 22.5) {
    iconUrl = `${process.env.BASE_URL}icons/historyMarker/N.png`;
  } else if (direction <= 67.5) {
    iconUrl = `${process.env.BASE_URL}icons/historyMarker/NE.png`;
  } else if (direction <= 112.5) {
    iconUrl = `${process.env.BASE_URL}icons/historyMarker/E.png`;
  } else if (direction <= 157.5) {
    iconUrl = `${process.env.BASE_URL}icons/historyMarker/SE.png`;
  } else if (direction <= 202.5) {
    iconUrl = `${process.env.BASE_URL}icons/historyMarker/S.png`;
  } else if (direction <= 247.5) {
    iconUrl = `${process.env.BASE_URL}icons/historyMarker/SW.png`;
  } else if (direction <= 292.5) {
    iconUrl = `${process.env.BASE_URL}icons/historyMarker/W.png`;
  } else if (direction <= 337.5) {
    iconUrl = `${process.env.BASE_URL}icons/historyMarker/NW.png`;
  } else {
    iconUrl = `${process.env.BASE_URL}icons/historyMarker/N.png`;
  }
  return iconUrl;
}

export function getDateAndTime(miliSeconds, formatType = null) {
  if (!miliSeconds) return '';
  if (!miliSeconds.toString().includes(':')) {
    const date = new Date(miliSeconds);
    const defaultFormatType = isLocaleEnUs ? 'dd-MM-yyyy aa hh:mm:ss' : 'dd-MM-yyyy HH:mm:ss';
    return format(date, formatType || defaultFormatType);
  }
  return miliSeconds;
}

// TODO: Change function name
export function calculateDepartureTime(time) {
  if (!time) return undefined;
  const { hours, minutes } = intToTime(time);
  const date = nextMonday(new Date());
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

export function nextWeekTodayOrWeekday(time, timezone = 'UTC', date = new Date()) {
  const zonedDate = utcToZonedTime(date, timezone);
  let nextWeekDate = add(zonedDate, { weeks: 1 });
  const dayOfWeek = getDay(nextWeekDate);
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    nextWeekDate = addDays(nextWeekDate, 1);
  } else {
    nextWeekDate = addDays(nextWeekDate, -1);
  }
  const { hours, minutes } = intToTime(time);
  nextWeekDate.setHours(hours);
  nextWeekDate.setMinutes(minutes);
  return zonedTimeToUtc(nextWeekDate, timezone);
}
/**
 * Returns a coordinate object in the format {lat: number, lng: number}
 * from various location formats.
 * @param {Object} location
 * @return {Object}
 */
export function toLatLng(location) {
  const loc = location.location ?? location;
  if (loc?.lat && loc?.lng) return loc;
  return { lat: loc.latitude, lng: loc.longitude };
}

export function capitalize(string) {
  return `${string.charAt(0).toUpperCase()}${string.toLowerCase().slice(1)}`;
}

export function capitalizeWordsWithSpaces(string) {
  const words = string.toLowerCase().split(' ');
  const formattedName = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  return formattedName;
}
// returns the time for the status of the route step
export function getStepTime(data, step) {
  if (data[`actual${step}`]) return { estimated: false, time: data[`actual${step}`] };
  if (['ON_TIME', 'DELAYED'].includes(data[`${step.toLowerCase()}Status`]))
    return { estimated: 'eta', time: data[`eta${step}`] };
  return { estimated: 'planned', time: data[`planned${step}`] };
}

const validLanguages = ['en', 'tr', 'fr'];
export function languageSelector() {
  if (window.location.hostname.includes('secure.flexigo.com')) return 'tr';
  // check url for lang
  if (window.location.href.includes('lang')) {
    const lang = window.location.href.split('lang=')[1].substring(0, 2);
    console.log('setting lang to: ', lang);
    return lang;
  }
  // check browser language
  if (validLanguages.includes(navigator.language.substring(0, 2))) return navigator.language.substring(0, 2);

  return 'en';
}

export function doesMeetPasswordCriteria(password) {
  // Define the criteria
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

  const criteriaMet = [hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean).length;

  return criteriaMet >= 3;
}

export default getEmissionType;
