<template>
  <div class="card-container h-100">
    <div class="top-row d-flex align-items-center justify-content-center">
      <div class="text-center">
        <div :class="{'has-cursor-pointer': isEnableTopMetricDirection}"
             v-on="isEnableTopMetricDirection ? { click: () => $emit('top-metric-direction', topMetric) } : {}">
          <img :src="topIcon" class="mb-2">
          <div class="top-value" style=" overflow-wrap: anywhere; ">{{ topValue }}</div>
          <div class="top-metric text-secondary" style=" min-height: 2rem; ">{{ topMetric }}</div>
        </div>
      </div>
    </div>
    <hr class="line-style-border my-3">
    <div class="bottom-row d-flex align-items-center justify-content-center">
      <div class="text-center w-100">
        <div :class="{'has-cursor-pointer': isEnableBottomMetricDirection}"
             v-on="isEnableBottomMetricDirection ? { click: () => $emit('bottom-metric-direction', bottomMetric) } : {}">
          <img :src="bottomIcon" class="mb-2">
          <div class="bottom-value">{{ bottomValue }}</div>
          <div class="bottom-metric text-secondary"> {{ bottomMetric }}</div>
        </div>
        <a v-if="subTextMetric"
           class="sub-text-link text-decoration-none d-block"
           :class="{'has-cursor-pointer': isEnableSubTextMetricDirection}"
           v-on="isEnableSubTextMetricDirection ? { click: () => $emit('subtext-metric-direction') } : {}">
          <div class="d-flex justify-content-around">
            <span>{{ subTextMetric }}</span>
            <span class="right-arrow-after ms-1">{{ subTextValue }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topIcon: {
      type: String,
      default: null,
    },
    bottomIcon: {
      type: String,
      default: null,
    },
    topValue: {
      type: String,
      default: null,
    },
    bottomValue: {
      type: String,
      default: null,
    },
    topMetric: {
      type: String,
      default: null,
    },
    isEnableTopMetricDirection: {
      type: Boolean,
      default: false,
    },
    bottomMetric: {
      type: String,
      default: null,
    },
    isEnableBottomMetricDirection: {
      type: Boolean,
      default: false,
    },
    subTextMetric: {
      type: String,
      default: null,
    },
    isEnableSubTextMetricDirection: {
      type: Boolean,
      default: false,
    },
    subTextValue: {
      type: [Number, String],
      default: null,
    },
  },
};
</script>
<style scoped>
.card-container{
   min-height: 252px;
   padding: 24px;
   background-color: white;
   border: 1px solid #e6eaee;
   border-radius: 4px;
}
.top-row,.bottom-row{
   min-height: 81px;
}
.top-value,.bottom-value{
   font-size: 1rem;
   font-weight: 600;
}
.top-metric,.bottom-metric{
   font-size: 0.7rem;
}
.line-style-border {
   border: 1px solid #e6eaee;
}

.sub-text-link {
   color: inherit;
   font-size: 12px;
}

.right-arrow-after::after {
    content: " >";
    color: #5667ff;
}
</style>
