import Vue from 'vue';
import LogRocket from 'logrocket';

/* eslint-disable no-unused-vars */
import { Dropdown, Tooltip } from 'bootstrap';
/* eslint-enable no-unused-vars */

import { LicenseManager } from 'ag-grid-enterprise';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueI18n from 'vue-i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEyeSlash, faEye, fas } from '@fortawesome/free-solid-svg-icons';

/* these could be removed if not used */
import VTooltip from 'v-tooltip';
import VModal from 'vue-js-modal';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as filters from '@/helpers/filters';

import App from '@/shells/ShellChooser.vue';
// import { sse } from '@/endpoints/notifications';
import messages from '../../../locale/i18n';

import router from './router';
import store from './store/store';

import {
  // requestNotificationPermission,
  // hasNotificationPermission,
  // sendToServiceWorker,
} from './helpers/notifications';
import { languageSelector } from './helpers/utility';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('wsyxb3/flexigo');
}

library.add(faEyeSlash);
library.add(faEye);
library.add(fas);
Vue.component('fa-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

// Global Filters
Vue.filter('date', filters.date);
Vue.filter('time', filters.time);
Vue.filter('tl', filters.tl);
Vue.filter('fulltime', filters.fulltime);

LicenseManager.setLicenseKey('CompanyName=Vektor,LicensedApplication=Vektor,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-018092,ExpiryDate=17_August_2022_[v2]_MTY2MDY5MDgwMDAwMA==32548f96502ae146d0f3bd08785a1784');

Vue.use(VueI18n);

// Google Map Config
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD5hb_jNij_4wNa1hUkPPk2zSb8HgmvQtY',
    libraries: 'places,visualization,drawing,geometry', // This is required if you use the Autocomplete plugin
    language: languageSelector(),
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  },
  installComponents: true,
});

Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false }, dialog: true });
Vue.use(VTooltip);

const lang = languageSelector();
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: lang,
  messages,
});
Vue.config.lang = lang;
console.log(lang);
// library.add(fas);
// Vue.component('fa-icon', FontAwesomeIcon);

Vue.mixin({
  data() {
    return {
      baseUrl: process.env.BASE_URL,
    };
  },
});

// Notifications start
// if (!hasNotificationPermission()) {
//   requestNotificationPermission();
// }

// const eventSource = new EventSource(sse.stream);
// eventSource.addEventListener('SSE-NOTIFICATION', (e) => {
//   const message = JSON.parse(e.data);
//   const { type } = message;
//   const { locale } = i18n;
//   const text = i18n.messages[locale]?.flexigo?.notifications[type];
//   sendToServiceWorker(type, locale, text);
//   console.log('notification:', type);
// });

// eventSource.onerror = (error) => {
//   console.error('SSE error:', error);
//   // Depending on the error, we may decide to close the connection or handle it differently.
// };
// Notifications end

navigator.serviceWorker.register('/admin/service-worker.js').then(() => {
  console.log('Service Worker registered successfully.');
}).catch((error) => {
  console.log('Service Worker registration failed:', error);
});

// setInterval(() => {
//   console.log('readyState', eventSource.readyState, new Date());
//   showNotification('test', { body: 'test', icon: '/admin/flexigo-loader.gif' });
// }, 30000);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
