import { BASEURL, UMNG, FLEXIGO_LEGACY } from './base';

export const auth = {
  menu: `${UMNG}/menu/flexigo`,
  riderMenu: `${UMNG}/menu/main`,
  logout: `${FLEXIGO_LEGACY}/user/web/session/logout`,
  changePassword: `${FLEXIGO_LEGACY}/user/change/password`,
  newPassword: `${BASEURL}oauth/personnel/web/changePassword`,
  resetPassword: `${BASEURL}oauth/reset/password`,
};

export default auth;
