export default {
  name: 'Redirector',
  data() {
    return {
      baseUrl: process.env.BASE_URL,
    };
  },
  async created() {
    try {
      const { route, legacyRoute } = this.$route.query;

      this.$router.push({
        name: route,
        params: { route: legacyRoute?.replaceAll('"', '') ?? undefined },
        query: { lang: this.$route.query?.lang ?? 'en' },
      });

      // localStorage.setItem('app', this.$store.getters.app);
      // console.log('pathName', pathName, this.$store.getters.appHome);
      // let url;
      // if (pathName && (pathName.includes('flexigo.') || pathName.includes('flexiride.') || pathName.includes('app.'))) {
      //   url = new URL(`${this.baseUrl}flexigo`, window.location.origin);
      // } else {
      //   url = new URL(`${this.baseUrl}${this.$store.getters.appHome}`, window.location.origin);
      // }

      // if (pathName && pathName.includes('flexigo.')) url.hash = `carpool/${pathName.slice(8)}`;
      // else if (pathName && pathName.includes('flexiride.')) url.hash = pathName.replace('.', '/');
      // else if (pathName && pathName.includes('app.')) url.hash = `/${pathName.slice(4)}`;
      // else url.hash = pathName || '';

      // window.location.assign(url);

      // if (this.$route.query.lang) {
      //   this.$i18n.locale = this.$route.query.lang;
      // }
      // localStorage.setItem('lang', this.$i18n.locale);
    } catch (error) {
      console.log(error);
      console.error(error.response.data._error);
      window.location.assign(`${window.location.origin}${this.baseUrl}`);
    }
  },
  render: () => null,
};
