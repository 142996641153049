<template>
  <div class="btn-group search-workgroup">
    <button
      type="button"
      class="btn dropdown-toggle"
      data-bs-toggle="dropdown"
      data-bs-display="static"
      aria-expanded="false"
      style=" min-width: 250px; ">
      {{selectedValue}}
    </button>
    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end p-2" style="width: 250px;">
      <div class="input-group mb-3">
        <input v-model="filterText" type="search" class="form-control" :placeholder="$t('flexigo.dashboard.searchRoutes')" aria-describedby="basic-addon1">
      </div>
      <a class="dropdown-item" href="#" @click.prevent="select(undefined)">{{$t('flexigo.dashboard.allRoutes')}}</a>
      <a v-for="el in filteredData" :key="el.id" class="dropdown-item text-wrap" href="#" @click.prevent="select(el)">{{el.name}}</a>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RouteDropdown',
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    selectedRouteId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      filterText: '',
      currentRoute: undefined,
    };
  },
  computed: {
    filteredData() {
      return this.listData.filter((el) => el.name.toLowerCase().match(this.filterText.toLowerCase()));
    },
    selectedValue() {
      return (this.currentRoute ? this.currentRoute.name : this.$t('flexigo.dashboard.allRoutes'));
    },
  },
  watch: {
    selectedRouteId(value) {
      this.currentRoute = this.listData.find((item) => item.id === value);
    },
  },
  mounted() {
    this.currentRoute = this.listData.find((item) => item.id === this.selectedRouteId);
  },
  methods: {
    select(val) {
      this.currentRoute = val;
      const routeId = val?.id;
      const routeName = val?.name;
      this.$emit('select', routeId, routeName);
    },
  },
};
</script>

<style>

</style>
