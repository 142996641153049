<template>
  <div>
    <div>
      <div class="row mb-3">
        <div class="col px-2">
          <card
            :top-icon="`${publicPath}icons/dashboard/users.svg`"
            :top-value="`${value.uniqueRiders} / ${value.rides}`"
            :top-metric="this.$t('flexigo.dashboard.uniqueRidersRides')"
            :isEnableTopMetricDirection="true"
            :bottom-icon="`${publicPath}icons/dashboard/routes.svg`"
            :bottom-value="`${value.trips} / ${value.routes}`"
            :bottom-metric="this.$t('flexigo.dashboard.tripsRoutes')"
            :isEnableBottomMetricDirection="true"
            @top-metric-direction="direct({ page:'Reservations'})"
            @bottom-metric-direction="direct({ page:'RouteStatuses'})"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${publicPath}icons/dashboard/dial.svg`"
            :top-value="formatPercent(value.avgNonCommuteDistance, locale)"
            :top-metric="this.$t('flexigo.dashboard.nonCommuteMiles')"
            :sub-text-metric="this.$t('flexigo.common.aboveWithParam', [formatPercent(value.nonCommuteThreshold, locale)])"
            :sub-text-value="value.tripsAboveNonCommuteThreshold"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${publicPath}icons/dashboard/bus.svg`"
            :top-value="formatNumberWithDecimal(value.avgRider, locale, 1, 1)"
            :top-metric="this.$t('flexigo.dashboard.avgRidersPerTrip')"
            :sub-text-metric="this.$t('flexigo.dashboard.below', [value.riderThreshold])"
            :sub-text-value="value.tripsBelowRiderThreshold"
            :isEnableTopMetricDirection="true"
            :isEnableSubTextMetricDirection="true"
            @top-metric-direction="direct({ page:'RouteStatuses'})"
            @subtext-metric-direction="direct({ page:'RouteStatuses', sort:'ascending', lessQrReads: 5})"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${publicPath}icons/dashboard/time.svg`"
            :top-value="
              formatNumberWithDecimal(value.avgTimeCoefficient, locale)
            "
            :top-metric="this.$t('flexigo.dashboard.avgTimeCoefficient')"
            :sub-text-metric="this.$t('flexigo.dashboard.over', [value.timeCoefficientThreshold])"
            :sub-text-value="value.timeCoefficientAboveThreshold"
            :isEnableTopMetricDirection="true"
            :isEnableSubTextMetricDirection="value.timeCoefficientAboveThreshold > 0"
            @top-metric-direction="direct({ page:'Reservations', overTimeCoefficient: 1.5})"
            @subtext-metric-direction="direct({ page:'Reservations', overTimeCoefficient:1.5})"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${publicPath}icons/dashboard/walk.svg`"
            :top-value="`${formatDigit(
              value.avgWalkingDistanceInMeter,
              locale
            )} ft`"
            :top-metric="this.$t('flexigo.dashboard.avgDistanceToStop')"
            :sub-text-metric="this.$t('flexigo.dashboard.above', [value.walkingDistanceThreshold])"
            :sub-text-value="value.walkingDistanceAboveThreshold"
            :isEnableBottomMetricDirection="true"
            :isEnableTopMetricDirection="true"
            :isEnableSubTextMetricDirection="true"
            @subtext-metric-direction="direct({ page:'Reservations', walkingDistanceInMeter: 500})"
            @top-metric-direction="direct({ page:'Reservations'})"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${publicPath}icons/dashboard/paper.svg`"
            :top-value="`${formatCurrency(
              value.totalCost,
              isLocaleTr() ? 'TRY' : 'USD',
              locale
            )}`"
            :top-metric="this.$t('flexigo.dashboard.totalCost')"
            :bottom-value="`${((value.costPerEmployee % 1 !== 0) &&
              (value.costPerEmployee !== 'Infinity'))  ?
              formatCurrency(value.costPerEmployee, (isLocaleTr() ? 'TRY' : 'USD'), locale) :
              value.costPerEmployee}`"
            :bottom-metric="this.$t('flexigo.dashboard.perEmployeeTrip')"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${publicPath}icons/dashboard/bubble.svg`"
            :top-value="`${emisionValue}`"
            :top-metric="this.$t('flexigo.dashboard.totalEmmisions')"
            :bottom-value="`${emissionPerEmployee}`"
            :bottom-metric="this.$t('flexigo.dashboard.perEmployeeMile')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '../card.vue';
import {
  getLink, formatCurrency, formatNumberWithDecimal, formatPercent, isLocaleTr, formatDigit, formatWeight,
} from '@/helpers/utility';

export default {
  name: 'VanpoolRoutesCards',
  components: { Card },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    locale() {
      return navigator.language;
    },
    emisionValue() {
      return formatWeight(this.value.totalEmission, navigator.language);
    },
    emissionPerEmployee() {
      return formatWeight(this.value.emissionPerEmployee, navigator.language);
    },
  },
  methods: {
    getLink,
    formatCurrency,
    formatNumberWithDecimal,
    formatPercent,
    isLocaleTr,
    formatDigit,
    direct(options) {
      const queries = { ...this.$route.query, ...options, ...{ workgroupType: 'VANPOOL' } };
      const routeData = this.$router.resolve({ name: options.page, params: { type: 'VANPOOL' }, query: queries });
      window.open(routeData.href, '_blank');
    },
  },
};

</script>
