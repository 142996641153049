import { notifications, companies } from '@/endpoint.flexigo';
import Repository from './repository';

const client = new Repository();

export default {
  async getNotifications(payload) {
    const r = await client.get(notifications.notifications, payload);
    return r;
  },
  async getNotificationDetails(id) {
    const r = await client.get(notifications.getNotifications.replace(':id', id));
    return r;
  },

  async getRoutes(workgroupType = null) {
    const r = await client.get(notifications.routes, { workgroupType });
    return r;
  },

  async getShuttleProviders() {
    const r = await client.get(`${companies.serviceProviders}?lightMode=true`);
    return r;
  },
  async sendPersonnelNotification(payload) {
    const formData = new FormData();
    formData.append('message', payload.message || null);
    formData.append('title', payload.title || null);
    formData.append('personnelIds', payload.personnelIds || null);
    if (payload.notificationDateTime) formData.append('notificationDateTime', payload.notificationDateTime);
    formData.append('file', payload.file || null);
    const r = await client.post(notifications.personnelNotifications, formData);
    return r;
  },
  async sendNotification(payload) {
    const formData = new FormData();
    payload.routeIds && formData.append('routeIds[]', payload.routeIds);
    payload.destinationId && formData.append('destinationId', payload.destinationId);
    payload.shuttleProviderId && formData.append('shuttleProviderId', payload.shuttleProviderId);
    payload.serviceType && formData.append('serviceType', payload.serviceType);
    formData.append('notifyPassivePersonnel', payload.notifyPassivePersonnel);
    formData.append('title', payload.title);
    formData.append('message', payload.message);
    payload.file && formData.append('file', payload.file);

    const r = await client.post(notifications.createNotifications, formData);
    return r;
  },
};
