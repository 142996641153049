/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { subSeconds } from 'date-fns';
import { timeStrToInt } from '@/helpers/dateTime';
import Vue from 'vue';

export default {
  state: {
    stationList: { TO: [], FROM: [] },
    routesData: { TO: {}, FROM: {} },
    tempIdsOnThisRoute: { TO: new Set(), FROM: new Set() },
  },

  mutations: {
    SET_STATION_LIST(state, { route, payload }) {
      Vue.set(state.stationList, route, payload);
    },

    RESET_STATION_LIST(state) {
      Vue.set(state, 'stationList', { TO: [], FROM: [] });
    },

    ADD_STATION_BY_DIRECTION(state, { route, payload }) {
      const clonedPayload = JSON.parse(JSON.stringify(payload));
      Vue.set(state.stationList[route], state.stationList[route].length, clonedPayload);
    },

    ADD_STATION_TO(state, payload) {
      const clonedPayload = JSON.parse(JSON.stringify(payload));
      Vue.set(state.stationList.TO, state.stationList.TO.length, clonedPayload);
    },

    ADD_STATION_FROM(state, payload) {
      const clonedPayload = JSON.parse(JSON.stringify(payload));
      Vue.set(state.stationList.FROM, state.stationList.FROM.length, clonedPayload);
    },

    ADD_STATION_UNSHIFT(state, { route, payload }) {
      const clonedPayload = JSON.parse(JSON.stringify(payload));
      state.stationList[route].unshift(clonedPayload);

      const { id, counterStopId } = payload;
      const routeReverse = route === 'TO' ? 'FROM' : 'TO';
      if (counterStopId) {
        const indexReverse = state.stationList[routeReverse].findIndex((item) => item.id === counterStopId);
        if (indexReverse !== -1) {
          Vue.set(state.stationList[routeReverse][indexReverse], 'counterStopId', id);
        }
      }
    },

    UPDATE_STATION_ID(state, { route, oldId, newId }) {
      const index = state.stationList[route].findIndex((el) => el.id === oldId);
      if (index !== -1) {
        Vue.set(state.stationList[route][index], 'id', newId);
      }
    },

    UPDATE_STATION_DATA(state, { route, stationId, key, value }) {
      const index = state.stationList[route].findIndex((el) => el.id === stationId);
      if (index !== -1) {
        Vue.set(state.stationList[route][index], key, value);
      }
    },

    UPDATE_STATION(state, { route, oldStationId, newStationId, statsData, draftId, description, location, address }) {
      const oldIndex = state.stationList[route].findIndex((el) => el.id === oldStationId);
      if (oldIndex === -1) {
        throw new Error('Old Station not found. This should not happen.');
      }
      Vue.set(state.stationList[route][oldIndex], 'id', newStationId);

      if (statsData) {
        const stats = statsData[draftId];
        if (stats && stats[0]) {
          Vue.set(state.stationList[route][oldIndex], 'expectedArrivalHour', stats[0].expectedArrivalHour);
          Vue.set(state.stationList[route][oldIndex], 'expectedDepartureHour', stats[0].expectedDepartureHour);
        }
      }

      Vue.set(state.stationList[route][oldIndex], 'description', description);
      Vue.set(state.stationList[route][oldIndex], 'location', location);
      Vue.set(state.stationList[route][oldIndex], 'address', address);
      console.log(state.stationList[route][oldIndex]);
    },

    REMOVE_STATION_BY_ID(state, { route, stationId }) {
      const index = state.stationList[route].findIndex((item) => item.id === stationId);
      if (index !== -1) {
        Vue.delete(state.stationList[route], index);
      }
    },

    CALCULATE_STATION_TIME(state, { route, isInbound, sections, serviceTimeForStopInSeconds }) {
      // SUBE exception: sections.length may be smaller than stations count
      const stationCount =
        sections.length < state.stationList[route].length ? sections.length : state.stationList[route].length;

      if (isInbound) {
        for (let i = 0; i < stationCount; i++) {
          if (i === 0) {
            const arrivalTimeForFirstStop = subSeconds(
              new Date(sections[0].departure.time),
              serviceTimeForStopInSeconds,
            );
            Vue.set(
              state.stationList[route][i],
              'expectedArrivalHour',
              timeStrToInt(arrivalTimeForFirstStop.toTimeString().slice(0, 5)),
            );
            Vue.set(
              state.stationList[route][i],
              'expectedDepartureHour',
              timeStrToInt(new Date(sections[0].departure.time).toTimeString().slice(0, 5)),
            );
          } else {
            Vue.set(
              state.stationList[route][i],
              'expectedArrivalHour',
              timeStrToInt(new Date(sections[i - 1].arrival.time).toTimeString().slice(0, 5)),
            );
            Vue.set(
              state.stationList[route][i],
              'expectedDepartureHour',
              timeStrToInt(new Date(sections[i].departure.time).toTimeString().slice(0, 5)),
            );
          }
        }
      } else {
        for (let i = 0; i < stationCount; i++) {
          const sectionsFormatted = sections.slice(-stationCount);
          Vue.set(
            state.stationList[route][i],
            'expectedArrivalHour',
            timeStrToInt(new Date(sectionsFormatted[i].arrival.time).toTimeString().slice(0, 5)),
          );
          if (i === stationCount - 1) {
            Vue.set(state.stationList[route][i], 'expectedDepartureHour', undefined);
          } else {
            Vue.set(
              state.stationList[route][i],
              'expectedDepartureHour',
              timeStrToInt(new Date(sectionsFormatted[i + 1].departure.time).toTimeString().slice(0, 5)),
            );
          }
        }
      }
    },

    ASSIGN_PERSONNEL_TO_STATION(state, { route, personnelId, personnelToAdd, stationId }) {
      const originalStation = state.routesData[route].personnelStations[personnelId];

      if (originalStation) {
        const originalStationIndex = state.stationList[route].findIndex((el) => el.id === originalStation);

        if (state.stationList[route][originalStationIndex]?.personnelIds?.length) {
          const filteredPersonnelIds = state.stationList[route][originalStationIndex].personnelIds.filter(
            (el) => el !== personnelId,
          );
          Vue.set(state.stationList[route][originalStationIndex], 'personnelIds', filteredPersonnelIds);
        }
        if (state.stationList[route][originalStationIndex]?.personnels?.length) {
          const filteredPersonnels = state.stationList[route][originalStationIndex].personnels.filter(
            (el) => el.id !== personnelId,
          );
          Vue.set(state.stationList[route][originalStationIndex], 'personnels', filteredPersonnels);
        }

        Vue.delete(state.routesData[route].personnelStations, personnelId);
      }

      Vue.set(state.routesData[route].personnelStations, personnelId, stationId);

      const stationIndex = state.stationList[route].findIndex((el) => el.id === stationId);
      state.routesData[route].personnelCount = state.stationList[route][stationIndex].personnelIds?.length || 0;

      const personnelIds = state.stationList[route][stationIndex].personnelIds || [];
      const personnels = state.stationList[route][stationIndex].personnels || [];

      Vue.set(state.stationList[route][stationIndex], 'personnelIds', [...personnelIds, personnelId]);
      Vue.set(state.stationList[route][stationIndex], 'personnels', [
        ...personnels,
        JSON.parse(JSON.stringify(personnelToAdd.personnel)),
      ]);
    },

    REMOVE_PERSONNEL_FROM_STATION(state, { route, personnelId, stationId }) {
      const stationIndex = state.stationList[route].findIndex((el) => el.id === stationId);
      const { personnelIds, personnels } = state.stationList[route][stationIndex];

      Vue.set(
        state.stationList[route][stationIndex],
        'personnelIds',
        personnelIds.filter((el) => el !== personnelId),
      );
      Vue.set(
        state.stationList[route][stationIndex],
        'personnels',
        personnels.filter((el) => el.id !== personnelId),
      );

      state.tempIdsOnThisRoute[route].add(personnelId);
      Vue.delete(state.routesData[route].personnelStations, personnelId);
    },

    UPDATE_PERSONNEL_STATION_ID(state, { route, oldStationId, newStationId }) {
      const personnelStations = { ...state.routesData[route].personnelStations };
      Object.keys(personnelStations).forEach((key) => {
        if (personnelStations[key] === oldStationId) {
          Vue.set(personnelStations, key, newStationId);
        }
      });
      Vue.set(state.routesData[route], 'personnelStations', personnelStations);
    },

    SET_ROUTES_ALL(state, payload) {
      Vue.set(state, 'routesData', payload);
    },

    SET_ROUTES(state, { route, payload }) {
      Vue.set(state.routesData, route, payload);
    },

    UPDATE_ROUTES(state, { route, payload }) {
      const updatedRoute = { ...state.routesData[route], ...payload };
      Vue.set(state.routesData, route, updatedRoute);
    },

    RESET_ROUTES(state) {
      Vue.set(state, 'routesData', { TO: {}, FROM: {} });
    },

    ADD_TEMP_IDS_ON_THIS_ROUTE(state, { route, payload }) {
      state.tempIdsOnThisRoute[route].add(payload);
    },
  },
  actions: {},

  getters: {
    listOfStations: (state) => (route) => state.stationList[route],
    stationsById:
      (state) =>
      ({ route, id }) => {
        if (state.stationList[route].length < 1) return [];

        return state.stationList[route].filter((item) => item.id === id);
      },
    firstStationById:
      (state) =>
      ({ route, id }) =>
        state.stationList[route].find((item) => item.id === id),
    listOfStationsTo(state) {
      return state.stationList.TO;
    },
    listOfStationsFrom(state) {
      return state.stationList.FROM;
    },
    countOfPersonnelById:
      (state) =>
      ({ route, stationId }) => {
        const index = state.stationList[route].findIndex((el) => el.id === stationId);
        if (index < 0) return 0;
        return state.stationList[route][index].personnelIds?.length;
      },
    listOfPersonnelIds:
      (state) =>
      ({ route, stationId }) => {
        const listOfIds = state.stationList[route].find((el) => el.id === stationId);
        if (!listOfIds) return [];
        return state.stationList[route].find((el) => el.id === stationId).personnelIds;
      },
    routesByDirection: (state) => (route) => state.routesData[route],
    personnelStationsOfRoutes: (state) => (route) => {
      if (!state.routesData[route].personnelStations) return {};
      return state.routesData[route].personnelStations;
    },
    tempIdsOnThisRoute: (state) => (route) => state.tempIdsOnThisRoute[route],
    counterStationId:
      (state, getters) =>
      ({ route, stationId, isRoundTrip }) => {
        if (!isRoundTrip) {
          return null;
        }
        let stationCounterId = getters.firstStationById({
          route,
          id: stationId,
        }).counterStopId;

        if (!stationCounterId) {
          stationCounterId = getters
            .listOfStations(route === 'TO' ? 'FROM' : 'TO')
            .find((item) => item.counterStopId === stationId)?.id;
        }
        return stationCounterId;
      },
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
