<template>
  <div v-if="dialog.state.active" class="dialog-wrapper">
    <div class="dialog-inner">
      <div
        v-if="icon"
        class="dialog-inner__logo text-center mb-3"
      >
        <img :src="`/icons/${icon}.png`" alt="">
      </div>
      <h3 v-if="dialog.state.title" class="dialog-inner__title">{{  dialog.state.title  }}</h3>

      <p v-if="dialog.state.html" class="dialog-inner__message" v-html="dialog.state.message"></p>
      <p v-else class="dialog-inner__message">{{ dialog.state.message }}</p>

      <input v-if="dialog.state.type === 'prompt'" v-model="dialog.state.userInput" :type="dialog.state.inputType" class="form-control form-control-sm mb-3">

      <div class="text-center">
        <button
          v-if="dialog.state.type !== 'alert'"
          class="btn btn-light me-3"
          @click="dialog.cancel()"
        >
          {{  dialog.state.textCancel  }}
        </button>

        <button
          class="btn btn-primary"
          :disabled="!canSubmit"
          @click="dialog.ok(dialog.state.userInput)"
        >
          {{  dialog.state.textOk  }}
        </button>
      </div>

    </div>
    <div class="dialog-bg"  @click="dialog.cancel()"></div>
  </div>
</template>

<script>
import dialog from './dialog';

export default {
  data() {
    return {
      userInput: '',
      dialog,
    };
  },
  computed: {
    icon() {
      if (this.dialog.state.type === 'error') return 'error';
      if (this.dialog.state.type === 'info') return 'primary';
      if (this.dialog.state.type === 'alert') return 'primary';
      if (this.dialog.state.type === 'prompt') return 'question';
      if (this.dialog.state.type === 'success') return 'success';
      return '';
    },
    canSubmit() {
      if (!this.dialog.state.inputRequired) return true;
      return this.dialog.state.type === 'prompt' && Boolean(dialog.state.userInput);
    },
  },
  destroyed() {
    this.dialog.reset();
  },
};
</script>

<style>
.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1060; /* sliding palnel has a z-index: 1040 */
}

.dialog-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.dialog-inner {
  /* background: white;
  z-index: 2;
  padding: 20px;
  min-width: 200px; */
  z-index: 2;
  left: calc(50% - 180px);
  /* transform: translateX(-50%); */
  top: 200px;
  position: fixed;
  width: 360px;
  height: 241px;
  padding: 32px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px var(--pale-grey-two);
  background-color: #fff;
}

.dialog-inner__title{
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: #363647;
}

.dialog-inner__message{
    height: 14px;
    margin: 8px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    color: #363647;
}
</style>
