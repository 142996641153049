<template>
  <div v-if="isLoading" class="container-xxl full-loader">
    <div class="imgContainer">
      <img :src="`${baseUrl}flexigo-loader.gif`" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'flexigoLoader',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
<style scoped>
.full-loader {
  grid-area: main-content;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
}
</style>
