<template>
  <section class="is-fullheight">
    <div class="colored-line"></div>

    <section class="login">
      <div class="row m-0">
        <div class="col-lg-6 center-content">
          <div class="login-content justify-content-center my-4">
            <header class="mb-5">
              <div class="login-content-logo scale-scroll visible">
                <a>
                  <img :src="`${baseUrl}icons/flexigo_logo_dark.svg`" alt="flexigo" style="height: 40px" />
                </a>
              </div>
              <div class="login-content-head">
                <div class="login-content-head-title mt-3">
                  <h1 v-html="$t('flexigo.login.welcomeToFlexigo')"></h1>
                </div>
                <div class="login-content-head-description">
                  <p>{{ $t('flexigo.login.slogan') }}</p>
                </div>
              </div>
            </header>

            <main>
              <user-type-toggle v-model="selectedUserType" @on-change="onUserTypeChange"></user-type-toggle>

              <form-login
                v-if="isScreenLogin"
                :selectedUserType="selectedUserType"
                :existingEmail="existingEmail"
                @open-screen="openScreen"
              />

              <forgot-password
                v-if="isScreenForgotPassword"
                :selectedUserType="selectedUserType"
                :existingEmail="existingEmail"
                @open-screen="openScreen"
              ></forgot-password>
            </main>
          </div>
        </div>
        <div class="col-lg-6 h-100vh p-0">
          <div
            class="d-flex h-100 w-100 align-items-center justify-content-center login__background"
            :style="`background-image: url(${baseUrl}img/login-women-image.png)`"
          ></div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import forgotPassword from './forgotPassword.vue';
import formLogin from './formLogin.vue';
import userTypeToggle from './userTypeToggle.vue';

const formStates = {
  login: 'LOGIN',
  loginWithPassword: 'LOGIN_WITH_PASSWORD',
  forgotPassword: 'FORGOT_PASSWORD',
  register: 'REGISTER',
};

export default {
  name: 'Login',
  components: { userTypeToggle, formLogin, forgotPassword },
  data() {
    return {
      selectedFormState: formStates.login,
      selectedUserType: 'RIDER',
      existingEmail: null,
    };
  },
  computed: {
    isScreenLogin() {
      if (!this.selectedFormState) return true;
      return this.selectedFormState === formStates.login || this.selectedFormState === formStates.loginWithPassword;
    },
    isScreenForgotPassword() {
      return this.selectedFormState === formStates.forgotPassword;
    },
  },
  mounted() {
    const userType = localStorage.getItem('userType');
    if (userType) {
      this.selectedUserType = userType;
    }
    if (this.$route.params) {
      const { email } = this.$route.params;
      this.selectedFormState = formStates.login;
      this.existingEmail = email;
    }
  },
  methods: {
    handleForgotPassword() {
      this.selectedFormState = formStates.forgotPassword;
    },
    openScreen(payload) {
      if (typeof payload === 'object') {
        this.existingEmail = payload.email;
        this.selectedFormState = payload.screen;
        return;
      }
      this.selectedFormState = payload;
    },
    onUserTypeChange(userType) {
      if (userType === 'RIDER') {
        this.selectedFormState = formStates.login;
      } else {
        this.selectedFormState = formStates.loginWithPassword;
      }
      // save this to local storage
      localStorage.setItem('userType', userType);
    },
  },
};
</script>
