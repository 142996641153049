<template>
  <div v-if="isLoading" class="loadingApp position-relative mt-3" style="height: 550px">
    <div class="imgContainer">
      <img :src="`${publicPath}flexigo-loader.gif`" />
    </div>
  </div>
  <div v-else class="out-countainer custom-border mt-3">
    <div class="container-header row w-100 m-0">
      <div class="col-8 p-3">{{ this.$t('flexigo.dashboard.compliance') }}</div>
      <div class="col-4 p-2 text-end">
        <dropdown
          :selectedRouteId="selectedRouteId"
          :list-data="value.routes"
          @select="(val) => $emit('occupancyRefresh', (selectedRouteId = val))"
        />
      </div>
    </div>
    <div class="border-0">
      <div class="bg-white outer-border" style="padding: 28px 16px 16px">
        <div class="row">
          <div class="col-6">
            <highcharts :options="chartOptions" />
          </div>
          <div class="col-6 pt-2">
            <div class="row">
              <div class="col p-0">
                <div class="pe-2 ps-3">
                  <div class="outer-border p-3 rounded">
                    <div
                      class="text-end d-flex align-items-center justify-content-end has-cursor-pointer"
                      @click="
                        () =>
                          riderShipArrow === 'arrow-up'
                            ? (riderShipArrow = 'arrow-down')
                            : (riderShipArrow = 'arrow-up')
                      "
                    >
                      <div
                        class="bold d-inline-block fw-bold pe-1 two-line-three-dots"
                        v-html="$t('flexigo.dashboard.vanpoolRoutesAvgRidership')"
                      ></div>
                      <img :src="`${publicPath}icons/${riderShipArrow}.svg`" class="ms-1" />
                    </div>
                    <div class="overflow-scroll" style="max-height: 305px">
                      <div
                        v-for="(m, i) in RoutesAndRiderships"
                        :key="m.routeName + i"
                        class="p-3 border-bottom d-flex align-items-center justify-content-end"
                      >
                        <div class="d-inline-block" style="max-width: 140px">
                          <span
                            class="one-line-three-dots text-primary has-cursor-pointer"
                            style="word-break: break-all"
                            @click="directToRoute(m.routeId)"
                            >{{ m.routeName }}</span
                          >
                        </div>
                        <span class="px-1">/</span>
                        <span>{{ m.riderCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col p-0">
                <div class="pe-3 ps-2">
                  <div class="outer-border p-3 rounded">
                    <div
                      class="text-end d-flex align-items-center justify-content-end has-cursor-pointer"
                      @click="
                        () =>
                          nonCommuteMilesArrow === 'arrow-up'
                            ? (nonCommuteMilesArrow = 'arrow-down')
                            : (nonCommuteMilesArrow = 'arrow-up')
                      "
                    >
                      <div
                        class="bold d-inline-block fw-bold pe-1 two-line-three-dots"
                        v-html="$t('flexigo.dashboard.vanpoolRoutesAvgNonCommuteMiles')"
                      ></div>
                      <img :src="`${publicPath}icons/${nonCommuteMilesArrow}.svg`" class="ms-1" />
                    </div>
                    <div class="overflow-scroll" style="max-height: 305px">
                      <div
                        v-for="(m, i) in RoutesAndNonCommutes"
                        :key="m.routeName + i * 2"
                        class="p-3 border-bottom d-flex align-items-center justify-content-end"
                      >
                        <div class="d-inline-block" style="max-width: 140px">
                          <span
                            class="one-line-three-dots text-primary has-cursor-pointer"
                            style="word-break: break-all"
                            @click="directToRoute(m.routeId)"
                            >{{ m.routeName }}</span
                          >
                        </div>
                        <span class="px-1">/</span>
                        <span>{{ formatPercent(Number(m.nonCommute)) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import { format } from 'date-fns';
import { en, tr } from 'date-fns/locale';
import { isLocaleTr, formatPercent } from '@/helpers/utility';
import { intToDate } from '@/helpers/dateTime';
import { defaults } from '@/helpers/appDefaults';
import dropdown from '../../routeDropdown.vue';
import dashboardService from '../../../services/dashboard';

let vm;

export default {
  name: 'vanpoolComplianceGraph',
  components: { highcharts: Chart, dropdown },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    startDateFormatted: {
      type: String,
    },
    endDateFormatted: {
      type: String,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      selectedRouteId: undefined,
      selectedXaxisPoint: undefined,
      chartOptions: {
        chart: {
          type: 'spline',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
          // align: 'left',
        },
        xAxis: {
          categories: [],
          gridLineWidth: 1,
        },
        yAxis: {
          crosshair: true,
          lineWidth: 1,
          title: {
            text: null,
            margin: 0,
          },
          allowDecimals: false,
        },
        legend: {
          // symbolType: 'square',
          itemStyle: {
            color: '#7f8fa4',
          },
        },
        tooltip: {
          shared: true,
          headerFormat: '',
          backgroundColor: '#363647',
          style: {
            color: 'white',
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            pointPadding: 0,
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click(e) {
                  vm.$data.selectedXaxisPoint = e?.point?.category;
                },
              },
            },
          },
        },
        series: [
          {
            // type: 'spline',
            name: this.$t('flexigo.dashboard.complianceGraphMarker.first'),
            color: '#f5b548',
            data: [],
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#f5b548',
              fillColor: 'white',
            },
          },
          {
            // type: 'spline',
            name: this.$t('flexigo.dashboard.complianceGraphMarker.second'),
            color: '#1DB64D',
            data: [],
            connectNulls: true,
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#1DB64D',
              fillColor: 'white',
              width: 150,
            },
          },
        ],
      },
      RoutesAndRiderships: [],
      RoutesAndNonCommutes: [],
      riderShipArrow: 'arrow-up',
      nonCommuteMilesArrow: 'arrow-down',
      serviceParams: {
        workgroupType: 'VANPOOL',
        fromDate: this.startDateFormatted,
        toDate: this.endDateFormatted,
        routeId: this.selectedRouteId,
      },
    };
  },
  watch: {
    isLoading(value) {
      if (!value) {
        this.prepareGraphData();
        this.getComplianceChartRidership();
        this.getComplianceNonCommute();
      }
    },
    selectedRouteId() {
      this.getComplianceChartRidership();
    },
    riderShipArrow(value) {
      this.sort({ data: 'RoutesAndRiderships', param: 'riderCount', sort: value });
    },
    nonCommuteMilesArrow(value) {
      this.sort({ data: 'RoutesAndNonCommutes', param: 'nonCommute', sort: value });
    },
    selectedXaxisPoint(value) {
      const dateIndex = this.chartOptions.xAxis.categories.findIndex((item) => value === item);
      const date = format(intToDate(this.value.data[dateIndex].routeDay), defaults.date);

      const queries = {
        ...this.$route.query,
        ...{
          selectedTab: 'routes',
          fromDate: date,
          toDate: date,
          workgroupType: 'VANPOOL',
          lessQrReads: 4,
        },
      };
      const routeData = this.$router.resolve({ name: 'RouteStatuses', query: queries });
      window.open(routeData.href, '_blank');
    },
  },
  mounted() {
    this.prepareGraphData();
    this.getComplianceChartRidership();
    this.getComplianceNonCommute();
    vm = this;
  },
  methods: {
    formatPercent,
    prepareGraphData() {
      this.chartOptions.xAxis.categories = this.value.data.map((item) =>
        format(intToDate(item.routeDay), 'dd LLL', { locale: isLocaleTr() ? tr : en }),
      );
      this.chartOptions.series[0].data = this.value.data.map((item) => item.trpisLessThanLimit);
      this.chartOptions.series[1].data = this.value.data.map((item) => item.trpisNonCommuteOverLimit);
    },
    async getComplianceChartRidership() {
      this.RoutesAndRiderships = await dashboardService.getComplianceChartRidership({
        workgroupType: 'VANPOOL',
        fromDate: this.startDateFormatted,
        toDate: this.endDateFormatted,
        routeId: this.selectedRouteId,
      });
      this.sort({ data: 'RoutesAndRiderships', param: 'riderCount', sort: this.riderShipArrow });
    },
    async getComplianceNonCommute() {
      this.RoutesAndNonCommutes = await dashboardService.getComplianceNonCommute({
        workgroupType: 'VANPOOL',
        fromDate: this.startDateFormatted,
        toDate: this.endDateFormatted,
        routeId: this.selectedRouteId,
      });
      this.sort({ data: 'RoutesAndNonCommutes', param: 'nonCommute', sort: this.nonCommuteMilesArrow });
    },
    sort(options) {
      const { data, param, sort } = options;
      if (sort === 'arrow-up') this[data].sort((a, b) => Number(a[param]) - Number(b[param]));
      else this[data].sort((a, b) => Number(b[param]) - Number(a[param]));
    },
    directToRoute(routeId) {
      const queries = {
        ...this.$route.query,
        ...{
          selectedTab: 'routes',
          workgroupType: 'VANPOOL',
          routeId,
        },
      };
      const routeData = this.$router.resolve({ name: 'RouteStatuses', query: queries });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style scoped>
.custom-border {
  border: 1px solid #e6eaee;
  border-radius: 4px;
}
.out-countainer {
  background-color: white;
}
.container-header {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e6eaee;
}

.number-value {
  font-size: 18px;
  font-weight: 600;
}
.text-value {
  font-size: 12px;
  font-weight: 500;
}
</style>
