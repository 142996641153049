<template>
  <div v-if="isLoading" class="loadingApp position-relative" style=" min-height: 491px; ">
    <div class="imgContainer">
      <img :src="`${publicPath}flexigo-loader.gif`">
    </div>
  </div>
  <div v-else class="out-countainer custom-border">
    <div class="container-header row w-100 m-0">
      <div class="col-8 p-3">{{ this.$t('flexigo.dashboard.numberOfRidersByDayOfTheWeek') }}</div>
      <div class="col-4 p-2">
        <dropdown  :selectedRouteId="selectedRouteId"
          :list-data="groupedRoutesByNameWithSingleId"
          @select="(routeId, routeName) => selectRoutes(routeId, routeName)"/>
      </div>
    </div>
    <div v-if="isGraphReady" class="p-3">
      <highcharts :options="chartOptions"/>
    </div>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import { weekdaysUs } from '@/helpers/constants';
import dropdown from '../routeDropdown.vue';

export default {
  name: 'RidersGraph',
  components: { highcharts: Chart, dropdown },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      selectedRouteId: undefined,
      isGraphReady: false,
      chartOptions: {
        chart: {
          type: 'column',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
          //  align: 'left',
        },
        xAxis: {
          categories: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].map((item) => this.$t(`flexigo.common.${item}`)),
          gridLineWidth: 1,
        },
        yAxis: {
          title: {
            text: null,
            margin: 20,
          },
          //  labels: {
          //    align: 'right',
          //    // margin: 20,
          //  },
          // tickPositions: [0, 100, 300, 400, 500, 600, 700, 800, 900, 1000],
          // labels: {
          //   align: 'right',
          //   formatter() {
          //     if (this.value <= 1000) {
          //       return this.value;
          //     }
          //     return `${this.value / 1000}k`;
          //   },
          // },
        },
        tooltip: {
          shared: false,
          headerFormat: '',
          backgroundColor: '#363647',
          style: {
            color: 'white',
          },
          borderRadius: 4,
          borderWidth: 0,
        },
        legend: {
          symbolType: 'square',
          itemStyle: {
            color: '#7f8fa4',
            fontSize: '10px',
          },
        },
        plotOptions: {
          //  column: {
          //    stacking: 'normal',
          //  },
        },
        series: [{
          type: 'column',
          name: this.$t('flexigo.dashboard.ridersEmbarkedOnFixedRoutes'),
          color: '#5666FF',
          tooltip: {
            headerFormat: '{point.x}<br/><br/>',
            pointFormat: '<div style=" font-size: 0.8rem; "><strong>{point.y}</strong> Riders</div><br/> embarked on fixed routes',
          },
          // data: [100, 400, 200, 600, 300, 900, 1000],
          //  data: [0, 0, 0, 0, 0, 0, 0],
        }, {
          type: 'column',
          name: this.$t('flexigo.dashboard.ridersEmbarkedOnDynamicRoutes'),
          color: '#4FDE7B',
          tooltip: {
            headerFormat: '{point.x}<br/><br/>',
            pointFormat: '<div style=" font-size: 0.8rem; "><strong>{point.y}</strong> Riders</div><br/> embarked on dynamic routes',
          },
          // data: [900, 300, 800, 100, 100, 600, 500],
          // data: [1, 0, 0, 0, 0, 0, 2],
        }],
      },
    };
  },
  computed: {
    groupedRoutesByName(){
      const grouped =  Object.groupBy(this.value.routes, ({ name }) => name);
      Object.keys(grouped).forEach((key) => {
        grouped[key] = grouped[key].map(item => item.id)
      })
      return grouped;
    },
    groupedRoutesByNameWithSingleId(){
      const grouped =  Object.groupBy(this.value.routes, ({ name }) => name);
      const formatRoutes = []
      Object.keys(grouped).forEach((key) => {
        formatRoutes.push({
          name: key,
          id: grouped[key][0].id,
          type: grouped[key][0].type
        })
      })
      return formatRoutes;
    }
  },
  watch: {
    isLoading(value) {
      if (!value) this.prepareGraphData();
    },
  },
  mounted() {
    this.prepareGraphData();
  },
  methods: {
    selectRoutes(routeId, routeName){
      this.selectedRouteId = routeId;
      console.log('routeId', routeId);
      this.$emit('riders-refresh', this.groupedRoutesByName[routeName])
    },
    prepareGraphData() {
      this.isGraphReady = false;
      this.chartOptions.series[0].data = weekdaysUs.map((item) => this.value.FIXED[item]);
      this.chartOptions.series[1].data = weekdaysUs.map((item) => this.value.DYNAMIC[item]);
      this.isGraphReady = true;
    },
  },
};
</script>
<style scoped>

.custom-border{
   border: 1px solid #e6eaee;
   border-radius: 4px;
}
.out-countainer{
   background-color: white;
}
.container-header{
   font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e6eaee;
}

.number-value{
  font-size: 18px;
  font-weight: 600;
}
.text-value{
  font-size: 12px;
  font-weight: 500;
}
</style>
