/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/modules/Flexigo/services/auth';
import { objectifyByKey } from '@/helpers/utility';

import globalService from '../services/global';
import workgroupService from '../services/workgroup';
import companies from '../services/companies';
import settings from './modules/settings';
import workgroups from './modules/workgroup';
import routeList from './modules/routeList';
import routeEdit from './modules/routeEdit';
import planner from './modules/planner';
import flexiride from './modules/flexiride';
import dispatch from './modules/dispatch';
import riders from './modules/riders';

import registrationService from '../services/registration';
import notifications from '../services/notifications';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    shell: 'blank',
    userDetails: [],
    campuses: [],
    currentRouterComponent: null,
    currentComponent: null,
    selectedStep: 0,
    personnel: [],
    isBackgroundWhite: false,
    companyName: null,
    allWorkgroups: null,
    selectedWorkgroupId: null,
    companySettings: null,
    mainMenu: [],
    selectedPersonnel: null,
    isSideMenuVisible: false,
    isLoading: false,
    slidingPanelViewMode: null,
    notificationFormChanged: false,
    appType: 'corporate', // added for flexicar vehicle page
  },
  mutations: {
    RESET(state) {
      state = {};
      localStorage.clear();
      sessionStorage.clear();
    },
    SET_LAYOUT(state, payload) {
      state.shell = payload;
    },
    SET_USER(state, payload) {
      state.user = payload.data;
      localStorage.setItem('_token', state.user.token);
    },
    SET_SIDEBAR_STATUS(state, status) {
      state.isSidebarOpen = status;
    },
    SET_CAMPUSES(state, payload) {
      state.campuses = payload;
      localStorage.setItem('campusLocation', JSON.stringify(payload));
    },
    SET_CURRENT_ROUTER_COMPONENT(state, payload) {
      state.currentRouterComponent = payload;
    },
    SET_CURRENT_COMPONENT(state, payload) {
      state.currentComponent = payload;
    },
    SET_SELECTED_STEP(state, payload) {
      state.selectedStep = payload;
    },
    SET_PERSONNEL_DATA(state, payload) {
      state.personnel = payload;
    },
    SET_IS_BACKGROUND_WHITE(state, payload) {
      state.isBackgroundWhite = payload;
    },
    SET_COMPANY_NAME(state, payload) {
      state.companyName = payload;
    },
    SET_WORKGROUPS(state, payload) {
      state.allWorkgroups = payload;
      localStorage.setItem('workgroups', JSON.stringify(payload));
    },
    SET_SELECTED_WORKGROUP_ID(state, payload) {
      state.selectedWorkgroupId = payload;
    },
    SET_COMPANY_SETTINGS(state, payload) {
      state.companySettings = payload;
    },
    SET_USER_DETAILS(state, payload) {
      state.userDetails = payload;
    },
    SET_COMPANY_ID(state, payload) {
      state.companySettings.companyId = payload;
    },
    SET_MAIN_MENU(state, items) {
      state.mainMenu = items;
    },
    SET_SELECTED_PERSONNEL(state, payload) {
      state.selectedPersonnel = payload;
    },
    SET_IS_SIDE_MENU_VISIBLE(state, payload) {
      state.isSideMenuVisible = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_SLIDING_PANEL_VIEW_MODE(state, payload) {
      state.slidingPanelViewMode = payload;
    },
    SET_NOTIFICATION_FORM_CHANGED(state, payload) {
      state.notificationFormChanged = payload;
    },
  },
  actions: {
    async SET_MAIN_MENU(store, options = { lang: 'en', isRider: false }) {
      const r = await auth.getMainMenu(options.lang, options.isRider);
      store.commit('SET_MAIN_MENU', r);
    },
    toggleSideMenu(store) {
      store.commit('SET_IS_SIDE_MENU_VISIBLE', !store.state.isSideMenuVisible);
    },
    SET_IS_SIDE_MENU_VISIBLE(store, payload) {
      store.commit('SET_IS_SIDE_MENU_VISIBLE', payload);
    },
    async SET_COMPANY_SETTINGS(store, value) {
      const response = await globalService.getCompanySettings();
      store.commit('SET_COMPANY_SETTINGS', response);
    },
    async SET_USER_DETAILS(store, value) {
      const response = await registrationService.verify();

      store.commit('SET_USER_DETAILS', response.response);
    },
    async SET_WORKGROUPS(store, value) {
      const response = await workgroupService.getAll();
      const templates = objectifyByKey(response.templates, 'id');
      const combineWorkgroups = response.instances.map((el) => ({ template: templates[el.templateId], instance: el }));
      combineWorkgroups.sort((x, y) => (x.instance.isDefault > y.instance.isDefault ? -1 : 0));
      store.commit('SET_WORKGROUPS', combineWorkgroups);
    },
    async SET_CAMPUSES(store, value) {
      const response = await companies.getCampuses(store.state.userDetails.accountType);
      store.commit('SET_CAMPUSES', response);
    },
  },
  getters: {
    shell(state) {
      return state.shell;
    },
    application(state) {
      if (!state.user) return null;
      return state.user.applicationName;
    },
    user(state) {
      return state.user;
    },
    entryPage(state) {
      return state?.user?.entryPage;
    },
    hasPermission(state) {
      if (!state?.userDetails?.permissions) return () => false;
      const permissionList = state.userDetails.permissions;
      return (permission) => Boolean(permissionList.find((el) => el === permission));
    },
    hasRole(state) {
      if (!state?.userDetails?.permissions) return false;
      return (role) => state.userDetails.scopes.includes(role);
    },
    campuses(state) {
      return (id) => {
        if (!id) return state.campuses;
        return state.campuses.find((el) => id === el.id);
      };
    },
    allWorkgroups(state) {
      return state.allWorkgroups;
    },

    userDetails(state) {
      const isRider = !state.userDetails.accountType;
      const userDetails = {
        isRider,
        fullName: isRider ? state.userDetails?.personnel?.fullName : state.userDetails?.cn,
        email: isRider ? state.userDetails?.personnel?.email : state.userDetails?.uid,
        personnelDetails: isRider ? state.userDetails?.personnel : undefined,
      };
      return userDetails;
    },
    slidingPanelViewMode(state) {
      return state.slidingPanelViewMode;
    },
  },
  modules: {
    settings,
    workgroups,
    routeList,
    routeEdit,
    planner,
    flexiride,
    dispatch,
    riders,
  },
});
/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
