/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { eventColor } from '@/modules/Flexigo/screens/Flexiride/events';

const requests = {
  REQUESTED: '',
  AWAITING_PLANNING: '',
  PLANNED: '',
  ASSIGNED: '',
  CHANGE_REQUEST: '',
  CHANGE_REQUEST_CANCELLED: '',
  CANCELLED: '',
  ENROUTE_TO_PICKUP: '',
  ENROUTE_TO_DROPOFF: '',
  COMPLETED: '',
};

export default {
  state: {
    eventColor,
    selectedOrder: null,
  },

  mutations: {
    SET_SELECTED_ORDER(state, payload) {
      state.selectedOrder = payload;
    },
  },

  actions: {},

  getters: {
    selectedOrder(state) {
      return state.selectedOrder;
    },
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
