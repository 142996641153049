import PlannerServices from '@/modules/Flexigo/services/planner';
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

export default {
  state: {
    currentRegion: undefined,
    currentDepot: undefined,
    regionList: [],
    depotList: [],
  },

  mutations: {
    SET_REGION_LIST(state, regions) {
      state.regionList = regions;
    },
    SET_DEPOT_LIST(state, depots) {
      state.depotList = depots;
    },
    SET_CURRENT_REGION(state, region) {
      state.currentRegion = region;
    },
    SET_CURRENT_DEPOT(state, depot) {
      state.currentDepot = depot;
    },
  },

  actions: {
    REGION_LIST: async ({ commit }, options = { selectFirst: true, force: false }) => {
      // TODO merge array for default optionns
      let regionList = JSON.parse(localStorage.getItem('regionList'));
      if (options.force || !regionList) {
        regionList = await PlannerServices.getRegions();
      }
      commit('SET_REGION_LIST', regionList);
      console.log('store regionList', regionList);
      if (options.selectFirst) commit('SET_CURRENT_REGION', regionList ? regionList[0] : undefined);

      localStorage.setItem('regionList', JSON.stringify(regionList));
    },
    DEPOT_LIST: async ({ commit }, options = { selectFirst: true, force: false, regionId: undefined }) => {
      // TODO merge array for default optionns
      let depotList = JSON.parse(localStorage.getItem('depotList'));
      if (options.force || !depotList) {
        depotList = await PlannerServices.getDepots(null, options.regionId);
      }

      commit('SET_DEPOT_LIST', depotList);
      if (options.selectFirst) commit('SET_CURRENT_DEPOT', depotList ? depotList[0] : undefined);

      localStorage.setItem('depotList', JSON.stringify(depotList));
    },
  },

  getters: {
    currentPlannerRegion: (state) => state.currentRegion,
    currentPlannerDepot: (state) => state.currentDepot,
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
