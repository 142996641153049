import Swal from 'sweetalert2';

export default {
  name: 'toaster',
  data() {
    return {
      displayTimer: 3000,
    };
  },
  methods: {
    async getToaster(icon, title, text = '', timer = 3000) {
      this.displayTimer = timer;
      let backgroundColor = '';
      switch (icon) {
        case 'success':
          backgroundColor = '#f4fbf6';
          break;
        case 'error':
          backgroundColor = '#fcf5f6';
          break;
        case 'warning':
          backgroundColor = '#fefbf6';
          break;
        case 'info':
          backgroundColor = '#f6f7ff';
          break;
        default:
      }
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        background: backgroundColor,
        timer: this.displayTimer,
        timerProgressBar: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
          backdrop: 'swal2-backdrop-hide',
        },
        customClass: {
          content: 'content-class',
          container: 'container-class',
          title: 'title-class',
          popup: 'popup-class',
          icon: 'icon-class',
          header: 'header-class',
        },
      });
      Toast.fire({
        icon,
        title: `<b>${title}</b> <br>${text}`,
      });
    },
  },
};
