<template>
  <div class="card text-dark mb-3">
    <div class="card-header dashboard title-style big">{{ $t('flexigo.dashboard.occupancyRatio') }}</div>
    <div class="position-absolute top-0 end-0">
      <date v-model="selectedDate" class="mt-2 me-2" @date-changed="onChange" />
    </div>
    <div class="card-body" style="padding-bottom: 3.125rem">
      <highcharts :options="chartDefaults" />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import { intToDate } from '@/helpers/dateTime';
import Date from '../date.vue';

export default {
  components: { highcharts: Chart, Date },
  props: {
    chartData: {},
    localization: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedDate: 'lastSevenDays',
      chartDefaults: {},
      mainData: {
        actual: [],
        cardScans: [],
        planned: [],
      },
    };
  },
  watch: {
    chartData() {
      this.mainData.actual.length = 0;
      this.mainData.cardScans.length = 0;
      this.mainData.planned.length = 0;

      // This if case written for changing data when it's none.
      if (this.chartData.length === 0) {
        this.mainData.actual.splice(0, this.mainData.actual.length);
        this.mainData.cardScans.splice(0, this.mainData.cardScans.length);
        this.mainData.planned.splice(0, this.mainData.planned.length);
      }

      this.chartData.forEach((item) => {
        this.mainData.actual.push({
          name: intToDate(item.logDay, true),
          y: Number((item.cardUsageOverCapacity * 100).toFixed(2)),
        });

        this.mainData.cardScans.push({
          name: intToDate(item.logDay, true),
          y: Number((item.cardUsageOverPersonnel * 100).toFixed(2)),
        });

        this.mainData.planned.push({
          name: intToDate(item.logDay, true),
          y: Number((item.personelCountOverCapacity * 100).toFixed(2)),
        });
      });
    },
    localization() {
      this.changeLocalization();
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    changeLocalization() {
      this.chartDefaults.series = [
        {
          type: 'column',
          name: this.$t('flexigo.dashboard.actual'),
          data: this.mainData.actual,
        },
        {
          type: 'spline',
          name: this.$t('flexigo.dashboard.cardScans'),
          data: this.mainData.cardScans,
          connectNulls: true,
          marker: {
            lineWidth: 3,
            lineColor: '#1eb64d',
            fillColor: 'white',
          },
        },
        {
          type: 'spline',
          name: this.$t('flexigo.dashboard.planned'),
          data: this.mainData.planned,
          dashStyle: 'dot',
          marker: {
            enabled: false,
          },
        },
      ];
    },
    initChart() {
      this.chartDefaults = {
        credits: {
          enabled: false,
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        colors: ['#5667ff', '#1eb64d', 'black'],
        tooltip: {
          shared: true,
          backgroundColor: '#363647',
          style: {
            color: 'white',
          },
          formatter() {
            let message = '';

            const foundItem1 = this.points.find((item) => item.colorIndex === 0);
            const foundItem2 = this.points.find((item) => item.colorIndex === 1);
            const foundItem3 = this.points.find((item) => item.colorIndex === 2);

            message +=
              foundItem1 && foundItem1.y ? `${this.points[0].series.name} => %${foundItem1.y ? foundItem1.y : ''}` : '';
            message +=
              foundItem2 && foundItem2.y
                ? `<br>${this.points[1].series.name} => %${foundItem2.y ? foundItem2.y : ''}`
                : '';
            message +=
              foundItem3 && foundItem3.y
                ? `<br>${this.points[2].series.name} => %${foundItem3.y ? foundItem3.y : ''}`
                : '';

            return message;
          },
        },
        series: [
          {
            type: 'column',
            name: this.$t('flexigo.dashboard.actual'),
            data: this.mainData.actual,
          },
          {
            type: 'spline',
            name: this.$t('flexigo.dashboard.cardScans'),
            data: this.mainData.cardScans,
            connectNulls: true,
            marker: {
              lineWidth: 3,
              lineColor: '#1eb64d',
              fillColor: 'white',
            },
          },
          {
            type: 'spline',
            name: this.$t('flexigo.dashboard.planned'),
            data: this.mainData.planned,
            dashStyle: 'dot',
            marker: {
              enabled: false,
            },
          },
        ],
      };
    },
    onChange(payload) {
      this.$emit('selected-date', payload);
    },
  },
};
</script>

<style></style>
