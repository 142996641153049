<template>
  <div class="card card-height">
    <div class="card-body" :class="{'text-center' : img}">
      <img v-if="img" class="mb-2" :src="img" >
      <div class="card-title title-style bold">{{title}}</div>
      <div class="card-title value-style">{{value}}</div>
      <div class="card-title metric-style">{{metric}}</div>

      <div class="row">
        <div v-if="topIcon" class="col-3">
          <img :src="topIcon" >
        </div>
        <div class="col" :class="{'mb-2': topLinkAddress}">
          <div class="number-value-style">{{topValue}}</div>
          <div class="metric-style">{{topMetric}}</div>
        </div>
        <a v-if="topLink && !topLinkAddress" class="right-arrow-after">{{topLink}}</a>
        <a v-if="topLinkAddress" target="_parent" :href="topLinkAddress" class="metric-style black right-arrow-after text-decoration-none">{{topLink}}</a>
      </div>

      <hr class="line-style">

      <div>
        <span v-if="leftValue" class="float-start metric-style black">{{leftValue}}</span>
        <span v-if="rightValue && !rightValueLink" class="float-end title-style hover-title right-arrow-after">{{rightValue}}</span>
        <a v-if="rightValueLink" target="_parent" :href="rightValueLink">
          <span class="float-end title-style hover-title right-arrow-after">{{rightValue}}</span>
        </a>
      </div>

      <div class="row">
        <div v-if="bottomIcon" class="col-3">
          <img :src="bottomIcon" >
        </div>
        <div class="col mb-2">
          <div class="number-value-style">{{bottomValue}}</div>
          <div class="metric-style">{{bottomMetric}}</div>
        </div>
        <a v-if="bottomLink && !bottomLinkAddress" class="link-style right-arrow-after">{{bottomLink}}</a>
        <a v-if="bottomLinkAddress" target="_parent" :href="bottomLinkAddress" class="metric-style black right-arrow-after text-decoration-none">{{bottomLink}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    metric: {
      type: String,
      default: null,
    },
    leftValue: {
      type: [String, Number],
      default: null,
    },
    rightValue: {
      type: [String, Number],
      default: null,
    },
    rightValueLink: {
      type: String,
      default: null,
    },
    topIcon: {
      type: String,
      default: null,
    },
    topValue: {
      type: [String, Number],
      default: null,
    },
    topMetric: {
      type: String,
      default: null,
    },
    topLink: {
      type: String,
      default: null,
    },
    topLinkAddress: {
      type: String,
      default: null,
    },
    bottomIcon: {
      type: String,
      default: null,
    },
    bottomValue: {
      type: [String, Number],
      default: null,
    },
    bottomMetric: {
      type: String,
      default: null,
    },
    bottomLink: {
      type: String,
      default: null,
    },
    bottomLinkAddress: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>

</style>
