<template>
  <div class="top-navbar">
    <div class="topbar-logo d-md-none">
      <img
        :src="`${baseUrl}/img/flexigo-logo.png`"
        :srcset="`${baseUrl}/img/flexigo-logo.png, ${baseUrl}/img/flexigo-logo@2x.png, ${baseUrl}/img/flexigo-logo@3x.png`"
        alt="Flexigo"
      />
    </div>
    <div class="notifications"></div>
    <div class="topbar-user">
      <div class="initials-circle d-md-flex d-none">
        <div class="initials">{{ initials }}</div>
      </div>
      <div class="user-details">
        <div class="username" data-bs-toggle="dropdown">
          <a href="#" @click.prevent
            >{{ fullName }}
            <svg width="8" height="5" viewBox="0 0 8 5" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M.538 0h6.924c.479 0 .718.637.38 1.011L4.38 4.827a.505.505 0 0 1-.762 0L.16 1.01C-.181.637.059 0 .539 0z"
                fill="#363647"
                fill-rule="nonzero"
              />
            </svg>
          </a>
        </div>
        <ul class="dropdown-menu">
          <!-- <li><a class="dropdown-item" href="#">Action</a></li> -->
          <!-- <li><a class="dropdown-item" href="#">Change Password</a></li> -->
          <li>
            <router-link :to="{ name: 'ChangePassword' }" class="dropdown-item">{{
              $t('flexigo.common.changePassword')
            }}</router-link>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click.prevent="logout">{{ $t('flexigo.employee.dashboard.signOut') }}</a>
          </li>
          <!-- <li><hr class="dropdown-divider"></li> -->
          <!-- <li><a class="dropdown-item" href="#">Separated link</a></li> -->
        </ul>
        <div class="email text-secondary">{{ email }}</div>
      </div>
      <div class="hamburger-menu d-md-none" @click="toggleSideMenu">
        <svg width="35" height="35" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <rect x="15" y="25" width="70" height="5" fill="#0B0B17"></rect>
          <rect x="15" y="45" width="70" height="5" fill="#0B0B17"></rect>
          <rect x="15" y="65" width="70" height="5" fill="#0B0B17"></rect>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/modules/Flexigo/services/auth';

export default {
  name: 'Topbar',
  data() {
    return {
      baseUrl: process.env.BASE_URL,
    };
  },
  computed: {
    fullName() {
      return this.$store.getters.userDetails.fullName;
    },
    email() {
      return this.$store.getters.userDetails.email;
    },
    initials() {
      if (!this.fullName) return '';
      const name = this.fullName;
      const words = name.trim().split(/\s+/);

      const firstInitial = words.length > 0 ? words[0][0] : ' ';
      const lastInitial = words.length > 1 ? words[words.length - 1][0] : ' ';

      return (firstInitial + lastInitial).toUpperCase();
    },
  },
  methods: {
    async logout() {
      try {
        const [base] = window.location.hostname.split('.');
        const urls = {
          localhost: 'http://localhost:5431',
          secure: 'https://secure.flexigo.com?lang=tr',
          portal: 'https://portal.flexigo.com?lang=en',
          secure3: 'https://secure3.vektorteknoloji.com/flexigo/',
          timezone: 'https://timezone.flexigo.com',
        };
        await auth.logout();
        sessionStorage.clear();
        window.location.href = urls[base];
      } catch (error) {
        console.error(error);
      }
    },
    toggleSideMenu() {
      this.$store.dispatch('toggleSideMenu');
    },
  },
};
</script>

<style lang="scss"></style>
