import { dashboard } from '@/endpoint.flexigo';
import Repository from './repository';

const client = new Repository();

export default {
  async getTaskCount(options = {}) {
    const { fromDate, toDate, campusId } = options;
    const r = await client.get(dashboard.taskCount, { fromDate, toDate, campusId });
    return r;
  },
  async getStats(options = {}) {
    const { fromDate, toDate, campusId } = options;
    const r = await client.get(dashboard.stats, { fromDate, toDate, campusId });
    return r.response;
  },
  async getRouteStats(options = {}) {
    const {
      campusId, fromDate, toDate, workingTypes, workgroupType,
    } = options;

    let workgroupIds;
    if (options?.workgroupIds?.length) workgroupIds = options.workgroupIds.join(',');

    const r = await client.get(dashboard.route, {
      campusId, fromDate, toDate, workingTypes, workgroupIds, workgroupType,
    });
    return r;
  },
  async getTelematicDevices(options = {}) {
    const {
     fromDate, toDate, workgroupType
    } = options;
    const r = await client.get(dashboard.telematicdevices, { fromDate, toDate, workgroupType});
    return r;
  },
  async getActionsRequired() {
    const r = await client.get(dashboard.actionsRequired);
    return r;
  },
  async getCompliance(options = {}) {
    const {
      campusId, fromDate, routeId, toDate, workingTypes, workgroupType,
    } = options;

    let workgroupIds;
    if (options?.workgroupIds?.length) workgroupIds = options.workgroupIds.join(',');

    let routeIds;
    if (options?.routeIds?.length) routeIds = options.routeIds.join(',');

    const r = await client.get(dashboard.compliance, {
      campusId, fromDate, routeId, toDate, workingTypes, workgroupIds, routeIds, workgroupType,
    });
    return r;
  },
  async getComplianceChartRidership(options = {}) {
    const {
      campusId, fromDate, routeId, toDate, workingTypes, workgroupType,
    } = options;

    let workgroupIds;
    if (options?.workgroupIds?.length) workgroupIds = options.workgroupIds.join(',');

    let routeIds;
    if (options?.routeIds?.length) routeIds = options.routeIds.join(',');

    const r = await client.get(dashboard.complianceChartRidership, {
      campusId, fromDate, routeId, toDate, workingTypes, workgroupIds, routeIds, workgroupType,
    });
    return r;
  },
  async getComplianceNonCommute(options = {}) {
    const {
      campusId, fromDate, routeId, toDate, workingTypes, workgroupType,
    } = options;

    let workgroupIds;
    if (options?.workgroupIds?.length) workgroupIds = options.workgroupIds.join(',');

    let routeIds;
    if (options?.routeIds?.length) routeIds = options.routeIds.join(',');

    const r = await client.get(dashboard.complianceNonCommute, {
      campusId, fromDate, routeId, toDate, workingTypes, workgroupIds, routeIds, workgroupType,
    });
    return r;
  },
  async getOccupancyRatios(options = {}) {
    const {
      campusId, fromDate, routeId, toDate, workingTypes, workgroupType,
    } = options;

    let workgroupIds;
    if (options?.workgroupIds?.length) workgroupIds = options.workgroupIds.join(',');

    let routeIds;
    if (options?.routeIds?.length) routeIds = options.routeIds.join(',');

    const r = await client.get(dashboard.occupancyRatios, {
      campusId, fromDate, routeId, toDate, workingTypes, workgroupIds, routeIds, workgroupType,
    });
    return r;
  },
  async getOccupancyRatiosAllRoutes(options = {}) {
    const {
      campusId, fromDate, toDate, workingTypes, workgroupType,
    } = options;

    let workgroupIds;
    if (options?.workgroupIds?.length) workgroupIds = options.workgroupIds.join(',');

    const r = await client.get(dashboard.occupancyRatiosAllRoutes, {
      campusId, fromDate, toDate, workingTypes, workgroupIds, workgroupType,
    });
    return r;
  },
  async getNumberOfRiders(options = {}) {
    const {
      campusId, fromDate, routeId, toDate, workingTypes, workgroupType,
    } = options;

    let workgroupIds;
    if (options?.workgroupIds?.length) workgroupIds = options.workgroupIds.join(',');

    let routeIds;
    if (Array.isArray(routeId)) {
      routeIds = routeId.join(',');
    } else {
      routeIds = routeId;
    }

    const r = await client.get(dashboard.numberOfRiders, {
      campusId, fromDate, routeIds, toDate, workingTypes, workgroupIds, workgroupType,
    });
    return r;
  },
  async getOnTimePerformance(options = {}) {
    const {
      campusId, fromDate, routeId, toDate, workgroupType,
    } = options;

    let workgroupIds;
    if (options?.workgroupIds?.length) workgroupIds = options.workgroupIds.join(',');

    let routeIds;
    if (options?.routeIds?.length) routeIds = options.routeIds.join(',');

    const r = await client.get(dashboard.onTimePerformance, {
      campusId, fromDate, routeId, toDate, workgroupIds, routeIds, workgroupType,
    });
    return r;
  },
  async getOccupancy(options = {}) {
    const { fromDate, toDate, campusId } = options;
    const r = await client.get(dashboard.occupancy, { fromDate, toDate, campusId });
    return r.response;
  },
  async getRideRequests(options = {}) {
    const {
      campusId, fromDate, toDate, workingTypes, workgroupType,
    } = options;

    let workgroupIds;
    if (options?.workgroupIds?.length) workgroupIds = options.workgroupIds.join(',');

    const r = await client.get(dashboard.rideRequests, {
      campusId, fromDate, toDate, workingTypes, workgroupIds, workgroupType,
    });
    return r;
  },
  async getTickets(options = {}) {
    const { fromDate, toDate, campusId } = options;
    const r = await client.get(dashboard.tickets, { fromDate, toDate, campusId });
    return r.response;
  },
  async getRouteStates(options = {}) {
    const { fromDate, toDate, campusId } = options;
    const r = await client.get(dashboard.routeStates, { fromDate, toDate, campusId });
    return r.response;
  },
  async getDistrictOccupancy(options = {}) {
    const { fromDate, toDate, campusId } = options;
    const r = await client.get(dashboard.districtOccupancy, { fromDate, toDate, campusId });
    return r.response;
  },

  async getDistrictData() {
    const r = await client.get(dashboard.districtMapData);
    return r.response;
  },
};
