<template>
  <div class="row user-data">
    <div class="col">
      <a v-if="link" target="_blank" :href="link" class="link-style normal" :class="{'inactive-link': !amount}">
        <div class="card text-dark mb-3 info-card">
          <div class="position-relative">
            <div v-if="time" class="position-absolute end-0 time-rectangle">
              <div class="metric-style small bold">{{time}}</div>
            </div>
          </div>
          <div class="card-body">
            <div class="text-center inside-card">
              <div class="amount number-value-style" :style="`color: ${color}`"><ins>{{amount}}</ins></div>
              <div class="metric-style black">{{subtitle}}</div>
            </div>
          </div>
        </div>
      </a>
      <div v-if="!link" class="card text-dark mb-3 info-card" :class="{'h-100': isHeightFull}">
        <div class="position-relative">
          <div v-if="time" class="position-absolute end-0 time-rectangle">
            <div class="metric-style small bold">{{time}}</div>
          </div>
        </div>
        <div class="card-body" :class="{'d-flex justify-content-center align-items-center': isHeightFull}">
          <div class="text-center inside-card">
            <div class="amount number-value-style" :style="`color: ${color}`"><ins>{{amount}}</ins></div>
            <div class="metric-style black">{{subtitle}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    time: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#363647',
    },
    link: {
      type: String,
      default: null,
    },
    isHeightFull: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    if (this.amount == undefined) console.log(this.subtitle);
  },
};
</script>

<style>

</style>
