import { route } from '@/endpoint.flexigo';
import Repository from './repository';

const client = new Repository();

export default {
  async getTracksById(routeIds) {
    const r = await client.post(route.tracksById, { routeIds });
    return r.response;
  },

  async getDriverVehicleMatch(routeId) {
    const r = await client.get(route.driverVehicleMatch, { routeId });
    return r;
  },

  async getDriverVehicleMatchRing(ringId = undefined) {
    const r = await client.get(route.driverVehicleMatch, ringId ? { ringId } : {});
    return r;
  },

  async getDriverVehicleMatchArchive(routeId) {
    const r = await client.get(route.driverVehicleMatch, { routeId, mode: 'ARCHIVE' });
    return r;
  },

  async updateVehicleRelation(id, payload) {
    const r = await client.post(route.updateVehicleRelation.replace(':routeId', id), payload);
    return r;
  },

  async updateVehicleRelationRing(ringId, payload) {
    const url = route.updateVehicleRelationRing.replace(':ringId', ringId);
    const r = await client.post(url, payload);
    return r;
  },
};
