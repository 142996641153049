<template>
  <div class="position-relative">
    <input
      ref="passwordInput"
      :value="password"
      :type="passwordType"
      class="bold-500 outer-border rounded has-cursor-pointer p-3 w-100"
      :name="name"
      :placeholder="placeholder"
      autocapitalize="off"
      spellcheck="false"
      inputmode="text"
      @input="$emit('change', $event.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />

    <button type="button" class="visibility-icon" tabindex="-1" @click.prevent="onVisibilityToggle">
      <img :src="toggleVisibilityIconPath" />
    </button>
  </div>
</template>
<script>
export default {
  name: 'passwordInput',
  model: {
    prop: 'password',
    event: 'change',
  },
  props: {
    password: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
      default: 'password',
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('flexigo.login.password');
      },
    },
  },
  data() {
    return {
      passwordValue: null,
      isPasswordVisible: false,
    };
  },
  computed: {
    passwordType() {
      return this.isPasswordVisible ? 'text' : 'password';
    },
    toggleVisibilityIconPath() {
      return this.isPasswordVisible
        ? `${this.baseUrl}icons/eye-gray-shut.svg`
        : `${this.baseUrl}icons/eye-gray-open.svg`;
    },
  },

  methods: {
    onVisibilityToggle() {
      this.isPasswordVisible = !this.isPasswordVisible;
      if (this.$refs.passwordInput) {
        this.$refs.passwordInput.focus();
      }
    },
  },
};
</script>
