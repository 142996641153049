var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"search-dashboard-map search-route p-3"},[_c('div',{staticClass:"text-center search-input"},[_c('div',{staticClass:"position-absolute search-icon"},[_c('img',{attrs:{"src":`${_vm.publicPath}icons/search.svg`}})]),_c('gmap-autocomplete',{staticClass:"form-control input-text",attrs:{"placeholder":_vm.$t('flexigo.common.searchLocation')},on:{"place_changed":function($event){return _vm.placeChanged($event)}}})],1)]),_c('gmap-map',{ref:"gmap",staticStyle:{"width":"100%","height":"417px"},attrs:{"center":_vm.map.center,"zoom":_vm.map.zoom,"map-type-id":_vm.map.mapTypeId,"options":_vm.map.options}},[_vm._l((_vm.clusters),function(cluster){return _c('gmap-polygon',{key:cluster.geohash,attrs:{"paths":cluster.bounds,"options":{
        fillOpacity: cluster.opacity,
        fillColor: '#FFB200',
        strokeWeight: 0.5,
        strokeOpacity: 1,
        strokeColor: '#fff',
        zIndex: 10,
      }},on:{"click":function($event){return _vm.displayClusterDetails(cluster)}}})}),_vm._l((_vm.routes),function(route){return _vm._l((route.stations),function(station){return _c('gmap-marker',{key:station.id,attrs:{"position":{lat: station.location.latitude, lng: station.location.longitude},"icon":`${_vm.publicPath}icons/map-blue-dotmap-blue-dot.png`}})})}),(_vm.routes.length)?_vm._l((_vm.routes),function(route){return _c('gmap-polyline',{key:route.id,attrs:{"path":route.polylinePath,"options":{
          strokeColor: route.avgOccupancy < 60 ? 'red' : 'green',
          strokeWeight: 1.3,
          zIndex: 11,
        }},on:{"click":_vm.onLineHover}})}):_vm._e()],2),_c('div',{staticClass:"map-legend mt-3"},[_vm._v(" "+_vm._s(_vm.$t('flexigo.common.riders'))+"   "),_vm._l((_vm.opacityScale),function(s){return _c('span',{key:s,staticClass:"small mb-1 me-3"},[_vm._v(" "+_vm._s(_vm.$t('flexigo.dashboard.over', [s]))+" "),_c('span',{staticClass:"scale-box",style:({opacity: _vm.getOpacity(s-1)})})])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }