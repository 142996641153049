<template>
  <div class="btn-group search-workgroup">
    <button
      id="dropdownMenuClickableInside"
      type="button"
      class="btn dropdown-toggle"
      data-bs-toggle="dropdown"
      data-bs-display="static"
      aria-expanded="false"
      data-bs-auto-close="outside"
      style=" min-width: 300px; ">
      {{selectedValue}}
    </button>
    <ul ref="dropdown-menu"  class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end p-2 bg-white workgroup-check-box-container overflow-hidden max-height-unset" aria-labelledby="dropdownMenuClickableInside" style="width: 300px;">
      <div class="input-group mb-3">
        <input v-model="filterText" type="search" class="form-control search-input-with-icon" :placeholder="$t('flexigo.routeList.routeGroupName')" aria-describedby="basic-addon1">
      </div>
      <a class="dropdown-item mb-2" href="#" @click.prevent="removeAllCheck">{{$t('flexigo.dashboard.allCampuses')}}</a>
      <div class="item-list" style="max-height: calc(100vh - 350px);">
        <div v-for="el in filteredData" :key="el.campus.id" class="mt-3">
          <div class="px-3 py-2 d-flex align-items-center has-cursor-pointer">
            <input :id="`select-all-checkbox-${el.campus.id}`" :ref="el.campus.id"  v-model="allSelected[el.campus.id]" class="form-check-input select-all p-2 m-0" indeterminate.prop="true" type="checkbox" @click="selectAll(el.campus.id)">
            <label class="form-check-label campus name ps-2" :for="`select-all-checkbox-${el.campus.id}`" role="button">
              {{el.campus.name}}
            </label>

            <span class="px-2 transform-180" @click.prevent="onParentClick($event, el.campus.id)">
              <svg width="14px" height="8px" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg" class="menu-arrow">
                <polyline fill="none" stroke="#7f858f" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" points="1 7, 7 1, 13 7" />
              </svg>
            </span>
          </div>

          <div :ref="`workgroups-container-${el.campus.id}`" :class="`workgroups-container-${el.campus.id}`" >
            <div v-for="workgroup in el.workgroups" :key="workgroup.id" class="ps-5 pe-3 py-2">
              <input :id="`select-checkbox-${el.campus.id}-${workgroup.id}`" :ref="el.campus.id"  v-model="selectedWorkgroups" :workgroup-id="workgroup.id" class="form-check-input select-all p-2 m-0" indeterminate.prop="true" type="checkbox" :value="workgroup" @click="select($event, el.campus.id)">
              <label class="form-check-label workgroup ps-2 d-inline" :for="`select-checkbox-${el.campus.id}-${workgroup.id}`" role="button">
                {{workgroup.name}}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end mx-3 py-3">
        <div class="border-top">
          <button type="button" class="btn btn-primary mt-3" @click="apply">{{$t('flexigo.employee.dashboard.apply')}}</button>
        </div>
      </div>
    </ul>  </div>
</template>

<script>
export default {
  name: 'WorkgroupSearch',
  props: {
    workgroups: {
      type: Array,
      default: () => [],
    },
    campusesAndWorkgroups: {
      type: Array,
      default: () => [],
    },
    selectedWorkgroupIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterText: '',
      selectedWorkgroups: [],
      allSelected: {},
    };
  },
  computed: {
    filteredData() {
      if (this.filterText.length) {
        const filtered = JSON.parse(JSON.stringify(this.campusesAndWorkgroups));

        const campusFilter = filtered.filter((el) => el.campus.name.toLowerCase().includes(this.filterText.toLowerCase()));
        if (campusFilter.length) return campusFilter;

        filtered.forEach((item) => {
          item.workgroups = item.workgroups.filter((workgroup) => workgroup.name.toLowerCase().includes(this.filterText.toLowerCase())); // eslint-disable-line
        });
        return filtered.filter((el) => el.workgroups.length);
      }
      return this.campusesAndWorkgroups;
    },
    selectedValue() {
      return (this.selectedWorkgroups.length ? this.$t('flexigo.dashboard.routeGroupSelected', [this.selectedWorkgroups.length]) : this.$t('flexigo.dashboard.allCampuses'));
    },
    campuses() {
      return this.$store.getters.campuses();
    },
  },
  mounted() {
    if (this.workgroups.length) {
      this.selectedWorkgroups = this.workgroups;
    }
  },
  methods: {
    selectAll(campusId) {
      const selected = this.campusesAndWorkgroups.find((item) => item.campus.id === campusId)?.workgroups;
      if (!this.allSelected[campusId]) {
        selected.forEach((item) => {
          if (!this.selectedWorkgroups.find((el) => el.id === item.id)) {
            this.selectedWorkgroups.push(item);
          }
        });
      } else {
        this.selectedWorkgroups = this.selectedWorkgroups.filter((item) => item.campusId !== campusId);
      }
    },
    select(event, campusId) {
      if (!event?.target?.checked) { this.allSelected[campusId] = event?.target?.checked; }
    },
    apply() {
      this.$refs['dropdown-menu'].classList.remove('show');
      this.$emit('select', this.selectedWorkgroups);
    },
    removeAllCheck() {
      this.selectedWorkgroups = [];
      this.allSelected = {};
    },
    onParentClick($event, campusId) {
      $event.currentTarget.classList.toggle('transform-180');
      this.$refs[`workgroups-container-${campusId}`][0].classList.toggle('d-none');
    },
  },
};
</script>

<style lang="scss" >
.transform-180{
  transform: rotate(180deg);
}

.search-input-with-icon {
  background-image: url('../../../../public/icons/search.svg');
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding-left: 40px;
}

.max-height-unset {
  max-height: unset !important;
}

.campus.name {
  font-weight: bolder;
  word-wrap: break-word;
  width: 80%;
  display: inline-block;
}

.search-workgroup {
  div.item-list {
    overflow-y: auto;
    overflow-x: hidden;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7F8FA4;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(198, 191, 191);
    opacity: 0.5;
  }
}
</style>
