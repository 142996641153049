export async function requestNotificationPermission() {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    console.log('Notification permission granted.');
  } else {
    console.error('Notification permission not granted.');
  }
}

export function hasNotificationPermission() {
  console.log('Notification.permission', Notification.permission);
  return Notification.permission === 'granted';
}
/* eslint-disable no-new */
export function showNotification(title, options) {
  if (Notification.permission === 'granted') {
    new Notification(title, options);
  } else {
    console.error('Notification permission not granted.');
  }
}
/* eslint-enable no-new */

function getUrl() {
  const url = new URL(window.location);
  if (url.origin.includes('localhost') || url.origin.includes('secure3')) {
    return `${url.origin}/admin/flexigo/#/flexiride/requests`;
  }
  return `${url.origin}/admin/flexigo/#/flexiride/requests`;
}

export function sendToServiceWorker(eventType, locale, text) {
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'SHOW_NOTIFICATION',
      data: {
        locale,
        text,
        eventType,
        targetUrl: getUrl(),
      },
    });
  }
}

export default showNotification;
