import Axios from 'axios';
import qs from 'qs';
import settings from './settings';

const HereRouting = () => {
  const {
    apiKey, version,
  } = settings;

  const baseURL = `https://${settings.endpoints.routing}/${version}/`;

  function axios() {
    return Axios.create({
      baseURL,
    });
  }

  const client = axios();

  function prepareWaypoints(waypoints) {
    if (!waypoints) return [];
    return waypoints.map((w) => {
      const { lat, lng } = w;
      if (w.passThrough) return `${lat},${lng}!passThrough=${w.passThrough}`;
      if (w.stopDuration) return `${lat},${lng}!stopDuration=${w.stopDuration}`;
      return `${lat},${lng}`;
    });
  }

  async function calculateRoute(options) {
    const {
      origin,
      destination,
      via,
      transportMode,
      routingMode,
      departureTime,
      arrivalTime,
      alternatives,
    } = options;

    const params = {
      apiKey,
      origin,
      destination,
      transportMode,
      via: prepareWaypoints(via),
      routingMode: routingMode || 'fast',
      alternatives: alternatives || 0,
      lang: 'en-US', // 'tr-tr',
      return: ['summary', 'polyline', 'routeHandle', 'routingZones', 'tolls', 'travelSummary', 'typicalDuration'].join(','),
      // routeattributes: 'wp,sh,wp',
      // spans: 'truckAttributes',
    };

    if (departureTime) params.departureTime = departureTime;
    if (arrivalTime && !departureTime) params.arrivalTime = arrivalTime;

    function paramsSerializer(param) {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    }

    const r = await client.get(`${baseURL}routes`, { params, paramsSerializer });
    return r.data;
  }

  return Object.freeze({
    calculateRoute,
  });
};

export default HereRouting;
// https://router.hereapi.com/v8/routes?transportMode=car
// https://router.hereapi.com/v8/?app_id=k9WYfk92WtcTR8UV4XHR&app_code=HoqTDteEneuCYhNUcsLPoQ&routeattributes=wp,sh,wp&language=tr-tr
// https://router.api.here.com/routing/v8/?app_id=k9WYfk92WtcTR8UV4XHR&app_code=HoqTDteEneuCYhNUcsLPoQ&routeattributes=wp,sh,wp&language=tr-tr
