<template>
  <div class="row mb-3">
    <div class="col">
      <card
        :topIcon="`${publicPath}icons/dashboard/users.svg`"
        :top-value="personelData && personelCount"
        :top-metric="$t('flexigo.carpool.approvedMatches.rider')"
        :top-link="$t('flexigo.dashboard.viewUsers')"
        :top-link-address="getLink('app.route.personel')"
        :bottomIcon="`${publicPath}icons/dashboard/routes.svg`"
        :bottom-value="routeDataObject.totalRoute"
        :bottom-metric="$t('flexigo.common.route')"
        :bottom-link="$t('flexigo.dashboard.viewRoutes')"
        :bottom-link-address="getLink('app.route.routes')"/>
    </div>
    <div class="col">
      <card
        :img="`${publicPath}icons/dashboard/bus.svg`"
        :title="$t('flexigo.dashboard.occupancyRatio')"
        :value="`%${vehicleOccupancy && vehicleOccupancy.average}`"
        :metric="$t('flexigo.dashboard.avg')"
        :left-value="$t('flexigo.dashboard.belowWithPercent', [vehicleOccupancy.upperTreshold])"
        :right-value="vehicleOccupancy.belowTreshold"
        :right-value-link="getLink('app.route.routes')" />
    </div>
    <div class="col">
      <card
        :img="`${publicPath}icons/dashboard/time.svg`"
        :title="$t('flexigo.dashboard.timeCoefficient')"
        :value="routeDataObject.timeCoefficient ? formatNumber(routeDataObject.timeCoefficient.average, $i18n.locale) : '-'"
        :metric="$t('flexigo.dashboard.avg')"
        :left-value="$t('flexigo.dashboard.over', [routeDataObject.timeCoefficient ? routeDataObject.timeCoefficient.overTreshold : 0])"
        :right-value="routeDataObject.timeCoefficient ? String(routeDataObject.timeCoefficient.aboveTreshold) : '0'"
        :right-value-link="getLink('app.route.personel')" />
    </div>
    <div class="col">
      <card
        :img="`${publicPath}icons/dashboard/walk.svg`"
        :title="$t('flexigo.dashboard.walkingDistance')"
        :value="`${walkingDistance.average} ${lengthSmallerMetric}`"
        :metric="$t('flexigo.dashboard.avg')"
        :left-value="`${$t('flexigo.dashboard.over', [walkingDistance.upperTreshold])} ${lengthSmallerMetric}`"
        :right-value="walkingDistance.belowTreshold"
        :right-value-link="getLink('app.route.personel')" />
    </div>
    <div class="col">
      <card
        :img="`${publicPath}icons/dashboard/paper.svg`"
        :top-value="`${currencySign} ${
          budgetData && formatNumber(budgetData.totalBudget, $i18n.locale)
        }`"
        :top-metric="$t('flexigo.dashboard.totalCost')"
        :bottom-value="`${currencySign} ${formatNumber(budgetData && budgetData.perEmployee, $i18n.locale)}`"
        :bottom-metric="$t('flexigo.dashboard.perEmployee')" />
    </div>
    <div class="col">
      <card
        :img="`${publicPath}icons/dashboard/bubble.svg`"
        :top-value="`${emission.total}/${$t('flexigo.common.month')}`"
        :top-metric="$t('flexigo.dashboard.totalEmission')"
        :bottom-value="`${emission.perEmployee}/${$t('flexigo.common.month')}`"
        :bottom-metric="$t('flexigo.dashboard.perEmployeeMile')" />
    </div>
  </div>
</template>

<script>
import { formatNumber, formatWeight } from '@/helpers/utility';
import { mtToMile, mtToKm } from '@/helpers/convert';
import {
  currency, weight, length, conversionRates,
} from '@/helpers/appDefaults';
import Card from '../card/card.vue';

export default {
  name: 'FlexiGoCards',
  components: { Card },
  props: {
    routeData: {
      type: Array,
      default: () => [],
    },
    personelData: {
      type: Array,
      default: () => [],
    },
    budgetData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    routeDataObject() {
      return this.routeData && this.routeData[0] ? this.routeData[0] : {};
    },
    walkingDistance() {
      if (!this.routeDataObject.walkingDistance) {
        return {
          average: 0,
          upperTreshold: 0,
          belowTreshold: this.routeDataObject.walkingDistance?.belowTreshold || 0,
          total: this.routeDataObject.walkingDistance?.total || 0,
        };
      }
      const distance = this.$store.state.companySettings.lengthUnit === 'IMPERIAL' ? mtToMile(this.routeDataObject?.walkingDistance.average) : mtToKm(this.routeDataObject?.walkingDistance.average);
      const average = formatNumber(distance, this.$i18n.locale);

      const upperTreshold = this.$store.state.companySettings.lengthUnit === 'IMPERIAL' ? formatNumber(mtToMile(this.routeDataObject?.walkingDistance.upperTreshold)) : formatNumber(mtToKm(this.routeDataObject?.walkingDistance.upperTreshold));
      return {
        average,
        upperTreshold,
        belowTreshold: this.routeDataObject.walkingDistance.belowTreshold,
        total: this.routeDataObject.walkingDistance.total,
      };
    },
    emission() {
      if (this.routeDataObject.emission) {
        return {
          total: formatWeight(this.routeDataObject.emission.totalEmission),
          perEmployee: formatWeight(this.routeDataObject.emission.perEmployeeMile),
        };
      }
      return {
        total: this.routeDataObject.emission ? this.routeDataObject.emission.totalEmission : '-',
        perEmployee: this.routeDataObject.emission ? this.routeDataObject.emission.perEmployeeMile : '-',
      };
    },
    vehicleOccupancy() {
      return this.routeDataObject.vehicleOccupancy ? this.routeDataObject.vehicleOccupancy : '-';
    },
    currencySign() {
      return this.$store.state.companySettings ? currency[this.$store.state.companySettings.currency] : '';
    },
    mtToFeet() {
      return conversionRates.mtToFeet;
    },
    kgToPound() {
      return conversionRates.kgToPound;
    },
    // weightUnit() {
    //   return this.$store.state.companySettings ? this.$store.state.companySettings.weightUnit : '';
    // },
    weightUnit() {
      return this.$store.state.companySettings ? weight[this.$store.state.companySettings.weightUnit] : '';
    },
    lengthSmallerMetric() {
      return this.$store.state.companySettings ? length[this.$store.state.companySettings.weightUnit] : '';
    },
    personelCount() {
      return this.personelData && this.personelData.reduce((acc, obj) => acc + obj.count, 0);
    },
  },
  methods: {
    getLink(route, query = '') {
      const link = this.$router.resolve({
        name: 'legacy',
        params: { route },
      }).href;
      return `${link}${query ? `?${query}` : ''}`;
    },
    formatNumber,
  },
};
</script>

<style>

</style>
