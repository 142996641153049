import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { defaults } from '@/helpers/appDefaults';
import { kgToPound } from '@/helpers/convert';

export function objectifyByKey(arr, key) {
  if (!Array.isArray(arr)) throw new Error('not an array');
  if (!arr.length) return;

  const reducer = (acc, el) => {
    if (el[key]) {
      return { ...acc, [el[key]]: el };
    }
    return {};
  };

  /* eslint-disable-next-line */
  return arr.reduce(reducer, {});
}

/* eslint-disable no-bitwise */
export function hashCode(str) {
  let h = 0xdeadbeef;
  for (let i = 0; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 2654435761);
  }
  return (h ^ (h >>> 16)) >>> 0; // eslint-disable-line
}
/* eslint-enable no-bitwise */

export function flattenHierarchy(hierarchy, childrenKey = 'children') {
  const result = [];
  result.push(hierarchy);
  hierarchy[childrenKey].forEach((childNode) => {
    const flattenedChild = flattenHierarchy(childNode);
    flattenedChild.forEach((item) => result.push(item));
  });
  return result;
}

export function verifyVkn(vkn) {
  if (typeof vkn !== 'string') throw new TypeError('vkn should be a string');
  if (vkn.length !== 10) throw new TypeError('invalid length');

  const digits = vkn.split('');
  const control = digits.pop(); // eslint-disable-line

  const r = [];

  digits.forEach((el, i) => {
    const x = Number(el) + 10 - (i + 1);
    const mod = x % 10;
    if (mod === 9) {
      r.push(mod);
    } else {
      const pow = 2 ** (10 - (i + 1));
      r.push((mod * pow) % 9);
    }
  });

  const total = r.reduce((acc, cur) => acc + cur);
  const c = (10 - ((total % 10) % 10)) % 10;

  return `${digits.join('')}${c}` === vkn;
}

export function guessCardType(cc) {
  const number = cc;
  let re = new RegExp('^4');
  if (number.match(re) != null) return 'visa';

  re = new RegExp('^(34|37)');
  if (number.match(re) != null) return 'amex';

  re = new RegExp('^5[1-5]');
  if (number.match(re) != null) return 'mastercard';

  re = new RegExp('^6011');
  if (number.match(re) != null) return 'discover';

  return 'other'; // default type
}

/* eslint-disable no-bitwise */
export function hexToRgb(hex) {
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b].join();
}
/* eslint-enable no-bitwise */

export function getRandomHexColor(times) {
  const colors = [];
  for (let i = 0; i < times; i++) {
    colors.push(`#${`000000${Math.random().toString(16).slice(2, 8).toUpperCase()}`.slice(-6)}`); // eslint-disable-line
  }
  return colors;
}

export function chunkArray(arr, chunkSize) {
  const r = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    r.push(arr.slice(i, i + chunkSize));
  }

  return r;
}

export function throttle(callback, wait, immediate = false) {
  let timeout = null;
  let initialCall = true;

  return () => {
    const callNow = immediate && initialCall;
    const next = (...args) => {
      callback.apply(this, ...args);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timeout) {
      timeout = setTimeout(next, wait);
    }
  };
}

export function generateRandomHtmlId(length = 8) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'; // Letters to choose from
  const digits = '0123456789'; // Digits to choose from
  let randomId = '';

  randomId += characters.charAt(Math.floor(Math.random() * characters.length)); // Start with a letter

  for (let i = 1; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * digits.length);
    randomId += digits.charAt(randomIndex);
  }

  return randomId;
}

export function createUniqueToken() {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0; // eslint-disable-line
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16); // eslint-disable-line
  });
  return uuid;
}

export function generateFastRandomString(length, alphanumericOnly = true) {
  const alphaChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const numChars = '0123456789';
  const characters = alphanumericOnly ? alphaChars + numChars : alphaChars;
  const charactersLength = characters.length;
  const result = new Array(length);

  for (let i = 0; i < length; i++) {
    result[i] = characters[(Math.random() * charactersLength) | 0]; // eslint-disable-line
  }

  return result.join('');
}

export function createFileFromBinary(data, type, fileName) {
  const fileAnchor = document.createElement('a');
  const url = new Blob([data], { type });

  document.body.appendChild(fileAnchor).style = 'display: none';
  fileAnchor.className = 'download-file-temp-link';
  fileAnchor.href = URL.createObjectURL(url);
  fileAnchor.download = fileName;
  fileAnchor.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(fileAnchor);
}

export function snakeToCamel(str) {
  return str.toLowerCase().replace(/([-_][a-z0-9])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
}

export function isMac() {
  return navigator.userAgent.indexOf('Mac') > -1;
}

export function capitalizeFirstLetter(string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export function getYesNo(value) {
  return value ? 'Evet' : 'Hayır';
}

export function toggleArrayItem(array, item) {
  const i = array.findIndex((el) => el === item);
  if (i < 0) {
    array.push(item);
  } else {
    array.splice(i, 1);
  }

  return array;
}

// because vue2 cant detect changes on sets properly
export function uniqueArray(array) {
  return [...new Set(array)];
}

export function removeFromArray(array, item) {
  const i = array.findIndex((el) => el === item);
  if (i < 0) return array;
  array.splice(i, 1);
  return array;
}

export function toLatLng(array) {
  return array.map((el) => ({ lat: el.latitude, lng: el.longitude }));
}

export function toLatitudeLongitude(array) {
  return array.map((el) => ({ latitude: el.lat, longitude: el.lng }));
}

// This is mostly for iframe issues
// Not needed elsewhere
export function getLink(lang, hash, isFlexigoPath = false) {
  const url = document.referrer ? new URL(document.referrer) : new URL(window.location.href);
  url.hash = hash;
  const isProduction = !!url.origin.includes('secure.flexigo') || !!url.origin.includes('portal.flexigo');

  if (url.searchParams.has('lang')) {
    url.searchParams.set('lang', lang);
  } else {
    url.searchParams.append('lang', lang);
  }
  const formattedUrl = isFlexigoPath
    ? url.href.replace('admin/flexigo', 'flexigo').replace('commute/flexigo', 'commute')
    : url.href;

  return isProduction ? formattedUrl.replace('/flexigo', '/commute') : formattedUrl;
}

export function formatNumber(number, locale = 'en-US', maximumFractionDigits = 2, emptySign = '-') {
  if (number === undefined || Number.isNaN(number)) return emptySign;
  // const l = locales[locale] || 'en-US';
  return new Intl.NumberFormat(locale, { maximumFractionDigits }).format(number);
}

export function formatNumberWithDecimal(
  number,
  locale = 'en-US',
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  emptySign = '-',
) {
  if (number === undefined || number === 'NaN') return emptySign;
  // const l = locales[locale] || 'en-US';
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(number);
}

export function getCurrencySymbol(currency, locale = navigator.language) {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export function formatCurrency(number, currency = 'USD', locale = 'en-US', minimumFractionDigits = 2, emptySign = '-') {
  if (number === undefined || number === 'NaN') return emptySign;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
  }).format(number);
}

export function formatPercent(
  number,
  locale = 'en-US',
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  emptySign = '-',
) {
  if (number === undefined || number === 'NaN') return emptySign;
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(number / 100);
}

export function formatDigit(
  number,
  locale = 'en-US',
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  emptySign = '-',
) {
  if (number === undefined || number === 'NaN') return emptySign;
  return number.toLocaleString(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

// this function checks only the browser language
// for 24h format check is24HourFormat function
export function isLocaleEnUs() {
  return navigator.language === 'en-US';
}

export function isLocaleTr() {
  return navigator.language === 'tr';
}

export function formatDateLocal(date) {
  return isLocaleEnUs() ? format(date, 'MMM d, yyyy') : format(date, 'd MMMM yyyy');
}

export function timeStampLocalFormatWithLocalize(time) {
  return isLocaleEnUs()
    ? format(new Date(time), defaults.textFirstMonthDate)
    : format(new Date(time), defaults.textMiddleMonthDate, { locale: tr });
}

export function getInitials(name) {
  if (!name) return '';
  const names = name.split(' ');
  const initials = names.map((n) => n[0].toUpperCase());
  return initials.join('');
}

export function displayFullNameWithSurnameInitial(fullName) {
  const nameParts = fullName.split(' ');

  if (nameParts.length < 2) {
    console.log('Invalid full name format.');
    return fullName;
  }

  const firstName = nameParts[0];
  const surnameInitial = nameParts[nameParts.length - 1][0].toUpperCase();

  return `${firstName} ${surnameInitial}.`;
}

export function getPercentage(value, total, decimals = 0) {
  if (total === 0) return 0;
  if (Number.isNaN(value) || Number.isNaN(total)) return 0;
  if (decimals > 0) return Number(((value / total) * 100).toFixed(decimals));
  return Math.round((value / total) * 100);
}

// WARNING: This function returns "0" for NaN values and undefined, use with caution or better DONT use it at all
export function decimalNumber(value, decimals = 2) {
  if (!value || Number.isNaN(value) || value === 'NaN') return 0;
  return Number(value.toFixed(decimals));
}

export async function* asyncPollingGenerator(fetchFunction, interval) {
  let isPaused = false;

  // Handle visibility change event
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      isPaused = true;
    } else {
      isPaused = false;
    }
  });

  while (true) {
    if (!isPaused) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const data = await fetchFunction();
        yield data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => setTimeout(resolve, interval));
  }
}

// This function parses the parameters from the hash of the URL
// and returns them as an object. Normally, the parameters are in searchParams
// but in the case of a vue router, they are in the hash.
// This function does NOT handle multiple parameters with the same name or arrays.
export function parseHashParams(url) {
  // Extracting the hash and then the query part from the hash
  const { hash } = new URL(url);
  const queryString = hash.split('?')[1];

  if (!queryString) {
    return {};
  }

  // Parsing the query string
  const params = new URLSearchParams(queryString);
  const paramsObject = {};

  // Creating an object from the parsed parameters
  params.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
}

/**
 * Filters an object based on a predicate function.
 *
 * @param {Object} obj - The object to filter.
 * @param {Function} predicate - A predicate function that takes an object value and returns a boolean.
 *                               If it returns true, the corresponding key-value pair is included in the output object.
 * @returns {Object} A new object with key-value pairs that match the predicate function.
 *
 */
export function filterObject(obj, predicate) {
  return Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => ({ ...res, [key]: obj[key] }), {});
}

// wrapper for filterObject to filter by simple value equality
export function filterObjectByValue(obj, value) {
  return filterObject(obj, (v) => v === value);
}

export function formatWeight(weightInKg, locale = 'en-US') {
  const isUSLocale = locale === 'en-US';
  let weight;
  let unit;

  if (isUSLocale) {
    // Convert to pounds
    const weightInPounds = kgToPound(weightInKg);
    if (weightInPounds < 2000) {
      weight = weightInPounds;
      unit = 'lb';
    } else {
      weight = weightInPounds / 2000;
      unit = 'tons';
    }
  } else {
    // Use kilograms or metric tons
    weight = weightInKg < 1000 ? weightInKg : weightInKg / 1000;
    unit = weightInKg < 1000 ? 'kg' : 'tons';
  }
  return `${weight.toFixed(2)} ${unit}`;
}

export function isElementOverflowPageContent(element) {
  const documentWidth = document.documentElement.offsetWidth;
  const box = element.getBoundingClientRect();
  return box.left < 0 || box.right > documentWidth;
}

export function getBaseUrl() {
  if (window.location.origin.includes('localhost')) return 'https://secure3.vektorteknoloji.com/commute';
  if (window.location.origin.includes('portal')) {
    return `${window.location.origin}${window.location.pathname.replace('/admin', '').replace('/flexigo/', 'commute')}`;
  }
  return `${window.location.origin}/commute`;
}

export function isEqual(a, b) {
  if (a === b) {
    return true;
  }

  if (a == null || b == null || typeof a !== 'object' || typeof b !== 'object') {
    return false;
  }

  if (Array.isArray(a) !== Array.isArray(b)) {
    return false;
  }

  if (Array.isArray(a)) {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (!isEqual(a[i], b[i])) {
        return false;
      }
    }
  } else {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) {
      return false;
    }

    // eslint-disable-next-line
    for (const key of aKeys) {
      // eslint-disable-next-line
      if (!b.hasOwnProperty(key) || !isEqual(a[key], b[key])) {
        return false;
      }
    }
  }

  return true;
}

export function isEmpty(value) {
  if (value == null) return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (typeof value === 'object' && Object.keys(value).length === 0) return true;
  return false;
}

export function groupBy(xs, fnBy) {
  const valueProcessor = (x) => (typeof fnBy === 'function' ? fnBy(x) : x[fnBy]);
  return xs.reduce((rv, x) => {
    // eslint-disable-next-line
    (rv[valueProcessor(x)] = rv[valueProcessor(x)] || []).push(x);
    return rv;
  }, {});
}

export const clearSearchString = (searchString) => {
  const charMap = {
    Ç: 'C',
    Ö: 'O',
    Ş: 'S',
    İ: 'I',
    I: 'I',
    Ü: 'U',
    Ğ: 'G',
    ç: 'c',
    ö: 'o',
    ş: 's',
    ı: 'i',
    ü: 'u',
    ğ: 'g',
  };
  let clearedString = '';
  searchString.split('').forEach((item) => {
    clearedString += charMap[item] || item;
  });

  return clearedString.replace(/[^\w\s]/gi, '');
};

export const stripHtml = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export function cloneObject(obj) {
  return structuredClone(obj);
}

export default objectifyByKey;
