<template>
  <div>
    <div class="login-content-body-content mt-5">
      <form v-if="!showVtspAccounts" class="vstack gap-3">
        <email-input v-if="showEmail" v-model="email" />

        <div v-if="showPassword" class="d-flex flex-column">
          <div class="vstack gap-3">
            <div v-if="this.formState === 'PASSWORD'" class="fw-bold" style="font-size: 1rem">
              {{ email.value }}
              <a class="link-primary text-decoration-none fw-normal ms-2" href="#" @click="changeEmail">{{
                $t('flexigo.login.change')
              }}</a>
            </div>
            <password-input v-model="password" :name="'admin-password'" />
            <div v-if="loginErrorMsg" class="text-danger">
              {{ loginErrorMsg }}
            </div>
          </div>

          <button type="button" class="link-dark mt-2 align-self-end" @click="handleForgotPassword">
            {{ $t('flexigo.login.forgotMyPassword') }}
          </button>
          <p class="invalid-feedback">
            {{ $t('flexigo.login.password') }}
          </p>
        </div>

        <Recaptcha
          v-if="isCaptchaRequired"
          class="mt-3 align-self-center"
          @verified="onRecaptchaVerified"
          @expired="onRecaptchaExpired"
        />

        <div class="login-submit-content my-3">
          <large-button :type="'submit'" :disabled="!isValid" :is-loading="isLoading" @click="() => handleLogin()">
            {{ formState === 'EMAIL' ? $t('flexigo.login.continue') : $t('flexigo.login.login') }}
          </large-button>
        </div>

        <div v-if="!isAdmin" class="fs-5">
          {{ $t('flexigo.login.dontHaveAnAccount') }}
          <a class="fw-bold ms-2 link-black" @click="$router.push({ name: 'register' })">
            {{ $t('flexigo.login.signUp') }}
          </a>
        </div>
      </form>

      <select-account v-if="showVtspAccounts" :options="accountsList" @on-select="onSelectAccount" />
    </div>
  </div>
</template>

<script>
import LogRocket from 'logrocket';
import { isLocaleEnUs, isEmpty } from '@/helpers/utility';
import registration from '../../services/registration';
import toaster from '../../mixins/toaster';
import loginRedirect from './loginRedirect';
import emailInput from './emailInput.vue';
import passwordInput from './passwordInput.vue';
import selectAccount from './selectAccount.vue';
import largeButton from './largeButton.vue';
import Recaptcha from '../../../../common/components/Recaptcha.vue';

function setLogRocketUser(isAdmin, loginResponse) {
  const id = isAdmin ? loginResponse.accountId : loginResponse.personnel.id;
  const email = isAdmin ? loginResponse.uid : loginResponse.personnel.email;
  const name = isAdmin ? loginResponse.cn : loginResponse.personnel.fullName;

  LogRocket.identify(id, {
    id,
    email,
    name,
    userType: isAdmin ? 'ADMIN' : 'PERSONNEL',
  });
  LogRocket.log('User logged in');
}

export default {
  name: 'formLogin',
  components: { emailInput, passwordInput, selectAccount, largeButton, Recaptcha },
  mixins: [toaster, loginRedirect],
  props: {
    selectedUserType: {
      type: String,
      required: false,
    },
    existingEmail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      email: { value: null, isValid: false },
      password: null,
      checkValid: false,
      loginErrorMsg: '',
      showVtspAccounts: false,
      accountsList: [],
      isLoading: false,
      isCaptchaRequired: false,
      captchaId: null,
      formState: 'BOTH',
    };
  },
  computed: {
    isAdmin() {
      return this.selectedUserType === 'ADMIN';
    },
    isValid() {
      if (this.isLoading) {
        return false;
      }
      if (this.isCaptchaRequired && !this.captchaId) {
        return false;
      }
      if (this.formState === 'BOTH') {
        return this.email?.isValid && this.password?.length;
      }
      if (this.formState === 'EMAIL') {
        return this.email?.isValid;
      }
      return this.password?.length > 0;
    },
    showEmail() {
      return ['BOTH', 'EMAIL'].includes(this.formState);
    },
    showPassword() {
      return ['BOTH', 'PASSWORD'].includes(this.formState);
    },
    formValues() {
      return `${this.email.value}${this.password}`;
    },
  },
  watch: {
    existingEmail: {
      handler(newVal) {
        this.getRedirectedEmail(newVal);
      },
      deep: true,
    },
    selectedUserType() {
      this.adjustFormState();
    },
    formValues() {
      if (this.loginErrorMsg && this.password?.length) {
        this.loginErrorMsg = '';
      }
    },
  },
  mounted() {
    this.adjustFormState();
    this.getRedirectedEmail(this.existingEmail);
  },
  methods: {
    handleForgotPassword() {
      this.$emit('open-screen', { screen: 'FORGOT_PASSWORD', email: this.email });
    },
    handleLogin() {
      this.checkValid = true;

      if (this.formState === 'EMAIL') {
        this.verifyEmail();
        return;
      }
      this.login();
    },
    continueWithAccountSelect(loginResponse) {
      this.showVtspAccounts = true;
      this.accountsList = loginResponse.vtspAccounts;
    },
    continueWithPassword() {
      this.formState = 'PASSWORD';
    },
    continueWithSso(loginResponse) {
      if (loginResponse.ssoUrl) {
        window.location.assign(loginResponse.ssoUrl);
      }
    },
    async verifyEmail() {
      try {
        const payload = { email: this.email?.value, langCode: isLocaleEnUs() ? 'en' : 'tr' };
        const loginResponse = await registration.checkAndLoginPersonnel(payload);
        if (loginResponse.status === 'CONTINUE_WITH_PASSWORD') {
          this.continueWithPassword();
        } else {
          this.continueWithSso(loginResponse);
        }
      } catch (error) {
        this.getToaster('error', error?.response?.data?.error?.message);
      }
    },

    async login(telematicAccount = null) {
      this.checkValid = true;
      this.isLoading = true;
      try {
        const payload = {
          email: this.email.value,
          password: this.password,
          captchaId: this.captchaId || null,
          sicilNo: '',
        };
        if (telematicAccount) {
          payload.telematicAccount = telematicAccount;
        }
        const loginResponse = await registration[this.isAdmin ? 'login' : 'loginPersonnel'](payload);

        if (!isEmpty(loginResponse.vtspAccounts) && !telematicAccount) {
          this.continueWithAccountSelect(loginResponse);
        } else {
          this.getToaster('success', this.$t('flexigo.login.redirecting'), this.$t('flexigo.login.loginSuccessful'));

          setLogRocketUser(this.isAdmin, loginResponse);
          await this.$store.dispatch('SET_USER_DETAILS');
          this.loginRedirect(loginResponse);
        }
        this.loginErrorMsg = '';
      } catch (error) {
        this.password = '';
        this.isCaptchaRequired = error?.response?.data?.error?.requiresCaptcha;
        if (error?.response?.data?.error?.errorId === 192) {
          this.loginErrorMsg = this.$t('flexigo.login.wrongUser.message');
        } else {
          this.getToaster('error', error?.response?.data?.error?.message);
        }
      } finally {
        this.isLoading = false;
      }
    },
    onSelectAccount(selectedAccount) {
      this.login(selectedAccount);
    },
    onRecaptchaVerified(token) {
      this.captchaId = encodeURIComponent(token);
    },
    onRecaptchaExpired() {
      this.captchaId = null;
    },
    adjustFormState() {
      if (this.isAdmin) {
        this.formState = 'BOTH';
      } else {
        this.formState = 'EMAIL';
      }
    },
    changeEmail() {
      this.formState = 'EMAIL';
      this.resetForm();
    },
    getRedirectedEmail(email) {
      if (!email || isEmpty(email)) {
        return;
      }
      this.email = { ...email };
      if (!this.isAdmin) {
        this.formState = 'PASSWORD';
      }
    },
    resetForm() {
      this.email = { value: null, isValid: false };
      this.password = null;
    },
  },
};
</script>
