<template>
  <div>
    <h6 class="mt-4 mb-3 grid-header-font-size title d-block">
      {{ this.$t('flexigo.dashboard.fixedRoutes') }}
    </h6>
    <div>
      <div class="row mb-3">
        <div class="col px-2">
          <card
            :top-icon="`${baseUrl}icons/dashboard/users.svg`"
            :top-value="`${formatDigit(value.uniqueRiders, locale)} / ${formatDigit(value.rides, locale)}`"
            :top-metric="this.$t('flexigo.dashboard.uniqueRidersRides')"
            :isEnableTopMetricDirection="true"
            :bottom-icon="`${baseUrl}icons/dashboard/routes.svg`"
            :bottom-value="`${formatDigit(value.trips, locale)} / ${formatDigit(value.routes, locale)}`"
            :bottom-metric="this.$t('flexigo.dashboard.tripsRoutes')"
            :isEnableBottomMetricDirection="true"
            @top-metric-direction="direct({ page: 'Reservations' })"
            @bottom-metric-direction="direct({ page: 'RouteStatuses' })"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${baseUrl}icons/dashboard/bus.svg`"
            :top-value="formatPercent(value.avgOccupancy, locale)"
            :top-metric="this.$t('flexigo.dashboard.avgOccupancy')"
            :sub-text-metric="this.$t('flexigo.dashboard.below', [formatPercent(value.occupancyThreshold, locale)])"
            :sub-text-value="value.occupancyBelowTreshold"
            :isEnableSubTextMetricDirection="true"
            @subtext-metric-direction="direct({ page: 'RouteStatuses' })"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${baseUrl}icons/dashboard/time.svg`"
            :top-value="formatDigit(value.avgTimeCoefficient, locale)"
            :top-metric="this.$t('flexigo.dashboard.avgTimeCoefficient')"
            :sub-text-metric="
              this.$t('flexigo.dashboard.over', [formatPercent(value.timeCoefficientThreshold, locale)])
            "
            :sub-text-value="value.timeCoefficientAboveThreshold"
            :isEnableTopMetricDirection="true"
            :isEnableSubTextMetricDirection="value.timeCoefficientAboveThreshold > 0"
            @top-metric-direction="direct({ page: 'Reservations', overTimeCoefficient: 1.5 })"
            @subtext-metric-direction="direct({ page: 'Reservations', overTimeCoefficient: 1.5 })"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${baseUrl}icons/dashboard/walk.svg`"
            :top-value="`${value.avgWalkingDistanceInMeter} ft`"
            :top-metric="this.$t('flexigo.dashboard.avgDistanceToStop')"
            :sub-text-metric="this.$t('flexigo.dashboard.above', [value.walkingDistanceThreshold])"
            :sub-text-value="value.walkingDistanceAboveThreshold"
            :isEnableTopMetricDirection="true"
            :isEnableSubTextMetricDirection="true"
            @subtext-metric-direction="direct({ page: 'Reservations', walkingDistanceInMeter: 500 })"
            @top-metric-direction="direct({ page: 'Reservations' })"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${baseUrl}icons/dashboard/paper.svg`"
            :top-value="`${formatCurrency(value.totalCost, isLocaleTr() ? 'TRY' : 'USD', locale)}`"
            :top-metric="this.$t('flexigo.dashboard.totalCost')"
            :bottom-value="`${
              value.costPerEmployee !== 'Infinity'
                ? formatCurrency(value.costPerEmployee, isLocaleTr() ? 'TRY' : 'USD', locale)
                : value.costPerEmployee
            }`"
            :bottom-metric="this.$t('flexigo.dashboard.perEmployeeTrip')"
          />
        </div>
        <div class="col px-2">
          <card
            :top-icon="`${baseUrl}icons/dashboard/bubble.svg`"
            :top-value="`${emisionValue}`"
            :top-metric="this.$t('flexigo.dashboard.totalEmmisions')"
            :bottom-value="`${emissionPerEmployee}`"
            :bottom-metric="this.$t('flexigo.dashboard.perEmployeeMile')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLink,
  formatCurrency,
  formatNumberWithDecimal,
  formatPercent,
  isLocaleTr,
  formatDigit,
  formatWeight,
} from '@/helpers/utility';
import Card from './card.vue';

export default {
  name: 'DynamicRoutesCard',
  components: { Card },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    locale() {
      return navigator.language;
    },
    emisionValue() {
      return formatWeight(this.value.totalEmission, navigator.language);
    },
    emissionPerEmployee() {
      return formatWeight(this.value.emissionPerEmployee, navigator.language);
    },
  },
  methods: {
    getLink,
    formatCurrency,
    formatNumberWithDecimal,
    formatPercent,
    isLocaleTr,
    formatDigit,
    direct(options) {
      const queries = {
        ...this.$route.query,
        ...options,
        ...{ workgroupType: 'SHUTTLE', workingTypes: 'DYNAMIC' },
      };
      const routeData = this.$router.resolve({
        name: options.page,
        params: { type: 'SHUTTLE' },
        query: queries,
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
