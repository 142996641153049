<template>
  <form name="lostPassword" class="form-validation mt-5">
    <h5 class="fw-bold mb-3">{{ $t('flexigo.login.forgotPassword') }}</h5>
    <div class="form-group pb-3">
      <email-input v-model="email" />
      <p class="invalid-feedback">
        {{ $t('flexigo.login.validEmail') }}
      </p>
    </div>

    <div class="login-submit-content my-5 vstack gap-4">
      <large-button type="submit" :disabled="!email?.isValid" @click="handleForgotPassword">
        {{ $t('flexigo.login.requestResetLink') }}
      </large-button>
      <button type="button" class="btn btn-light p-3" @click.prevent="backToLogin">
        {{ $t('flexigo.common.cancel') }}
      </button>
    </div>
  </form>
</template>

<script>
import registration from '../../services/registration';
import toaster from '../../mixins/toaster';
import largeButton from './largeButton.vue';
import emailInput from './emailInput.vue';

export default {
  name: 'forgotPassword',
  components: { largeButton, emailInput },
  mixins: [toaster],
  props: {
    selectedUserType: {
      type: String,
      required: false,
    },
    existingEmail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      email: { value: null, isValid: false },
    };
  },
  computed: {
    isAdmin() {
      return this.selectedUserType === 'ADMIN';
    },
  },
  mounted() {
    this.email = { ...this.existingEmail };
  },
  methods: {
    backToLogin() {
      this.$emit('open-screen', { screen: this.isAdmin ? 'LOGIN_WITH_PASSWORD' : 'LOGIN', email: this.email });
    },
    async handleForgotPassword() {
      if (!this.email) return;
      try {
        await registration[this.isAdmin ? 'forgotPassword' : 'forgotPasswordPersonnel']({
          email: this.email.value,
        });
        this.getToaster('success', this.$t('flexigo.login.newPasswordSent'));
        this.backToLogin();
      } catch (error) {
        this.getToaster('error', this.$t('flexigo.login.emailNotFound'), error?.response?.data?.error?.message);
      }
    },
  },
};
</script>
