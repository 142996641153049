<template>
  <div class="btn-group search-workgroup">
    <button
      type="button"
      class="btn dropdown-toggle"
      data-bs-toggle="dropdown"
      data-bs-display="static"
      aria-expanded="false">
      {{selectedValue}}
    </button>
    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end p-2" style="width: 378px;"> <!-- width değeri görüntü için geçici olarak sabit verildi -->
      <div class="input-group mb-3">
        <input v-model="filterText" type="search" class="form-control" :placeholder="$t('flexigo.dashboard.searchCampus')" aria-describedby="basic-addon1">
      </div>
      <a class="dropdown-item" href="#" @click.prevent="select(undefined)">{{$t('flexigo.dashboard.allCampuses')}}</a>
      <a v-for="el in filteredData" :key="el.id" class="dropdown-item" href="#" @click.prevent="select(el)">{{el.title}}</a>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CampusSearch',
  props: {
    workgroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterText: '',
      currentworkGroup: undefined,
    };
  },
  computed: {
    filteredData() {
      return this.campuses.filter((el) => el.title.toLowerCase().match(this.filterText.toLowerCase()));
    },
    selectedValue() {
      return (this.currentworkGroup ? this.currentworkGroup.title : this.$t('flexigo.dashboard.allCampuses'));
    },
    campuses() {
      return this.$store.getters.campuses();
    },
  },
  methods: {
    select(val) {
      this.currentworkGroup = val;
      this.$emit('select', val);
    },
  },
};
</script>

<style>

</style>
