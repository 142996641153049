<template>
  <div class="card text-dark mb-3" style="height: 100%">
    <div class="card-header dashboard">
      {{$t('flexigo.dashboard.systemStatus')}}
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="row">
            <h6  class="title-style float-start">{{$t('flexigo.common.routes')}}</h6>
            <div class="col end-0" style="margin-top: -36px">
              <date v-model="formattedDate" class="float-end" />
            </div>
            <div class="row ms-0">
              <toggle-button v-model="toggleButtonValue"  :first-label="$t('flexigo.dashboard.toCampus')" :second-label="$t('flexigo.dashboard.fromCampus')" />
            </div>
          </div>

          <div v-if="toggleButtonValue">
            <div class="row">
              <div class="col">
                <user-info
                  :subtitle="this.$t('flexigo.dashboard.onTimeArrival')"
                  :amount="onTimeArrivalToCampus"
                  :link="getLink('app.route.instance.*', 'status=ARRIVED_ON_TIME')" />
              </div>
              <div class="col">
                <user-info
                  :subtitle="this.$t('flexigo.dashboard.noArrival')"
                  :amount="noArrivalToCampus"
                  :link="getLink('app.route.instance.*', 'status=NOT_ARRIVED')" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <user-info
                  :subtitle="this.$t('flexigo.dashboard.lateArrival')"
                  :amount="lateArrivalToCampus"
                  :link="getLink('app.route.instance.*', 'status=ARRIVED_LATE')" />
              </div>
              <div class="col">
                <user-info
                  :subtitle="this.$t('flexigo.dashboard.deviceProblem')"
                  :amount="deviceProblemToCampus"
                  :link="getLink('app.route.instance.*', 'status=DEVICE_NOT_OK')" />
              </div>
            </div>
          </div>

          <div v-if="!toggleButtonValue">
            <div class="row">
              <div class="col">
                <user-info
                  :subtitle="this.$t('flexigo.dashboard.leaveOnTime')"
                  :amount="leaveOnTimeFromCampus"
                  :link="getLink('app.route.instance.*', 'status=LEAVE_ON_TIME')" />
              </div>
              <div class="col">
                <user-info
                  :subtitle="this.$t('flexigo.dashboard.readyToLeave')"
                  :amount="readyToLeaveFromCampus"
                  :link="getLink('app.route.instance.*', 'status=READY_TO_LEAVE')" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <user-info
                  :subtitle="this.$t('flexigo.dashboard.leaveEarly')"
                  :amount="leaveEarlyToCampus"
                  :link="getLink('app.route.instance.*', 'status=LEAVE_EARLY')" />
              </div>
              <div class="col">
                <user-info
                  :subtitle="this.$t('flexigo.dashboard.deviceProblem')"
                  :amount="deviceProblemFromCampus"
                  :link="getLink('app.route.instance.*', 'status=DEVICE_NOT_OK')" />
              </div>
            </div>
          </div>
        </div>

        <div class="col d-flex flex-column flex-wrap">
          <div class="row" style="flex: 1">
            <h6 class="title-style float-start">{{$t('flexigo.dashboard.userRegistration')}}</h6>
            <div class="col">
              <user-info
                :subtitle="this.$t('flexigo.dashboard.undefinedRoute')"
                :amount="undefinedRoute"
                :color="'#f5b548'"
                :is-height-full="true"/>
            </div>
            <div class="col">
              <user-info
                :subtitle="this.$t('flexigo.dashboard.addressMissing')"
                :amount="addressMissing"
                :color="'#f5b548'"
                :is-height-full="true"/>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <user-info
                :subtitle="this.$t('flexigo.dashboard.notUsingShuttle')"
                :amount="notUsingShuttle"
                :is-height-full="true"/>
            </div>
            <div class="col">
              <user-info
                :subtitle="this.$t('flexigo.dashboard.completed')"
                :amount="completed"
                :is-height-full="true"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfo from '../userInfo/userInfo.vue';
import ToggleButton from '../toggleButton/toggleButton.vue';
import Date from '../date.vue';

export default {
  name: 'SystemStatus',
  components: { UserInfo, ToggleButton, Date },
  props: {
    userRegistration: {
      type: [Array, Boolean],
      default: false,
    },
    routes: {
      type: [Array, Boolean],
      default: false,
    },
  },
  data() {
    return {
      formattedDate: null,
      toggleButtonValue: true,
      tabName: 'toCampus',
    };
  },
  computed: {
    // USER REGISTRATION
    undefinedRoute() {
      return this.userRegistration && this.userRegistration.find((item) => (item.state === 'NO_ROUTE')) ? this.userRegistration.find((item) => (item.state === 'NO_ROUTE')).count : 0;
    },
    addressMissing() {
      return this.userRegistration && this.userRegistration.find((item) => (item.state === 'NO_HOME')) ? this.userRegistration.find((item) => (item.state === 'NO_HOME')).count : 0;
    },
    notUsingShuttle() {
      return this.userRegistration && this.userRegistration.find((item) => (item.state === 'NOT_USING_ROUTE')) ? this.userRegistration.find((item) => (item.state === 'NOT_USING_ROUTE')).count : 0;
    },
    completed() {
      return this.userRegistration && this.userRegistration.find((item) => (item.state === 'DEFINITION_OK')) ? this.userRegistration.find((item) => (item.state === 'DEFINITION_OK')).count : 0;
    },

    // ROUTES
    onTimeArrivalToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'ARRIVED_ON_TIME' && item.routeDirection === 0) ? this.routes.find((item) => item.state === 'ARRIVED_ON_TIME' && item.routeDirection === 0).count : 0;
    },
    leaveOnTimeFromCampus() {
      return this.routes && this.routes.find((item) => item.state === 'LEAVE_ON_TIME' && item.routeDirection === 1) ? this.routes.find((item) => item.state === 'LEAVE_ON_TIME' && item.routeDirection === 1).count : 0;
    },
    noArrivalToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'NOT_ARRIVED' && item.routeDirection === 0) ? this.routes.find((item) => item.state === 'NOT_ARRIVED' && item.routeDirection === 0).count : 0;
    },
    readyToLeaveFromCampus() {
      return this.routes && this.routes.find((item) => item.state === 'READY_TO_LEAVE' && item.routeDirection === 1) ? this.routes.find((item) => item.state === 'READY_TO_LEAVE' && item.routeDirection === 1).count : 0;
    },
    lateArrivalToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'ARRIVED_LATE' && item.routeDirection === 0) ? this.routes.find((item) => item.state === 'ARRIVED_LATE' && item.routeDirection === 0).count : 0;
    },
    leaveEarlyToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'LEAVE_EARLY' && item.routeDirection === 1) ? this.routes.find((item) => item.state === 'LEAVE_EARLY' && item.routeDirection === 1).count : 0;
    },
    deviceProblemToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'DEVICE_NOT_OK' && item.routeDirection === 0) ? this.routes.find((item) => item.state === 'DEVICE_NOT_OK' && item.routeDirection === 0).count : 0;
    },
    deviceProblemFromCampus() {
      return this.routes && this.routes.find((item) => item.state === 'DEVICE_NOT_OK' && item.routeDirection === 1) ? this.routes.find((item) => item.state === 'DEVICE_NOT_OK' && item.routeDirection === 1).count : 0;
    },
  },
  watch: {
    formattedDate() {
      this.$emit('getFormattedDate', this.formattedDate);
    },
  },
  methods: {
    getLink(route, query = '') {
      const link = this.$router.resolve({
        name: 'legacy',
        params: { route },
      }).href;
      return `${link}${query ? `?${query}` : ''}`;
    },
  },
};
</script>

<style>

</style>
