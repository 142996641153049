import { companies, employeeList } from '@/endpoint.flexigo';
import { umng } from '@/endpoints';
import Repository from './repository';

const client = new Repository();

export default {
  async getCompanies() {
    const r = await client.get(companies.companies);
    return r;
  },
  async saveCompanies(payload) {
    const r = await client.post(companies.companies, payload);
    return r;
  },
  async getSubCompanies(companyId) {
    let url = companies.subCompanies;
    if (companyId) {
      url = `${companies.subCompanies}?companyId=${companyId}`;
    }
    const r = await client.get(url);
    return r;
  },
  async saveSubCompanies(payload, companyId) {
    let url = companies.subCompanies;
    if (companyId) {
      url = `${companies.subCompanies}?companyId=${companyId}`;
    }
    const r = await client.post(url, payload);
    return r;
  },
  async getCompanyBasicInfo(companyId) {
    let url = companies.companyBasicInfo;
    if (companyId) url = `${companies.companyBasicInfo}?companyId=${companyId}`;
    const r = await client.get(url);
    return r;
  },
  async updateCompanyBasicInfo(payload) {
    const r = await client.post(companies.companyBasicInfo, payload);
    return r;
  },
  async getSubCompanyBasicInfo(subcompanyId, companyId) {
    const url = `${companies.subCompanyBasicInfo}?subcompanyId=${subcompanyId}&companyId=${companyId}`;
    const r = await client.get(url);
    return r;
  },
  async getServiceProviders(companyType, companyId) {
    let url = companies.serviceProviders;
    if (companyType === 'FLEXIGO_VEKTOR_ADMIN') {
      url = `${companies.serviceProviders}?companyId=${companyId}`;
    }
    const r = await client.get(url);
    return r;
  },
  async saveServiceProviders(payload) {
    const r = await client.post(companies.serviceProviders, payload);
    return r;
  },
  async updateServiceProviders(payload) {
    const r = await client.put(companies.serviceProviders, payload);
    return r;
  },
  async deleteServiceProviders(id, companyId) {
    const r = await client.delete(`${companies.serviceProviders}/${id}?companyId=${companyId}`);
    return r;
  },
  async getCompanyDepartments(companyType, companyId, subcompanyId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        url = `${companies.companyDepartments}?companyId=${companyId}&subcompanyId=${subcompanyId}`;
        break;
      case 'ADMIN':
        if (companyId) {
          url = `${companies.companyDepartments}?subcompanyId=${companyId}`;
        } else {
          url = `${companies.companyDepartments}`;
        }
        break;
      default:
        url = `${companies.companyDepartments}`;
        break;
    }
    const r = await client.get(url);
    return r;
  },

  async deleteDepartments(companyType, companyId, departmentId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        url = `${companies.companyDepartments}/${departmentId}/?companyId=${companyId}`;
        break;
      case 'ADMIN':
        url = `${companies.companyDepartments}/${departmentId}/?subcompanyId=${companyId}`;
        break;
      default:
        url = `${companies.companyDepartments}/${departmentId}`;
        break;
    }
    const r = await client.delete(url);
    return r;
  },

  async createDepartments(payload, companyType, companyId, subcompanyId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        if (subcompanyId) {
          url = `${companies.companyDepartments}?companyId=${companyId}&subcompanyId=${subcompanyId}`;
        } else {
          url = `${companies.companyDepartments}?companyId=${companyId}`;
        }
        break;
      case 'ADMIN':
        url = `${companies.companyDepartments}?subcompanyId=${companyId}`;
        break;
      default:
        url = `${companies.companyDepartments}`;
        break;
    }
    const r = await client.post(url, payload);
    return r;
  },
  async updateDepartments(payload, companyType, companyId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        url = `${companies.companyDepartments}?companyId=${companyId}`;
        break;
      case 'ADMIN':
        url = `${companies.companyDepartments}?subcompanyId=${companyId}`;
        break;
      default:
        url = `${companies.companyDepartments}`;
        break;
    }
    const r = await client.put(url, payload);
    return r;
  },
  async getAdmins(companyType, companyId, subcompanyId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        if (subcompanyId) {
          url = `${companies.admins}?companyId=${companyId}&subcompanyId=${subcompanyId}`;
        } else {
          url = `${companies.admins}?companyId=${companyId}`;
        }
        break;
      case 'ADMIN':
        if (companyId) {
          url = `${companies.admins}?subcompanyId=${companyId}`;
        } else {
          url = `${companies.admins}`;
        }
        break;
      default:
        url = `${companies.admins}`;
        break;
    }
    const r = await client.get(url);
    return r;
  },
  async saveAdmin(payload, companyType, companyId, subcompanyId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        if (subcompanyId) {
          url = `${companies.admins}?companyId=${companyId}&subcompanyId=${subcompanyId}`;
        } else {
          url = `${companies.admins}?companyId=${companyId}`;
        }
        break;
      case 'ADMIN':
        url = `${companies.admins}?subcompanyId=${companyId}`;
        break;
      default:
        url = `${companies.admins}`;
        break;
    }
    const r = await client.post(url, payload);
    return r;
  },
  async updateAdmin(payload, companyType, companyId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        url = `${companies.admins}?companyId=${companyId}`;
        break;
      case 'ADMIN':
        url = `${companies.admins}?subcompanyId=${companyId}`;
        break;
      default:
        url = `${companies.admins}`;
        break;
    }
    const r = await client.put(url, payload);
    return r;
  },
  async deleteAdmin(companyType, companyId, adminId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        url = `${companies.admins}/${adminId}/?companyId=${companyId}`;
        break;
      case 'ADMIN':
        url = `${companies.admins}/${adminId}/?subcompanyId=${companyId}`;
        break;
      default:
        url = `${companies.admins}/${adminId}`;
        break;
    }
    const r = await client.delete(url);
    return r;
  },
  async getCampuses(companyType, companyId, subcompanyId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        if (subcompanyId) {
          url = `${companies.campuses}?companyId=${companyId}&subcompanyId=${subcompanyId}`;
        } else {
          url = `${companies.campuses}?companyId=${companyId}`;
        }
        break;
      case 'ADMIN':
        if (companyId) {
          url = `${companies.campuses}?subcompanyId=${companyId}`;
        } else {
          url = `${companies.campuses}`;
        }
        break;
      default:
        url = `${employeeList.campusesOfEmployee}`;
        break;
    }
    const r = await client.get(url);
    return r;
  },
  async deleteCampuses(id, companyId) {
    const url = `${companies.campuses}/${id}?companyId=${companyId}`;
    const r = await client.delete(url);
    return r;
  },
  async updateCampuses(payload) {
    const r = await client.put(companies.campuses, payload);
    return r;
  },
  async createCampuses(payload) {
    const r = await client.post(companies.campuses, payload);
    return r;
  },
  async getRegions(companyType, companyId, subcompanyId) {
    let url = '';
    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        if (subcompanyId) {
          url = `${companies.regions}?companyId=${companyId}&subcompanyId=${subcompanyId}`;
        } else {
          url = `${companies.regions}?companyId=${companyId}`;
        }
        break;
      case 'ADMIN':
        if (companyId) {
          url = `${companies.regions}?subcompanyId=${companyId}`;
        } else {
          url = `${companies.regions}`;
        }
        break;
      default:
        url = `${companies.regions}`;
        break;
    }
    const r = await client.get(url);
    return r;
  },
  async deleteRegions(id, companyId) {
    const url = `${companies.regions}/${id}?companyId=${companyId}`;
    const r = await client.delete(url);
    return r;
  },
  async updateRegions(payload) {
    const r = await client.put(companies.regions, payload);
    return r;
  },
  async createRegions(payload) {
    const r = await client.post(companies.regions, payload);
    return r;
  },
  async getIndustries() {
    const r = await client.get(companies.industries);
    return r;
  },
  async getSettings(companyType, tabName, companyId, subcompanyId) {
    let url = '';

    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        if (subcompanyId) {
          url = `${companies.settings}/?companyId=${companyId}&subcompanyId=${subcompanyId}`;
        } else {
          url = `${companies.settings}/?companyId=${companyId}`;
        }
        break;
      case 'ADMIN':
        url = `${companies.settings}?subcompanyId=${companyId}`;
        break;
      default:
        url = `${companies.settings}`;
        break;
    }
    url = url.replace(':tabName', tabName);
    const r = await client.get(url);
    return r;
  },
  async updateSettings(companyType, tabName, payload, companyId, subcompanyId) {
    let url = '';

    switch (companyType) {
      case 'FLEXIGO_VEKTOR_ADMIN':
        if (subcompanyId) {
          url = `${companies.settings}/?companyId=${companyId}&subcompanyId=${subcompanyId}`;
        } else {
          url = `${companies.settings}/?companyId=${companyId}`;
        }
        break;
      case 'ADMIN':
        url = `${companies.settings}?subcompanyId=${companyId}`;
        break;
      default:
        url = `${companies.settings}`;
        break;
    }
    url = url.replace(':tabName', tabName);
    const r = await client.put(url, payload);
    return r;
  },
  async getFeedback() {
    const r = await client.get(companies.feedback);
    return r;
  },
  async updateFeedback(payload) {
    const r = await client.put(companies.feedback, payload);
    return r;
  },
  async getMenuNodesById(accountId, params) {
    const url = umng.menuNodesById.replace(':id', accountId);
    const r = await client.get(url, params);
    return r;
  },
};
