import { registration } from '@/endpoint.flexigo';
import Repository from './repository';

const client = new Repository();

export default {
  async getIndustryList() {
    const r = await client.get(registration.industryList);
    return r;
  },
  async getCompanySizeList() {
    const r = await client.get(registration.companySizeList);
    return r;
  },
  async register(payload) {
    const r = await client.post(registration.register, payload);
    return r;
  },
  async login(payload) {
    const r = await client.post(registration.login, payload);
    return r;
  },
  async loginPersonnel(payload) {
    const r = await client.post(registration.loginPersonnel, payload);
    return r;
  },
  async forgotPassword(payload) {
    const r = await client.post(registration.forgotPassword, payload);
    return r;
  },
  async forgotPasswordPersonnel(payload) {
    const r = await client.post(registration.forgotPasswordPersonnel, payload);
    return r;
  },
  async verify() {
    const r = await client.get(registration.verify);
    return r;
  },
  async checkAndLoginPersonnel(payload) {
    const r = await client.post(registration.checkAndLoginPersonnel, payload);
    return r;
  },
  async signupPersonnel(payload) {
    const r = await client.post(registration.signupPersonnel, payload);
    return r;
  },
  async checkDomainPersonnel(payload, langcode) {
    const r = await client.post(registration.checkDomainPersonnel.replace(':lcode', langcode), payload);
    return r;
  },
  async verifyCompanyCode(payload, langcode) {
    const r = await client.post(registration.verifyCompanyCode.replace(':lcode', langcode), payload);
    return r;
  },
  async verifyEmail(payload, langcode) {
    const r = await client.post(registration.verifyEmail.replace(':lcode', langcode), payload);
    return r;
  },
};
