import { format, differenceInMinutes, parse, subMinutes, addMinutes } from 'date-fns';
import { tr } from 'date-fns/locale';
import { defaults } from '@/helpers/appDefaults';
import { convertTo12Hour, kmToMiles } from '@/helpers/convert';
import { isLocaleEnUs, decimalNumber } from './utility';

// convert time values in to string values
// 1230 => { hours: '12', minutes: '30' }
export function intToTime(val) {
  const strValue = String(val);
  const hours = strValue.slice(-4, -2) || '0';
  const minutes = strValue.slice(-2);
  return { hours, minutes: minutes.length < 2 ? `0${minutes}` : minutes };
}
// createUTCDateFromInput('2021-01-01')
export function intToTimeStr(value, is12Hour = false) {
  if (value === undefined || value === null) return undefined;
  const { hours, minutes } = intToTime(String(value));
  const h = hours.length === 1 ? `0${hours}` : hours;
  const m = minutes.length === 1 ? `0${hours}` : minutes;
  return is12Hour ? convertTo12Hour(`${h}:${m}`) : `${h}:${m}`;
}

export function createUTCDateFromInput(dateString) {
  return new Date(`${dateString}T00:00:00Z`);
}

export function createFixedDateUTC(dateString) {
  return new Date(`${dateString}T00:00:00`);
}

// 1741618462 => 03-10-2025 17:54:54 PM
export function getDateAndTime(miliSeconds, formatType = null, time = true) {
  if (!miliSeconds) return '';
  if (!miliSeconds.toString().includes(':')) {
    let defaultFormatType;
    const date = new Date(miliSeconds);
    if (time) {
      defaultFormatType = isLocaleEnUs ? 'MM-dd-yyyy aa hh:mm:ss' : 'dd-MM-yyyy HH:mm:ss';
    } else {
      defaultFormatType = isLocaleEnUs ? 'MM-dd-yyyy' : 'dd-MM-yyyy';
    }

    return format(date, formatType || defaultFormatType);
  }
  return miliSeconds;
}

/**
 * Generates an array of time strings in HH:MM format at specified intervals.
 *
 * @param {number} interval - The interval in minutes between each time string. Default is 15 minutes.
 * @returns {string[]} An array of time strings in HH:MM format.
 */
export function timeStrings(interval = 15) {
  const hours = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += interval) {
      hours.push(`${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`);
    }
  }
  return hours;
}

// convert date values in to string values
// 20190101 => string or Date
export function intToDate(intDateValue, stringVersion = false, seperateMarker = '-') {
  const strVal = String(intDateValue).replaceAll('-', '');
  const year = strVal.slice(0, 4);
  const month = strVal.slice(4, 6);
  const day = strVal.slice(-2);

  return stringVersion ? `${year}${seperateMarker}${month}${seperateMarker}${day}` : new Date(year, month - 1, day);
}

export function timeStrToInt(timeStr) {
  if (timeStr === undefined || timeStr === null) return undefined;
  const [hours, minutes] = timeStr.split(':');
  return Number(`${hours}${minutes}`);
}
export function dateStrToInt(dateStr) {
  if (dateStr === undefined || dateStr === null) return undefined;
  if (![...`${dateStr}`].includes('-')) return dateStr;

  return parseInt(`${dateStr}`.replace(/-/g, ''), 10);
}

export function localDateTimeFormatter(timeZone, locale = 'en-US', showTime = true, hour12 = true) {
  const options = {
    day: 'numeric',
    month: 'long',
    timeZone,
  };

  if (showTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.hour12 = hour12;
  }

  return new Intl.DateTimeFormat(locale, options);
}

export function localDateFormatter(dateString, timezone = null) {
  const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timezone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  return new Intl.DateTimeFormat(navigator.language === 'en-US' ? 'en-US' : 'tr-TR', options).format(dateString);
}

export function extractHourFromDateTimeStr(dateTime) {
  if (!dateTime) return '';
  return dateTime.slice(11, 16);
}

export function hourToComparableNumber(date) {
  if (date === undefined || date === null) {
    return null;
  }
  const isPm = date.toLowerCase().includes('pm');
  const hour = Number(date.substring(0, 5).split(':')[0]);
  const minute = Number(date.substring(0, 5).split(':')[1].trim());
  return hour * 60 + minute + (isPm ? 12 * 60 : 0);
}

export function hourComparator(date1, date2) {
  const date1Number = hourToComparableNumber(date1);
  const date2Number = hourToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
}

export function localFormatTimeString(time) {
  if (!time) return '';
  return isLocaleEnUs() ? convertTo12Hour(time) : time;
}

export function timeLocalFormat(time) {
  return isLocaleEnUs() ? convertTo12Hour(intToTimeStr(time)) : intToTimeStr(time);
}

export function formatDateLocal(date) {
  return isLocaleEnUs() ? format(date, 'MMM d, yyyy') : format(date, 'd MMMM yyyy');
}

export function timeStampLocalFormat(time) {
  return isLocaleEnUs() ? format(time, 'hh:mm a') : format(time, 'HH:mm');
}

export function timeStampLocalFormatWithLocalize(time) {
  return isLocaleEnUs()
    ? format(new Date(time), defaults.textFirstMonthDate)
    : format(new Date(time), defaults.textMiddleMonthDate, { locale: tr });
}

// will be deprecated use convertMinutesToHoursAndMinutes in "convert.js"
export function minutesConvert(duration) {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return { hours, minutes };
}

export function getTimeFromString(str) {
  // Split the string into hours, minutes, and the am/pm indicator
  const [time, period] = str.split(' ');
  const [hours, minutes] = time.split(':');

  // Convert hours to a number
  let hour = parseInt(hours, 10);

  // Adjust the hour based on the am/pm indicator (if present)
  if (period) {
    if (period.toLowerCase() === 'pm' && hour !== 12) {
      hour += 12;
    } else if (period.toLowerCase() === 'am' && hour === 12) {
      hour = 0;
    }
  }

  // Pad single-digit hours and minutes with leading zeros
  const formattedHour = hour.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Return the time as a string in 24-hour format
  return `${formattedHour}:${formattedMinutes}`;
}

export const formatTimeLocal = (dateTime) =>
  isLocaleEnUs() ? convertTo12Hour(format(dateTime, 'HH:mm')) : format(dateTime, 'HH:mm');

export const plannedActual = (planned, actual, buffer = 3, seperator = '-', isMetric = true) => {
  const computedBuffer = isMetric ? buffer : buffer * (3 / 2);
  let withinBuffer = true;
  if (actual - planned > computedBuffer) withinBuffer = false;
  const plannedFormatted = `<span>${decimalNumber(isMetric ? planned : kmToMiles(planned))}</span>`;
  const actualFormatted = actual
    ? ` ${seperator} <span class="${!withinBuffer ? 'text-danger' : ''}">${decimalNumber(
        isMetric ? actual : kmToMiles(actual),
      )}</span>`
    : null;

  return `${plannedFormatted} ${actual ? '-' : ''} ${actualFormatted || ''}`;
};

export function formattedTimeWindowActual(start, end, actual, direction) {
  const startDateTime = new Date(start);
  const endDateTime = new Date(end);
  const actualDateTime = new Date(actual);

  let isLate = false;
  if (actual) {
    isLate = direction === 'pickup' ? startDateTime < actualDateTime : endDateTime < actualDateTime;
  }

  let str = `${formatTimeLocal(new Date(start))} - ${formatTimeLocal(new Date(end))}`;

  if (actual) {
    str += ` / <span class="${isLate ? 'text-danger' : 'text-success'}">${formatTimeLocal(actualDateTime)}</span>`;
  }
  return str;
}

export const stringToDate = (strTimeOrDate) => {
  if (!strTimeOrDate) return strTimeOrDate;
  if (Object.prototype.toString.call(strTimeOrDate) !== '[object Date]') {
    if (strTimeOrDate.toString().length < 5) {
      return new Date(`${new Date().toISOString().split('T')[0]} ${intToTimeStr(strTimeOrDate)}`);
    }
    return new Date(strTimeOrDate);
  }
  return strTimeOrDate;
};

export const plannedActualTime = (plannedStr, actualStr, bufferInMin = 0, seperator = '-') => {
  if (!plannedStr && !actualStr) return '';
  const planned = stringToDate(plannedStr);
  const actual = stringToDate(actualStr);

  const diff = differenceInMinutes(actual, planned) || undefined;

  const formattedPlanned = planned ? `<span>${formatTimeLocal(planned)}</span>` : ' ? ';
  const formattedActual = actual
    ? ` ${seperator} <span class="${diff > 0 + bufferInMin ? 'text-danger' : 'text-success'}">${formatTimeLocal(
        actual,
      )}</span>`
    : '';

  return `${formattedPlanned}${formattedActual}`;
};

export function is24HourFormat() {
  const formattedTime = new Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    hourCycle: 'h23', // Ensures we get 23 instead of 11 PM if 24h format
  }).format(new Date());

  return !formattedTime.includes('AM') && !formattedTime.includes('PM');
}

export function addInMinutes(dateStr, minutesToAdd) {
  const date = parse(dateStr, 'yyyy-MM-dd HH:mm:ss', new Date());
  const newDate = addMinutes(date, minutesToAdd);
  const formattedDate = format(newDate, 'yyyy-MM-dd HH:mm:ss');
  return formattedDate;
}

export function subInMinutes(dateStr, minutesToSub) {
  const date = parse(dateStr, 'yyyy-MM-dd HH:mm:ss', new Date());
  const newDate = subMinutes(date, minutesToSub);
  const formattedDate = format(newDate, 'yyyy-MM-dd HH:mm:ss');
  return formattedDate;
}

export function isInTimeInWindow(time, start, end) {
  const [startHour, startMinute] = start.split(':').map(Number);
  const [endHour, endMinute] = end.split(':').map(Number);
  const [hour, minute] = time.split(':').map(Number);

  const startTime = startHour * 60 + startMinute; // Convert to minutes since midnight
  const endTime = endHour * 60 + endMinute;
  const givenTime = hour * 60 + minute;

  if (endTime < startTime) {
    // Time window spans midnight
    return givenTime >= startTime || givenTime < endTime;
  }
  return givenTime >= startTime && givenTime < endTime;
}


// return formatted date as "March 10, 2025"
export function formattedDateLong(date) {
  if (!date) return '';
  console.log(date);
  return date.toLocaleString(navigator.language || navigator.userLanguage, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

// return local formatted date as "08/24/2024, 5:00 PM"
export function formattedDateFull(date) {
  if (!date) return '';
  return date.toLocaleString(navigator.language || navigator.userLanguage, {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}
