<template>
  <div v-if="isLoading" class="loadingApp position-relative my-3" style="min-height: 731px">
    <div class="imgContainer">
      <img :src="`${publicPath}flexigo-loader.gif`" />
    </div>
  </div>
  <div v-else class="out-countainer custom-border my-3">
    <div class="container-header row w-100 m-0">
      <div class="col-8 p-3">{{ this.$t('flexigo.dashboard.onTimePerformanceOverTime') }}</div>
      <div class="col-4 p-2 text-end">
        <dropdown
          :selectedRouteId="selectedRouteId"
          :list-data="groupedRoutesByNameWithSingleId"
          @select="(routeId, routeName) => selectRoutes(routeId, routeName)"
        />
      </div>
    </div>
    <div class="border-0">
      <!-- <highcharts :options="requestChartOptions"/> -->
      <div class="route-list-navbar bg-white border-0 m-0">
        <ul class="badge-icon">
          <li v-for="(item, index) in tabLabels" :key="index">
            <a href="#" :class="{ active: selectedTab === item.label }" @click.prevent="selectedTab = item.label">
              {{ $t(item.text) }}
            </a>
          </li>
        </ul>
      </div>

      <div class="bg-white p-3 outer-border">
        <div class="">
          <div class="row m-0">
            <div class="col-3 p-0">
              <div class="px-2">
                <div class="border rounded p-4 text-center">
                  <div style="font-size: 1.1rem">
                    <u
                      v-if="selectedTab === 'CAMPUS'"
                      class="has-cursor-pointer"
                      @click="direct({ page: 'RouteStatuses', tab: 'routes', routeInstanceState: 'ARRIVED_LATE' })"
                    >
                      {{ value[selectedTab]['lateArrival'] || 0 }}
                    </u>
                    <span v-else>{{ value[selectedTab]['lateArrival'] || 0 }}</span>
                  </div>
                  <div>{{ this.$t('flexigo.dashboard.lateArrivals') }}</div>
                </div>
              </div>
            </div>
            <div class="col-3 p-0">
              <div class="px-2">
                <div class="border rounded p-4 text-center">
                  <div style="font-size: 1.1rem">
                    <u
                      v-if="selectedTab === 'CAMPUS'"
                      class="has-cursor-pointer"
                      @click="
                        direct({
                          page: 'RouteStatuses',
                          tab: 'routes',
                          routeInstanceState: 'NOT_ARRIVED,DEVICE_NOT_OK',
                        })
                      "
                    >
                      {{ value[selectedTab]['noArrival'] || 0 }}
                    </u>
                    <span v-else>{{ value[selectedTab]['noArrival'] || 0 }}</span>
                  </div>
                  <div>{{ this.$t('flexigo.dashboard.noArrivalsDepartures') }}</div>
                </div>
              </div>
            </div>
            <div class="col-3 p-0">
              <div class="px-2">
                <div class="border rounded p-4 text-center">
                  <div style="font-size: 1.1rem">
                    <u
                      v-if="selectedTab === 'CAMPUS'"
                      class="has-cursor-pointer"
                      @click="direct({ page: 'RouteStatuses', tab: 'routes', routeInstanceState: 'LEAVE_EARLY' })"
                    >
                      {{ value[selectedTab]['earlyDeparture'] || 0 }}
                    </u>
                    <span v-else>{{ value[selectedTab]['earlyDeparture'] || 0 }}</span>
                  </div>
                  <div>{{ this.$t('flexigo.dashboard.earlyDepartures') }}</div>
                </div>
              </div>
            </div>
            <div class="col-3 p-0">
              <div class="px-2">
                <div class="border rounded p-4 text-center">
                  <div style="font-size: 1.1rem">
                    {{ formatPercent(value[selectedTab]['performance'], locale, 0, 0) }}
                  </div>
                  <div>{{ this.$t('flexigo.dashboard.onTimePerformance') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedTab === 'CAMPUS'">
          <highcharts :options="campusChartOptions" />
        </div>
        <div v-if="selectedTab === 'STOP'">
          <highcharts :options="stopChartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import { format } from 'date-fns';
import { en } from 'date-fns/locale';
import { formatPercent } from '@/helpers/utility';
import { intToDate } from '@/helpers/dateTime';
import { defaults } from '@/helpers/appDefaults';
import dropdown from '../routeDropdown.vue';

let vm;

export default {
  name: 'PerformanceGraph',
  components: { highcharts: Chart, dropdown },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      selectedXaxisPoint: undefined,
      selectedTab: 'CAMPUS',
      selectedRouteId: undefined,
      tabLabels: [
        {
          label: 'CAMPUS',
          text: this.$t('flexigo.common.campus'),
        },
        {
          label: 'STOP',
          text: this.$t('flexigo.common.stop'),
        },
      ],
      campusChartOptions: {
        chart: {
          type: 'spline',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
          // align: 'left',
        },
        xAxis: {
          categories: [],
          gridLineWidth: 1,
        },
        yAxis: [
          {
            allowDecimals: false,
            crosshair: true,
            lineWidth: 1,
            title: {
              text: null,
              margin: 0,
            },
            // labels: {
            //   align: 'left',
            //   step: 1,
            // },
          },
          {
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            labels: {
              style: {
                color: '#1eb64d',
              },
            },
            allowDecimals: false,
            opposite: true,
            lineWidth: 1,
            crosshair: true,
            title: {
              text: '',
              margin: 0,
            },
            // labels: {
            //   align: 'right',
            //   step: 1,
            // },
          },
        ],
        legend: {
          // symbolType: 'square',
          itemStyle: {
            color: '#7f8fa4',
            fontSize: '10px',
          },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            return `
                <div class="dashboard-graph-tooltip">
                  <div> ${this.points[0].key}</div>
                  <br/>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>Late Arrival</span>
                    <span>${this.points[0].y}</span>
                  </div>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>Early Departure</span>
                    <span>${this.points[1].y}</span>
                  </div>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>No Arrival</span>
                    <span>${this.points[2].y}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>On Time Performance</span>
                    <span>${formatPercent(this.points[3].y, this.locale, 0, 0)}</span>
                  </div>
                </div>
            `;
          },
          shared: true,
          // headerFormat: '',
          backgroundColor: '#363647',
          style: {
            color: 'white',
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            pointPadding: 0,
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click(e) {
                  vm.$data.selectedXaxisPoint = {
                    date: e?.point?.category,
                    chartOptionsType: 'campusChartOptions',
                    type: 'CAMPUS',
                  };
                },
              },
            },
          },
        },
        series: [
          {
            type: 'column',
            name: this.$t('flexigo.dashboard.lateArrivals'),
            color: '#5667ff',
            data: [],
          },
          {
            type: 'column',
            name: this.$t('flexigo.dashboard.earlyDepartures'),
            color: '#f5b548',
            data: [],
          },
          {
            type: 'column',
            name: this.$t('flexigo.dashboard.noArrivals'),
            color: '#b9b9b9',
            data: [],
          },
          {
            yAxis: 1,
            type: 'spline',
            name: `${this.$t('flexigo.dashboard.onTimePerformance')} (%)`,
            color: '#1eb64d',
            data: [],
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#1eb64d',
              fillColor: 'white',
              width: 150,
            },
          },
        ],
      },
      stopChartOptions: {
        chart: {
          type: 'spline',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
          // align: 'left',
        },
        xAxis: {
          categories: [],
          gridLineWidth: 1,
        },
        yAxis: [
          {
            allowDecimals: false,
            crosshair: true,
            lineWidth: 1,
            title: {
              text: null,
              margin: 0,
            },
            // labels: {
            //   align: 'left',
            //   step: 1,
            // },
          },
          {
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            labels: {
              style: {
                color: '#1eb64d',
              },
            },
            allowDecimals: false,
            opposite: true,
            lineWidth: 1,
            crosshair: true,
            title: {
              text: '',
              margin: 0,
            },
            // labels: {
            //   align: 'right',
            //   step: 1,
            // },
          },
        ],
        legend: {
          // symbolType: 'square',
          itemStyle: {
            fontSize: '10px',
            color: '#7f8fa4',
          },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            return `
                <div class="dashboard-graph-tooltip">
                  <div> ${this.points[0].key}</div>
                  <br/>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>Late Arrival</span>
                    <span>${this.points[0].y}</span>
                  </div>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>Early Departure</span>
                    <span>${this.points[1].y}</span>
                  </div>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>No Arrival</span>
                    <span>${this.points[2].y}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>On Time Performance</span>
                    <span>${formatPercent(this.points[3].y, this.locale)}</span>
                  </div>
                </div>
            `;
          },
          shared: true,
          // headerFormat: '',
          backgroundColor: '#363647',
          style: {
            color: 'white',
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            pointPadding: 0,
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click(e) {
                  vm.$data.selectedXaxisPoint = {
                    date: e?.point?.category,
                    chartOptionsType: 'stopChartOptions',
                    type: 'STOP',
                  };
                },
              },
            },
          },
        },
        series: [
          {
            type: 'column',
            name: this.$t('flexigo.dashboard.lateArrivals'),
            color: '#5667ff',
            data: [],
          },
          {
            type: 'column',
            name: this.$t('flexigo.dashboard.earlyDepartures'),
            color: '#f5b548',
            data: [],
          },
          {
            type: 'column',
            name: this.$t('flexigo.dashboard.noArrivals'),
            color: '#b9b9b9',
            data: [],
          },
          {
            yAxis: 1,
            type: 'spline',
            name: `${this.$t('flexigo.dashboard.onTimePerformance')} (%)`,
            color: '#1eb64d',
            data: [],
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#1eb64d',
              fillColor: 'white',
              width: 150,
            },
          },
        ],
      },
    };
  },
  computed: {
    locale() {
      return navigator.language;
    },
    groupedRoutesByName() {
      const grouped = Object.groupBy(this.value.routes, ({ name }) => name);
      Object.keys(grouped).forEach((key) => {
        grouped[key] = grouped[key].map((item) => item.id);
      });
      return grouped;
    },
    groupedRoutesByNameWithSingleId() {
      const grouped = Object.groupBy(this.value.routes, ({ name }) => name);
      const formatRoutes = [];
      Object.keys(grouped).forEach((key) => {
        formatRoutes.push({
          name: key,
          id: grouped[key][0].id,
          type: grouped[key][0].type,
        });
      });
      return formatRoutes;
    },
  },
  watch: {
    isLoading(value) {
      if (!value) this.prepareGraphData();
    },
    selectedXaxisPoint(value) {
      const dateIndex = this[value.chartOptionsType].xAxis.categories.findIndex((item) => value.date === item);
      const date = format(intToDate(this.value[value.type].stats[dateIndex].routeDay), defaults.date);

      const queries = {
        ...this.$route.query,
        ...{
          selectedTab: 'routes',
          date: 'customDate',
          fromDate: date,
          toDate: date,
          workgroupType: this.type,
        },
      };
      const routeData = this.$router.resolve({ name: 'RouteStatuses', query: queries });
      window.open(routeData.href, '_blank');

      // this.$router.push(
      //   {
      //     name: 'RouteStatuses',
      //     query: queries,
      //   },
      // );
    },
  },
  mounted() {
    this.prepareGraphData();
    vm = this;
  },
  methods: {
    selectRoutes(routeId, routeName) {
      this.selectedRouteId = routeId;
      this.$emit('performance-refresh', this.groupedRoutesByName[routeName]);
    },
    formatPercent,
    prepareGraphData() {
      if (this.value.CAMPUS?.stats?.length) {
        this.campusChartOptions.xAxis.categories = this.value.CAMPUS.stats.map((item) =>
          format(intToDate(item.routeDay), 'dd LLL', { locale: en }),
        );
        this.campusChartOptions.series[0].data = this.value.CAMPUS.stats.map((item) => item.lateArrival);
        this.campusChartOptions.series[1].data = this.value.CAMPUS.stats.map((item) => item.earlyDeparture);
        this.campusChartOptions.series[2].data = this.value.CAMPUS.stats.map((item) => item.noArrival);
        this.campusChartOptions.series[3].data = this.value.CAMPUS.stats.map((item) => item.performance);
      }

      if (this.value.STOP?.stats?.length) {
        this.stopChartOptions.xAxis.categories = this.value.STOP.stats.map((item) =>
          format(intToDate(item.routeDay), 'dd LLL', { locale: en }),
        );
        this.stopChartOptions.series[0].data = this.value.STOP.stats.map((item) => item.lateArrival);
        this.stopChartOptions.series[1].data = this.value.STOP.stats.map((item) => item.earlyDeparture);
        this.stopChartOptions.series[2].data = this.value.STOP.stats.map((item) => item.noArrival);
        this.stopChartOptions.series[3].data = this.value.STOP.stats.map((item) => item.performance);
      }
    },
    direct(options) {
      const { page, tab, routeInstanceState } = options;
      // const { lang, date, fromDate, toDate, sort } = this.$route.query;
      this.$route.query.selectedTab = tab;
      this.$route.query.routeInstanceState = routeInstanceState;
      this.$route.query.workgroupType = this.type;

      // this.$router.push(
      //   {
      //     name: page,
      //     query: this.$route.query,
      //   },
      // );

      const routeData = this.$router.resolve({ name: page, query: this.$route.query });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style scoped>
.custom-border {
  border: 1px solid #e6eaee;
  border-radius: 4px;
}
.out-countainer {
  background-color: white;
}
.container-header {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e6eaee;
}

.number-value {
  font-size: 18px;
  font-weight: 600;
}
.text-value {
  font-size: 12px;
  font-weight: 500;
}
</style>
