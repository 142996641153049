<template>
  <div class="container-xxl dashboard-background">
    <div class="row mb-3">
      <div>
        <h6 class="float-start mt-3">flexiShuttle</h6>
        <search
          class="float-end mt-2"
          @select="refresh"
        />
      </div>
      <div>
      </div>
    </div>

    <flexi-go-cards :route-data="stats.routeStat" :budget-data="stats.budgetStat" />

    <div class="row mb-3">
      <div class="col-8">
        <system-status-admin
          :user-registration="stats.personnelStat"
          :routes="routeStates.routeStates"
          @getFormattedDate="changeSystemStatusData" />
      </div>
      <div class="col-4">
        <tickets
          :ticket-data="tickets"
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <employee-location />
      </div>
    </div>
  </div>
</template>

<script>
import FlexiGoCards from '../../components/dashboard/flexiGoCards.vue';
import Tickets from '../../components/dashboard/tickets.vue';
import SystemStatusAdmin from '../../components/dashboard/systemStatusAdmin.vue';
import Search from '../../components/campusSearch.vue';
import EmployeeLocation from '../../components/dashboard/employeeLocation.vue';
import dashboardService from '../../services/dashboard';

export default {
  name: 'Dashboard-Admin',
  components: {
    Search, SystemStatusAdmin, FlexiGoCards, Tickets, EmployeeLocation,
  },
  data() {
    return {
      stats: [],
      tickets: [],
      routeStates: [],
    };
  },
  mounted() {
    this.getStats();
    this.getTasks();
    this.getRouteStates();
  },
  methods: {
    async getStats(options) {
      this.stats = await dashboardService.getStats(options);
    },
    async getRouteStates(options) {
      this.routeStates = await dashboardService.getRouteStates(options);
    },
    changeSystemStatusData(data) {
      this.getStats({ fromDate: data.fromDate, toDate: data.toDate });
      this.getRouteStates({ fromDate: data.fromDate, toDate: data.toDate });
    },
    refresh(campus) {
      this.getStats({ campusId: campus?.id });
    },
  },
};
</script>

<style>

</style>
