import { PLANNER, CORPORATE, PLANNER_CORPORATE } from './base';

export const calendars = {
  parkings: `${CORPORATE}/calendars/parks`,
  vehicles: `${CORPORATE}/calendars/:calendarId/vehicles`,
  // resources: `${UMNG_REST}/calendar/resources`,
  // events: `${UMNG_REST}/calendar/:id/events`,

  // assignableVehicles: see planner/drivers
};

export default {
  corporate: {
    dashboard: `${PLANNER_CORPORATE}/dashboard`,
    addresses: {
      list: `${PLANNER_CORPORATE}/addresses`,
      save: `${PLANNER_CORPORATE}/addresses/saveList`,
      reverseGeocode: `${PLANNER_CORPORATE}/addresses/reverseGeocode`,
    },
    vehicles: `${PLANNER_CORPORATE}/vehicles`,
    drivers: `${PLANNER_CORPORATE}/drivers`,
    excludedDrivers: `${PLANNER_CORPORATE}/flexiride/calendar/excluded-drivers`,
    excludedRiders: `${PLANNER_CORPORATE}/flexiride/calendar/excluded-route-riders`,
    resources: `${PLANNER_CORPORATE}/calendar/resources`,
    approveDraft: `${PLANNER_CORPORATE}/calendar/resources/:resourceId/approveDraft`,
    events: `${PLANNER_CORPORATE}/calendar/events`,
    removeGaps: `${PLANNER_CORPORATE}/calendar/:routeId/removeGaps`,
    updateFastestSequence: `${PLANNER_CORPORATE}/calendar/:routeId/updateFastestSequence`,
    calculateTraffic: `${PLANNER_CORPORATE}/calendar/:routeId/calculateTraffic`,
    track: `${PLANNER_CORPORATE}/calendar/track`,
    depots: `${PLANNER_CORPORATE}/depots`,
    orders: {
      orders: `${PLANNER_CORPORATE}/orders`,
      update: `${PLANNER_CORPORATE}/calendar/resources/:resourceId/updateOrder`,
      confirmOrdersFile: `${PLANNER_CORPORATE}/orders/confirmOrders`,
      removeRoute: `${PLANNER_CORPORATE}/orders/:orderId/remove-route`,
    },
    optimizations: `${PLANNER_CORPORATE}/optimizations`,
    autoplan: `${PLANNER_CORPORATE}/optimizations/autoPlan`,
    autoPlanAsync: `${PLANNER_CORPORATE}/optimizations/auto-plan-async`,
    regions: `${PLANNER_CORPORATE}/regions`,
    settings: {
      locations: `${PLANNER_CORPORATE}/settings/locations`,
      loadDimensions: `${PLANNER_CORPORATE}/settings/loadDimensions`,
      vehicleFeatures: `${PLANNER_CORPORATE}/settings/vehicleFeatures`,
      driverSkills: `${PLANNER_CORPORATE}/settings/driverSkills`,
      application: `${PLANNER_CORPORATE}/settings/applications`,
    },
    vehicleInspections: `${PLANNER_CORPORATE}/drivers/vehicle-inspection/answers`,
  },
  poolOrders: `${PLANNER}/portal/pool-orders`,
};
