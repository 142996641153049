<template>
  <div>
    <div class="search-dashboard-map search-route p-3">
      <div class="text-center search-input">
        <div class="position-absolute search-icon">
          <img :src="`${publicPath}icons/search.svg`" >
        </div>
        <gmap-autocomplete
          class="form-control input-text"
          :placeholder="$t('flexigo.common.searchLocation')"
          @place_changed="placeChanged($event)"
        />
      </div>
    </div>
    <gmap-map
      ref="gmap"
      :center="map.center"
      :zoom="map.zoom"
      :map-type-id="map.mapTypeId"
      :options="map.options"
      style="width: 100%; height: 417px;"
    >
      <gmap-polygon
        v-for="cluster in clusters"
        :key="cluster.geohash"
        :paths="cluster.bounds"
        :options="{
          fillOpacity: cluster.opacity,
          fillColor: '#FFB200',
          strokeWeight: 0.5,
          strokeOpacity: 1,
          strokeColor: '#fff',
          zIndex: 10,
        }"
        @click="displayClusterDetails(cluster)" />

      <template v-for="route in routes">
        <gmap-marker
          v-for="station in route.stations"
          :key="station.id"
          :position="{lat: station.location.latitude, lng: station.location.longitude}"
          :icon="`${publicPath}icons/map-blue-dotmap-blue-dot.png`"
        />
      </template>

      <template v-if="routes.length">
        <gmap-polyline
          v-for="route in routes"
          :key="route.id"
          :path="route.polylinePath"
          :options="{
            strokeColor: route.avgOccupancy < 60 ? 'red' : 'green',
            strokeWeight: 1.3,
            zIndex: 11,
          }"
          @click="onLineHover"
        />
      </template>
    </gmap-map>

    <div class="map-legend mt-3">
      {{$t('flexigo.common.riders')}} &nbsp;
      <span v-for="s in opacityScale" :key="s" class="small mb-1 me-3">
        {{$t('flexigo.dashboard.over', [s])}} <span class="scale-box" :style="{opacity: getOpacity(s-1)}"></span>
      </span>
    </div>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import Geohash from '@/helpers/geohash';

export default {
  name: 'DistrictGeoHashMap',
  props: {
    clusterData: {
      type: Array,
      required: true,
    },
    routeData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fitBoundsData: [],
      publicPath: process.env.BASE_URL,
      map: {
        center: {
          lat: 25.761681,
          lng: -80.191788,
        },
        // mapTypeId: 'hybrid',
        zoom: 10,
        options: {
          mapTypeControl: false,
          styles: [{
            // featureType: 'road',
            elementType: 'all',
            stylers: [
              { saturation: -100 }, // <-- THIS
            ],
          }],
        },
      },
      opacityScale: [1, 3, 5, 15, 30],
    };
  },
  computed: {
    google: gmapApi,
    clusters() {
      let min = Number.MAX_SAFE_INTEGER;
      let max = Number.MIN_SAFE_INTEGER;
      return this.clusterData.map((el) => {
        if (!el.geohash) {
          console.log(el);
          throw new Error('no geohash');
        }
        const bounds = Geohash.getBounds(el.geohash);
        const boundsArray = [bounds.ne, bounds.se, bounds.sw, bounds.nw];
        const { count } = el;
        const opacity = this.getOpacity(count);
        if (count > max) max = count;
        if (count < min) min = count;
        // if (el.day_count > this.maxDays) this.maxDays = el.day_count;
        // if (el.day_count < this.maxDays) this.minDays = el.day_count;
        this.fitBoundsData = [...this.fitBoundsData, ...boundsArray];
        this.fitBounds(this.fitBoundsData);

        return {
          ...el,
          bounds: boundsArray,
          count,
          opacity,
        };
      });
    },
    routes() {
      let fitBoundsData = [];
      const routes = this.routeData.map((el) => {
        fitBoundsData = [...fitBoundsData, ...el.routeDetail.path.lstTrackPositions.map((latLng) => ({ lat: latLng.latitude, lng: latLng.longitude }))];
        return {
          id: el.routeId,
          avgOccupancy: el.avgVehicleOccupancy,
          stopIds: el.routeDetail.path.lstRouteStopIds,
          polygon: el.routeDetail.path.lstTrackPolygon,
          polylinePath: el.routeDetail.path.lstTrackPositions.map((latLng) => ({ lat: latLng.latitude, lng: latLng.longitude })),
          totalKm: el.routeDetail.path.distanceInKm,
          totalMins: el.routeDetail.path.durationInMin,
          stations: el.stations,
        };
      });
      this.fitBounds(fitBoundsData);
      return routes;
    },
  },
  methods: {
    placeChanged(event) {
      if (event.name) {
        this.map.center = {
          lat: event.geometry.location.lat(),
          lng: event.geometry.location.lng(),
        };
        this.map.zoom = 18;
      }
    },
    fitBounds(markers) {
      if (markers.length) {
        const bounds = new this.google.maps.LatLngBounds();
        markers.forEach((marker) => {
          bounds.extend(new this.google.maps.LatLng(marker.lat, marker.lng));
        });
        this.$refs.gmap.$mapObject.fitBounds(bounds);
      }
    },
    getOpacity(count) {
      for (let index = 0; index < this.opacityScale.length; index++) {
        if (count < this.opacityScale[index]) return (index + 1) * 0.2;
      }
      return 1;
    },
    displayClusterDetails(cluster) {
      console.log(cluster);
    },
    onLineHover(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss">
.scale-box{
  width: 19px;
  height: 12px;
  display: inline-block;
  border: 1px solid silver;
  margin: auto 8px auto 4px;
  vertical-align: middle;
  background-color: #FFB200;
}
</style>
