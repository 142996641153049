import { utcToZonedTime, format as tzFormat } from 'date-fns-tz';
import hereRouting from '@/helpers/here/routing';
import { encode, decode } from '@/helpers/here/flexipolyline';
import { toLatLng } from '@/modules/Flexigo/helpers/utility';

export function getMapIcon(id, type, otp, routeState, nextStop) {
  if (type === 'campus' && otp === false) {
    // { stroke: '#cb3e4f', fill: '#cb3e4f', comment: 'late' };
    return 'pin-campus-anomaly.svg';
  }
  if (type === 'campus' && otp === true) {
    // { stroke: '#1eb64d', fill: '#1eb64d', comment: 'on time' };
    return 'pin-campus-ok.svg';
  }

  if (type === 'campus' && otp === null) {
    // { stroke: '#1eb64d', fill: '#1eb64d', comment: 'on time' };
    return 'pin-campus.svg';
  }

  // return {
  //     stroke: '#CB3E4F', fill: 'white', strokeWidth: 2, strokeDasharray: '3, 3', textFill: '#CB3E4F', comment: 'next',
  //   };

  if (id === nextStop?.id) {
    const stroke = routeState === 'ON_THE_WAY_WITH_DELAY' ? '#CB3E4F' : '#50DE7B';
    const textFill = routeState === 'ON_THE_WAY_WITH_DELAY' ? '#CB3E4F' : '#50DE7B';
    return {
      stroke,
      fill: 'white',
      strokeWidth: 1,
      strokeDasharray: '3, 3',
      textFill,
      comment: 'next',
    };
  }
  if (otp === false) {
    return { stroke: '', fill: '#CB3E4F', comment: 'late' };
  }

  if (otp === true) {
    return { stroke: '', fill: '#50DE7B', comment: 'on time' };
  }

  return { stroke: 'white', fill: '#7F8FA4', comment: 'default' };
}

export function convertToZonedTime(date, timeZone) {
  if (timeZone && timeZone !== 'UTC') {
    // Convert the UTC date to the same instant in the target timeZone
    const zonedDate = utcToZonedTime(date, timeZone);

    // Format the zoned date to ISO 8601 string
    const pattern = "yyyy-MM-dd'T'HH:mm:ssXXX";
    const formattedDateWithOffset = tzFormat(zonedDate, pattern, { timeZone });
    return formattedDateWithOffset;
  }
  return date.toISOString();
}

export function orderStations(order, stations) {
  if (!order || !stations) return [];
  const orderedStations = stations.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});
  return order.map((o) => orderedStations[o]).filter((el) => el);
}

export function getMarkerPosition(p) {
  return {
    lat: Number(p.homeLocation.latitude),
    lng: Number(p.homeLocation.longitude),
  };
}

export function orderStationsByWaypoints(stations, waypointsOrder, fromType = 'CAMPUS') {
  if (!waypointsOrder) return stations;
  if (fromType !== 'CAMPUS') {
    const waypoints = waypointsOrder.map((order) => stations.slice(1)[order]);
    return [stations[0], ...waypoints];
  }
  const waypoints = waypointsOrder.map((order) => stations[order]);
  waypoints.push(stations[stations.length - 1]);
  return waypoints;
}

export function prepareStationMarkerData(station) {
  return {
    ...station,
    stationName: station.description,
    address: station.location.address,
    position: {
      lat: station.location.latitude,
      lng: station.location.longitude,
    },
    icon: {
      url: `${process.env.BASE_URL}icons/station.svg`,
      labelOrigin: { x: 13, y: 15 },
    },
    animation: 2,
    visible: true,
    infoData: {
      serviceLine: station.description,
      personnel: station.personnelIds.length,
    },
  };
}

/*
  directionsService: google.maps.DirectionsService
  coords: { origin: { lat: 41.015137, lng: 28.979530 }, destination: { lat: 41.008633, lng: 28.978360 }, waypoints: [{ location: { lat: 41.008633, lng: 28.978360 } }] }
  drivingOptions: { departureTime: new Date(), trafficModel: 'pessimistic' }
  travelMode: 'DRIVING'
  provideRouteAlternatives: false
*/
export async function getDirections(
  directionsService,
  coords,
  drivingOptions,
  travelMode = 'DRIVING',
  provideRouteAlternatives = false,
) {
  console.log(typeof directionsService.route);
  const { origin, destination, waypoints } = coords;
  const request = {
    origin,
    destination,
    waypoints,
    travelMode,
    provideRouteAlternatives,
  };

  if (drivingOptions) request.drivingOptions = drivingOptions;

  return new Promise((resolve, reject) => {
    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        resolve(result);
      } else {
        reject(status);
      }
    });
  });
}

export async function getDirectionsHere(coords, time = {}, timezone = 'UTC') {
  const hereRouter = hereRouting();
  const { origin, destination, waypoints } = coords;

  const request = {
    origin: `${origin.lat},${origin.lng}`,
    destination: `${destination.lat},${destination.lng}`,
    via: waypoints,
    transportMode: 'car',
    routingMode: 'fast',
    alternatives: 0,
  };

  if (time.departureTime) request.departureTime = convertToZonedTime(time.departureTime, timezone);
  if (!time.departureTime && time.arrivalTime) request.arrivalTime = convertToZonedTime(time.arrivalTime, timezone);

  const r = await hereRouter.calculateRoute(request);

  return r;
}

export function completeRouteSummary(sections) {
  const totalSummary = {
    encodedPolyline: '',
    duration: 0,
    length: 0,
    path: [],
    tollCost: 0,
  };

  const sum = sections.reduce((acc, cur) => {
    const { summary, polyline } = cur;
    const { duration, length } = summary;
    const { polyline: decodedPolyline } = decode(polyline);
    acc.length += length;
    acc.duration += duration;
    acc.path = [...acc.path, ...decodedPolyline.map((p) => [p[0], p[1]])];
    return acc;
  }, totalSummary);

  sum.encodedPolyline = encode({ polyline: sum.path });
  return sum;
}

export function getWaypoints(isInbound, allStops, campusLocations, stopDuration = 0, isSube = false) {
  const stopsForNotSube = isInbound ? allStops.slice(1, allStops.length) : allStops.slice(0, allStops.length - 1);
  const stops = isSube ? allStops.slice(1, -1) : stopsForNotSube;
  const campuses = isInbound
    ? campusLocations.slice(0, campusLocations.length - 1)
    : campusLocations.slice(1, campusLocations.length);
  const stopWaypoints = stops
    .map((el) => {
      const { lat, lng } = toLatLng(el);
      const passThrough = el.isPassthrough || false;
      return {
        lat,
        lng,
        stopDuration,
        passThrough,
      };
    })
    .filter((item) => item.lat && item.lng);
  const campusWaypoints = campuses
    .map((el) => ({ lat: el.position.lat, lng: el.position.lng, stopDuration }))
    .filter((item) => item.lat && item.lng);
  const combinedWaypoints = isInbound ? [...stopWaypoints, ...campusWaypoints] : [...campusWaypoints, ...stopWaypoints];
  return isSube ? stopWaypoints : combinedWaypoints;
}

export function cleanPersonnelObject(personnel) {
  return {
    ...personnel.personnel,
    routeId: personnel?.route.id,
    routeName: personnel?.route.name,
    stationName: personnel?.station.name,
    stationDescription: personnel?.station.description,
  };
}

export function decodePolyline(encodedPolyline) {
  if (!encodedPolyline) return [];
  const decoded = decode(encodedPolyline);
  return decoded.polyline.map((a) => ({ lat: a[0], lng: a[1] }));
}

export default orderStations;
