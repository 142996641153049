/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

export default {
  state: {
    openNotifyPanel: false,
    refreshPersonnelList: false,
    refreshSelectedRider: false,
    riderStation: null,
  },

  mutations: {
    SET_OPEN_NOTIFY_PANEL(state, payload) {
      state.openNotifyPanel = payload;
    },
    SET_REFRESH_PERSONNEL_LIST(state, payload) {
      state.refreshPersonnelList = payload;
    },
    SET_REFRESH_SELECTED_RIDER(state, payload) {
      state.refreshSelectedRider = payload;
    },
    SET_RIDER_STATION(state, payload) {
      state.riderStation = payload;
    },
  },

  actions: {
    triggerRefreshPersonnel({ commit }) {
      commit('SET_REFRESH_PERSONNEL_LIST', true);
    },
    resetRefreshPersonnel({ commit }) {
      setTimeout(() => {
        commit('SET_REFRESH_PERSONNEL_LIST', false);
      }, 1000);
    },
  },

  getters: {
    openNotifyPanel(state) {
      return state.openNotifyPanel;
    },
    refreshPersonnelList(state) {
      return state.refreshPersonnelList;
    },
    refreshSelectedRider(state) {
      return state.refreshSelectedRider;
    },
    riderStation(state) {
      return state.riderStation;
    },
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
