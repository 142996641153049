<template>
  <section>
    <div class="d-flex flex-column justify-content-center">
      <div class="text-muted mb-2">{{ $t('flexigo.login.main.label') }}</div>
      <main>
        <div class="form-group scale-scroll visible vstack gap-4">
          <multiselect
            v-model="selectedCampus"
            :options="formattedAccountList"
            :allowEmpty="false"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            :placeholder="$t('flexigo.login.main.placeholder')"
            :maxHeight="300"
            label="display"
            trackBy="value"
            @select="onAccountSelect"
          />
        </div>
      </main>
    </div>
  </section>
</template>
<script>
import multiselect from 'vue-multiselect';

export default {
  name: 'selectAccount',
  components: { multiselect },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedCampus: null,
    };
  },
  computed: {
    formattedAccountList() {
      if (!this.options.length) return [];
      const regex = /(?<==)[^,]+/g;
      return this.options.map((item) => ({ display: item.match(regex).join(' - '), value: item }));
    },
  },
  created() {},
  methods: {
    onAccountSelect(selected) {
      this.$emit('on-select', selected.value);
    },
  },
};
</script>
