import { auth } from '@/endpoints/auth';
import Repository from './repository';

const client = new Repository();
const validLanguages = ['en', 'tr', 'fr'];

export default {
  async getMainMenu(lang = 'en', isRider = false) {
    const url = isRider ? auth.riderMenu : auth.menu;
    if (!validLanguages.includes(lang)) {
      console.error(`Invalid language: ${lang}`);
      throw new Error(`Invalid language: ${lang}`);
    }
    const r = client.get(url, { lang });
    return r;
  },

  async logout() {
    const r = client.get(auth.logout);
    return r;
  },

  async changePassword(data) {
    const r = client.post(auth.changePassword, data);
    return r;
  },

  async createOrUpdatePassword(newPassword, sessionId, routeId = undefined) {
    const r = client.post(auth.newPassword, { newPassword, sessionId, routeId });
    return r;
  }
};
