<template>
  <button :type="type" class="w-100 btn-login" :class="buttonClass" @click.prevent="handleClick">
    <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
    <span v-else class="hstack justify-content-between gap-2">
      <slot></slot>
      <img :src="`${baseUrl}icons/arrow-right-long-white.svg`" alt="" style="height: 12px" />
    </span>
  </button>
</template>
<script>
export default {
  name: 'largeButton',

  props: {
    showArrow: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'solid',
      required: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    data: {
      type: [Object, String, Array, null],
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isValidInput() {
      return this.validateEmail(this.inputValue) && this.inputValue;
    },
    buttonClass() {
      return this.variant;
    },
  },
  watch: {
    inputValue(newVal) {
      this.$emit('change', { value: newVal, isValid: this.validateEmail(newVal) });
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.data);
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/flexigo/variables';

.btn-login {
  &.outlined {
    padding: 0.8em 1.8em !important;
    background-color: transparent;
    color: #000b20;
    box-shadow: 0 0 0 3px #000b20;
    width: calc(100% - 6px) !important;
    margin: 0 3px;
    &:hover,
    &:focus {
      color: #fff;
      background-color: #000b20;
    }
    &.primary-color {
      color: $primary !important;
      box-shadow: 0 0 0 2px $primary !important;
    }
    &:hover,
    &:focus {
      color: #fff !important;
      background-color: $primary !important;
    }
  }
}
</style>
