var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading)?_c('div',{staticClass:"loadingApp position-relative mt-3",staticStyle:{"height":"550px"}},[_c('div',{staticClass:"imgContainer"},[_c('img',{attrs:{"src":`${_vm.publicPath}flexigo-loader.gif`}})])]):_c('div',{staticClass:"out-countainer custom-border mt-3"},[_c('div',{staticClass:"container-header row w-100 m-0"},[_c('div',{staticClass:"col-8 p-3"},[_vm._v(_vm._s(this.$t('flexigo.dashboard.compliance')))]),_c('div',{staticClass:"col-4 p-2 text-end"},[_c('dropdown',{attrs:{"selectedRouteId":_vm.selectedRouteId,"list-data":_vm.value.routes},on:{"select":(val) => _vm.$emit('occupancyRefresh', (_vm.selectedRouteId = val))}})],1)]),_c('div',{staticClass:"border-0"},[_c('div',{staticClass:"bg-white outer-border",staticStyle:{"padding":"28px 16px 16px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('highcharts',{attrs:{"options":_vm.chartOptions}})],1),_c('div',{staticClass:"col-6 pt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"pe-2 ps-3"},[_c('div',{staticClass:"outer-border p-3 rounded"},[_c('div',{staticClass:"text-end d-flex align-items-center justify-content-end has-cursor-pointer",on:{"click":() =>
                        _vm.riderShipArrow === 'arrow-up'
                          ? (_vm.riderShipArrow = 'arrow-down')
                          : (_vm.riderShipArrow = 'arrow-up')}},[_c('div',{staticClass:"bold d-inline-block fw-bold pe-1 two-line-three-dots",domProps:{"innerHTML":_vm._s(_vm.$t('flexigo.dashboard.vanpoolRoutesAvgRidership'))}}),_c('img',{staticClass:"ms-1",attrs:{"src":`${_vm.publicPath}icons/${_vm.riderShipArrow}.svg`}})]),_c('div',{staticClass:"overflow-scroll",staticStyle:{"max-height":"305px"}},_vm._l((_vm.RoutesAndRiderships),function(m,i){return _c('div',{key:m.routeName + i,staticClass:"p-3 border-bottom d-flex align-items-center justify-content-end"},[_c('div',{staticClass:"d-inline-block",staticStyle:{"max-width":"140px"}},[_c('span',{staticClass:"one-line-three-dots text-primary has-cursor-pointer",staticStyle:{"word-break":"break-all"},on:{"click":function($event){return _vm.directToRoute(m.routeId)}}},[_vm._v(_vm._s(m.routeName))])]),_c('span',{staticClass:"px-1"},[_vm._v("/")]),_c('span',[_vm._v(_vm._s(m.riderCount))])])}),0)])])]),_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"pe-3 ps-2"},[_c('div',{staticClass:"outer-border p-3 rounded"},[_c('div',{staticClass:"text-end d-flex align-items-center justify-content-end has-cursor-pointer",on:{"click":() =>
                        _vm.nonCommuteMilesArrow === 'arrow-up'
                          ? (_vm.nonCommuteMilesArrow = 'arrow-down')
                          : (_vm.nonCommuteMilesArrow = 'arrow-up')}},[_c('div',{staticClass:"bold d-inline-block fw-bold pe-1 two-line-three-dots",domProps:{"innerHTML":_vm._s(_vm.$t('flexigo.dashboard.vanpoolRoutesAvgNonCommuteMiles'))}}),_c('img',{staticClass:"ms-1",attrs:{"src":`${_vm.publicPath}icons/${_vm.nonCommuteMilesArrow}.svg`}})]),_c('div',{staticClass:"overflow-scroll",staticStyle:{"max-height":"305px"}},_vm._l((_vm.RoutesAndNonCommutes),function(m,i){return _c('div',{key:m.routeName + i * 2,staticClass:"p-3 border-bottom d-flex align-items-center justify-content-end"},[_c('div',{staticClass:"d-inline-block",staticStyle:{"max-width":"140px"}},[_c('span',{staticClass:"one-line-three-dots text-primary has-cursor-pointer",staticStyle:{"word-break":"break-all"},on:{"click":function($event){return _vm.directToRoute(m.routeId)}}},[_vm._v(_vm._s(m.routeName))])]),_c('span',{staticClass:"px-1"},[_vm._v("/")]),_c('span',[_vm._v(_vm._s(_vm.formatPercent(Number(m.nonCommute))))])])}),0)])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }