/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

export default {
  state: {
    isUnsaved: false,
    routeNext: null,
  },

  mutations: {
    SET_IS_UNSAVED(state, payload) {
      state.isUnsaved = payload;
    },
    SET_ROUTE_NEXT(state, payload) {
      state.routeNext = payload;
    },
  },

  actions: {},

  getters: {
    isUnsaved(state) {
      return state.isUnsaved;
    },
    routeNext(state) {
      return state.routeNext;
    },
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
