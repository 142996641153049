<template>
  <div class="d-flex toggle-wrapper mb-3 p-0">
    <label class="p-0 w-100">
      <input v-model="toggleValue" type="radio" :value="true" class="toggle-input" :disabled="notEditable" />
      <div class="toggle-value-container" v-html="firstLabel"></div>
    </label>
    <label class="p-0 w-100">
      <input v-model="toggleValue" type="radio" :value="false" class="toggle-input" :disabled="notEditable" />
      <div class="toggle-value-container" v-html="secondLabel"></div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    firstLabel: {
      type: String,
      required: true,
    },
    secondLabel: {
      type: String,
      required: true,
    },
    notEditable: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      toggleValue: null,
    };
  },
  watch: {
    value() {
      this.toggleValue = this.value;
    },
    toggleValue() {
      this.$emit('input', this.toggleValue);
    },
  },
  created() {
    this.toggleValue = this.value;
  },
};
</script>

<style></style>
