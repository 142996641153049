export function kmToMiles(km, precision = 2) {
  return parseFloat((km * 0.62137119).toFixed(precision));
}

export function milesToKm(miles, precision = 2) {
  return parseFloat((miles * 1.609344).toFixed(precision));
}

export function mtToFeet(mt, precision = 2) {
  return parseFloat((mt * 3.28084).toFixed(precision));
}
export function mtToMile(mt, precision = 2) {
  return parseFloat((mt * 0.00062137119).toFixed(precision));
}
export function mtToKm(mt, precision = 2) {
  return parseFloat((mt * 0.001).toFixed(precision));
}

export function feetToMt(feet, precision = 2) {
  return parseFloat((feet * 0.3048).toFixed(precision));
}

export function kgToPound(kg, precision = 2) {
  return parseFloat((kg * 2.20462).toFixed(precision));
}

export function poundToKg(pound, precision = 2) {
  return parseFloat((pound * 0.453592).toFixed(precision));
}

export function convertKm(km, unit) {
  return unit === 'METRIC' ? km : kmToMiles(km);
}

export function convertTo12Hour(time) {
  if (!time) return '';
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;
  return `${hours12}:${minutes} ${ampm}`;
}

export function convertMinutesToHoursAndMinutes(minutes, object = false) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (object) return { hours, minutes: remainingMinutes };
  return `${hours ? `${hours}h` : ''} ${remainingMinutes ? `${remainingMinutes}m` : ''}`.trim();
}

export function convertToHoursAndMinutes(time) {
  const hours = Math.floor(time / 60);
  const minutes = Math.floor(time % 60);

  const hoursText = hours > 0 ? `${hours} hr` : '';
  const minutesText = minutes > 0 ? `${minutes} min` : '';

  return `${hoursText} ${minutesText}`.trim();
}
// converts hour and minutes in string format (e.g. 22:30) to Date object
export function convertTimeStringToDate(time) {
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

// converts integer dates to minutes
// 30 -> 30 minutes
// 2330 -> 23 hours 30 minutes
export function convertIntHourFormatToMinutes(time) {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;
  return hours * 60 + minutes;
}
