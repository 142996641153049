import { workgroups } from '@/endpoint.flexigo';
import Repository from './repository';

const client = new Repository();

export default {
  async getWorkgroups(workgroupType, isNotLoop, isOnlyActive) {
    const r = await client.get(workgroups.workgroupInstances, { workgroupType, isNotLoop, isOnlyActive });
    return r;
  },
  async getAll(workgroupType) {
    const r = await client.get(workgroups.self, { workgroupType });
    return r;
  },
  async createAndUpdate(payload) {
    const r = await client.post(workgroups.self, payload);
    return r;
  },
  async uploadExcel(id, file) {
    const formData = new FormData();
    formData.append('file', file);
    const r = await client.post(workgroups.excelUpload.replace(':instanceId', id), formData);
    return r.data;
  },
  async getInstance(id) {
    const r = await client.get(workgroups.getInstance.replace(':instanceId', id));
    return r;
  },
  async addPersonnel(id, payload) {
    const r = await client.post(workgroups.addPersonnel.replace(':instanceId', id), payload);
    return r;
  },
  async removePersonnelFromList(id, payload) {
    const r = await client.post(workgroups.removePersonnelList.replace(':instanceId', id), payload);
    return r;
  },
  async getPersonnelList(isVanpoolUser, payload) {
    const r = await client.post(workgroups.listPersonnel.replace(':isVanpoolUser', isVanpoolUser), payload);
    return r;
  },
  async getWorkbook(instanceId) {
    const r = await client.get(workgroups.instanceWorkbook.replace(':instanceId', instanceId));
    return r;
  },
  async createInstanceWorkbook(instanceId) {
    const url = `${workgroups.instanceWorkbook.replace(':instanceId', instanceId)}`;
    const r = await client.post(url, {});
    return r;
  },
  async deleteTemplate(templateId) {
    const url = `${workgroups.workgroupCancel.replace(':templateId', templateId)}`;
    const r = await client.put(url, {});
    return r;
  },
  async changePersonnelRoute(instanceId, payload) {
    const r = await client.post(workgroups.personnelRouteChange.replace(':instanceId', instanceId), payload);
    return r;
  },
  async getRoutes(workgroupInstanceId) {
    const r = await client.get(workgroups.listRoutes, { workgroupInstanceId, isActive: true });
    return r;
  },
  async getStops(routeId) {
    const r = await client.get(workgroups.listStops, { routeId });
    return r;
  },
  async makeDefault(instanceId) {
    const r = await client.post(workgroups.makeDefault.replace(':instanceId', instanceId));
    return r;
  },
  async changeStatus(instanceId, workgroupStatus) {
    const r = await client.post(workgroups.changeStatus.replace(':instanceId', instanceId), { workgroupStatus });
    return r;
  },

  // async getWorkgroupsWithStatus(workgroupStatus = null, offset = null, limit = null, sortBy = null) {
  //   const r = await client.get(workgroups.self, {
  //     params: {
  //       workgroupStatus, offset, limit, sortBy,
  //     },
  //   });
  //   return r;
  // },

  async getWorkgroupsWithStatus(archived, offset, limit, sortBy) {
    const r = await client.get(
      workgroups.archiveWorkgroups
        .replace(':archived', archived)
        .replace(':offset', offset)
        .replace(':limit', limit)
        .replace(':short', sortBy),
    );
    return r;
  },

  async getInstancesOfArchiveWorkgroups(templateId, archived, offset, limit, sortBy) {
    const r = await client.get(
      workgroups.instancesOfArchiveWorkgroups
        .replace('templateId', templateId)
        .replace(':archived', archived)
        .replace(':offset', offset)
        .replace(':limit', limit)
        .replace(':short', sortBy),
    );
    return r;
  },

  async cancelWorkgroupInstance(payload) {
    const r = await client.put(workgroups.workgroupInstanceCancel, payload);
    return r;
  },

  async getRegions() {
    const r = await client.get(workgroups.getRegions);
    return r;
  },
  async getDepots(serviceCompanyId) {
    const r = await client.get(`${workgroups.getDepots}?serviceCompanyId=${serviceCompanyId}`);
    return r;
  },
  async getWorkgroupsTypeFilter(workgroupType, workingType, campusIds) {
    const r = await client.get(workgroups.workgroupInstances, { workgroupType, workingType, campusIds });
    return r;
  },
};
