/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { hashCode } from '@/helpers/utility';

export default {
  state: {
    allTrips: [],
    ringsById: {},
  },

  mutations: {
    setAllTrips(state, allTrips) {
      state.allTrips = allTrips;
    },
    setRingsById(state, ringsById) {
      state.ringsById = ringsById;
    },
  },

  actions: {},

  getters: {
    ringsById: (state) => (ringHash) => state.ringsById[ringHash],
    firstOfRings(state) {
      const rings = Object.values(state.ringsById);
      return rings.map((ring) => ring[0]);
    },
    // this should return the last active ring of each route
    // if there is no active ring, it should return the first ring
    // if all rings are completed, it should return the last ring
    activeRingInstances(state) {
      const rings = Object.values(state.ringsById);
      return rings.map((ring) => {
        const activeIndex = ring.findIndex((item) => item.routeMonitoringInfo.actualDepartureTime && !item.routeMonitoringInfo.actualArrivalTime);

        // if not found, return the first or last ring
        if (activeIndex < 0) {
          // all completed return the last one
          const lastCompletedIndex = ring.findLastIndex(item => item.routeMonitoringInfo.actualArrivalTime && item.routeMonitoringInfo.actualDepartureTime)
          if (lastCompletedIndex > 0) {
            return lastCompletedIndex === ring.length - 1 ? ring[ring.length - 1] : ring[lastCompletedIndex + 1];
          }
          // return the first one
          return ring[0];
        }

        return ring[activeIndex];
      });
    },
    routeMonitoringInfo(state) {
      if (!state.allTrips.length > 0) return [];
      return state.allTrips[0].routeMonitoringInfo
        ? state.allTrips.map((item) => item.routeMonitoringInfo)
        : state.allTrips;
    },
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
