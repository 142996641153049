<template>
  <div class="card text-dark">
    <div class="card-header dashboard title-style big">{{$t('flexigo.dashboard.employeeLocationAndRouteDistribution')}}</div>
    <div class="card-body">
      <div class="row">
        <div class="col-7">
          <hashmap
            :cluster-data="clusterData"
            :route-data="routeData"
          />
        </div>
        <div class="col-5 border-style">
          <table class="table table-hover" style="cursor: pointer;">
            <thead>
              <tr class="metric-style border-bottom-none">
                <th>{{$t('flexigo.dashboard.city')}}</th>
                <th>{{$t('flexigo.dashboard.numberOfUser')}}</th>
                <th>{{$t('flexigo.dashboard.numberOfRoutes')}}</th>
                <th>{{$t('flexigo.dashboard.occupancyRatioAvg')}}</th>
              </tr>
            </thead>
            <tbody class="employee-location-table border-top-0">
              <tr
                v-for="(loc, index) in locationData"
                :key="`${index} location`"
                :class="{'table-light': index === selectedRowIndex}"
                @click.prevent="selectRoute(index)">
                <td>{{loc.city}} / {{loc.regionName}}</td>
                <td class="text-center">{{loc.numberOfPersonnels}}</td>
                <td class="text-center">{{loc.numberOfRoutes}}</td>
                <td class="text-center">{{loc.avgRate}} ({{loc.minRate}}-{{loc.maxRate}})</td>
              </tr>
            </tbody>
          </table>
          <!-- <data-table :columns="locationColumns" :data="locationData" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dashboardService from '../../services/dashboard';
import routeService from '../../services/route';
import hashmap from './districtGeoHashMap.vue';

export default {
  name: 'EmployeeLocation',
  components: { hashmap },
  props: {
    campusId: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clusterData: [],
      locationData: [],
      routeData: [],
      selectedRowIndex: undefined,
    };
  },
  watch: {
    campusId() {
      this.getDistrictOccupancy(this.campusId);
    },
  },
  mounted() {
    this.getDistrictOccupancy();
    this.getMapData();
  },
  methods: {
    async getDistrictOccupancy(options) {
      const r = await dashboardService.getDistrictOccupancy(options);
      this.locationData = r.districtOccupancyStat;
    },
    async getMapData() {
      this.clusterData = await dashboardService.getDistrictData();
    },
    async getRoutes(routeIds) {
      this.routeData = await routeService.getTracksById(routeIds);
    },
    async selectRoute(index) {
      this.selectedRowIndex = index;
      this.routeData = [];
      const routeIds = this.locationData[index].routeIds?.split(',');
      if (routeIds) {
        await this.getRoutes(routeIds);
      }
    },
  },
};
</script>

<style>

</style>
