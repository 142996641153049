<template>
  <div v-if="isLoading" class="loadingApp position-relative mt-3" style="height: 550px">
    <div class="imgContainer">
      <img :src="`${publicPath}flexigo-loader.gif`" />
    </div>
  </div>
  <div v-else class="out-countainer custom-border mt-3">
    <div class="container-header row w-100 m-0">
      <div class="col-8 p-3">{{ this.$t('flexigo.dashboard.occupancyRatiosOverTime') }}</div>
      <div class="col-4 p-2 text-end">
        <dropdown
          v-if="selectedTab === 'Dynamic'"
          :selectedRouteId="selectedRouteId"
          :list-data="groupedRoutesByNameWithSingleId"
          @select="(routeId, routeName) => selectRoutes(routeId, routeName)"
        />
        <dropdown
          v-if="selectedTab === 'Fixed'"
          :selectedRouteId="selectedRouteId"
          :list-data="groupedRoutesByNameWithSingleId"
          @select="(routeId, routeName) => selectRoutes(routeId, routeName)"
        />
      </div>
    </div>
    <div class="border-0">
      <!-- <highcharts :options="requestChartOptions"/> -->
      <div class="route-list-navbar bg-white border-0 m-0">
        <ul class="badge-icon">
          <li v-for="(item, index) in tabLabels" :key="index">
            <a href="#" :class="{ active: selectedTab === item.label }" @click.prevent="selectedTab = item.label">
              {{ $t(item.label) }}
            </a>
          </li>
        </ul>
      </div>

      <div class="bg-white p-3 outer-border">
        <div v-if="selectedTab === 'Dynamic'">
          <highcharts :options="dynamicChartOptions" />
        </div>
        <div v-if="selectedTab === 'Fixed'">
          <highcharts :options="fixedChartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { getPercentage, formatPercent } from '@/helpers/utility';
import { intToDate } from '@/helpers/dateTime';
import { defaults } from '@/helpers/appDefaults';
import dropdown from '../routeDropdown.vue';

let vm;

export default {
  name: 'OccupancyGraph',
  components: { highcharts: Chart, dropdown },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      selectedXaxisPoint: undefined,
      selectedTab: 'Dynamic',
      selectedRouteId: undefined,
      tabLabels: [
        {
          label: this.$t('flexigo.workgroup.dynamic'),
        },
        {
          label: this.$t('flexigo.workgroup.fixed'),
        },
      ],
      dynamicChartOptions: {
        chart: {
          type: 'spline',
        },
        title: {
          text: null,
          // align: 'left',
        },
        xAxis: {
          crosshair: true,
          lineWidth: 1,
          categories: [],
          gridLineWidth: 1,
        },
        yAxis: {
          allowDecimals: false,
          crosshair: true,
          lineWidth: 1,
          title: {
            text: null,
            margin: 0,
          },
        },
        legend: {
          // symbolType: 'square',
          itemStyle: {
            fontSize: '10px',
            color: '#7f8fa4',
          },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            if (this.points[0].y || this.points[1].y || this.points[2].y) {
              return `
                <div class="dashboard-graph-tooltip">
                  <div> ${this.points[0].key}</div>
                  <br/>
                  <div> Occupancy Ratios</div>
                  <br/><br/>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>Planned</span>
                    <span>${formatPercent(this.points[0].y, this.locale)}</span>
                  </div>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>Reported by driver</span>
                    <span>${formatPercent(this.points[1].y, this.locale)}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Rider checkins</span>
                    <span>${formatPercent(this.points[2].y, this.locale)}</span>
                  </div>
                </div>
            `;
            }
            return `
                <div class="dashboard-graph-tooltip">
                  <div> ${this.points[0].key}</div>
                  <br/>
                  <div> No active routes on this day</div>
                </div>
            `;
          },
          shared: true,
          // headerFormat: '',
          backgroundColor: '#363647',
          style: {
            color: 'white',
          },
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click(e) {
                  vm.$data.selectedXaxisPoint = {
                    date: e?.point?.category,
                    chartOptionsType: 'dynamicChartOptions',
                    type: 'DYNAMIC',
                  };
                },
              },
            },
          },
        },
        series: [
          {
            name: this.$t('flexigo.routeList.planned'),
            color: '#5667ff',
            data: [],
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#5667ff',
              fillColor: 'white',
            },
          },
          {
            name: this.$t('flexigo.dashboard.reportedByDriver'),
            color: '#1eb64d',
            data: [],
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#1eb64d',
              fillColor: 'white',
            },
          },
          {
            name: this.$t('flexigo.dashboard.riderCheckins'),
            color: '#f6b953',
            data: [],
            connectNulls: true,
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#f6b953',
              fillColor: 'white',
              width: 150,
            },
          },
        ],
      },
      fixedChartOptions: {
        chart: {
          type: 'spline',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
          // align: 'left',
        },
        xAxis: {
          crosshair: true,
          lineWidth: 1,
          categories: [],
          gridLineWidth: 1,
        },
        yAxis: {
          allowDecimals: false,
          crosshair: true,
          lineWidth: 1,
          title: {
            text: null,
            margin: 0,
          },
        },
        legend: {
          // symbolType: 'square',
          itemStyle: {
            fontSize: '10px',
            color: '#7f8fa4',
          },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            if (this.points[0].y || this.points[1].y || this.points[2].y) {
              return `
                <div class="dashboard-graph-tooltip">
                  <div> ${this.points[0].key}</div>
                  <br/>
                  <div> Occupancy Ratios</div>
                  <br/><br/>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>Planned</span>
                    <span>${formatPercent(this.points[0].y, this.locale)}</span>
                  </div>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>Reported by driver</span>
                    <span>${formatPercent(this.points[1].y, this.locale)}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Rider checkins</span>
                    <span>${formatPercent(this.points[2].y, this.locale)}</span>
                  </div>
                </div>
            `;
            }
            return `
                <div class="dashboard-graph-tooltip">
                  <div> ${this.points[0].key}</div>
                  <br/>
                  <div> No active routes on this day</div>
                </div>
            `;
          },
          shared: true,
          // headerFormat: '',
          backgroundColor: '#363647',
          style: {
            color: 'white',
          },
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click(e) {
                  vm.$data.selectedXaxisPoint = {
                    date: e?.point?.category,
                    chartOptionsType: 'fixedChartOptions',
                    type: 'FIXED',
                  };
                },
              },
            },
          },
        },
        series: [
          {
            // type: 'spline',
            name: this.$t('flexigo.routeList.planned'),
            color: '#5667ff',
            data: [],
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#5667ff',
              fillColor: 'white',
            },
          },
          {
            // type: 'spline',
            name: this.$t('flexigo.dashboard.reportedByDriver'),
            color: '#1eb64d',
            data: [],
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#1eb64d',
              fillColor: 'white',
            },
          },
          {
            // type: 'spline',
            name: this.$t('flexigo.dashboard.riderCheckins'),
            color: '#f6b953',
            data: [],
            connectNulls: true,
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#f6b953',
              fillColor: 'white',
              width: 150,
            },
          },
        ],
      },
    };
  },
  computed: {
    locale() {
      return navigator.language;
    },
    groupedRoutesByName() {
      const routesData =
        this.selectedTab === 'Dynamic' ? this.value?.DYNAMIC?.routes ?? [] : this.value?.FIXED?.routes ?? [];
      const grouped = Object.groupBy(routesData, ({ name }) => name);
      Object.keys(grouped).forEach((key) => {
        grouped[key] = grouped[key].map((item) => item.id);
      });
      return grouped;
    },
    groupedRoutesByNameWithSingleId() {
      const routesData =
        this.selectedTab === 'Dynamic' ? this.value?.DYNAMIC?.routes ?? [] : this.value?.FIXED?.routes ?? [];
      const grouped = Object.groupBy(routesData, ({ name }) => name);
      const formatRoutes = [];
      Object.keys(grouped).forEach((key) => {
        formatRoutes.push({
          name: key,
          id: grouped[key][0].id,
          type: grouped[key][0].type,
        });
      });
      return formatRoutes;
    },
  },
  watch: {
    isLoading(value) {
      if (!value) this.prepareGraphData();
    },
    selectedXaxisPoint(value) {
      const dateIndex = this[value.chartOptionsType].xAxis.categories.findIndex((item) => value.date === item);
      const date = format(intToDate(this.value[value.type].data[dateIndex].routeDay), defaults.date);

      const queries = {
        ...this.$route.query,
        ...{
          selectedTab: 'routes',
          date: 'customDate',
          fromDate: date,
          toDate: date,
          workgroupType: 'SHUTTLE',
          workingTypes: value.type,
        },
      };
      const routeData = this.$router.resolve({ name: 'RouteStatuses', query: queries });
      window.open(routeData.href, '_blank');
    },
  },
  mounted() {
    this.prepareGraphData();
    vm = this;
  },
  methods: {
    getPercentage,
    formatPercent,
    selectRoutes(routeId, routeName) {
      this.selectedRouteId = routeId;
      this.$emit('occupancyRefresh', this.groupedRoutesByName[routeName]);
    },
    prepareGraphData() {
      if (this.value && this.value.DYNAMIC.data && this.value.FIXED.data) {
        this.dynamicChartOptions.xAxis.categories = this.value.DYNAMIC.data.map((item) =>
          format(intToDate(item.routeDay), 'dd LLL', { locale: enUS }),
        );
        this.dynamicChartOptions.series[0].data = this.value.DYNAMIC.data.map((item) => item.plannedRatio);
        this.dynamicChartOptions.series[1].data = this.value.DYNAMIC.data.map((item) =>
          getPercentage(item.reportedByDriver, item.capacity),
        );
        this.dynamicChartOptions.series[2].data = this.value.DYNAMIC.data.map((item) => item.riderCheckinsRatio);

        this.fixedChartOptions.xAxis.categories = this.value.FIXED.data.map((item) =>
          format(intToDate(item.routeDay), 'dd LLL', { locale: enUS }),
        );
        this.fixedChartOptions.series[0].data = this.value.FIXED.data.map((item) => item.plannedRatio);
        this.fixedChartOptions.series[1].data = this.value.FIXED.data.map((item) =>
          getPercentage(item.reportedByDriver, item.capacity),
        );
        this.fixedChartOptions.series[2].data = this.value.FIXED.data.map((item) => item.riderCheckinsRatio);
      }
    },
  },
};
</script>
<style scoped>
.custom-border {
  border: 1px solid #e6eaee;
  border-radius: 4px;
}
.out-countainer {
  background-color: white;
}
.container-header {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e6eaee;
}

.number-value {
  font-size: 18px;
  font-weight: 600;
}
.text-value {
  font-size: 12px;
  font-weight: 500;
}
</style>
