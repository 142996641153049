import planner from '../../../endpoints/planner';
import Repository from './repository';

const client = new Repository();

export default {
  async getAddresses(regionId = undefined, filter = undefined) {
    let url = planner.corporate.addresses.list;
    if (filter) {
      url = `${url}/${filter}`;
    }
    const r = await client.get(url, { regionId });
    return r;
  },
  async saveAddress(payload) {
    const r = await client.post(planner.corporate.addresses.list, payload);
    return r;
  },

  async deleteAddress(id) {
    const r = await client.delete(`${planner.corporate.addresses.list}/${id}`);
    return r;
  },

  async getPendingApprovalAddresses() {
    const r = await client.get(`${planner.corporate.addresses.list}/needsVerification`);
    return r;
  },

  async saveImports(payload) {
    const r = await client.post(planner.corporate.addresses.save, payload);
    return r;
  },

  async reverseGeocode(latitude, longitude) {
    const r = await client.get(planner.corporate.addresses.reverseGeocode, { latitude, longitude });
    return r;
  },

  async getVehicles(options = {}) {
    const query = {
      depotId: options?.depotId,
      withDrivers: options.withDrivers || true,
      withFeatures: options.withFeatures || true,
    };
    const r = await client.get(planner.corporate.vehicles, query);
    return r;
  },

  async saveVehicle(payload) {
    const r = await client.post(planner.corporate.vehicles, payload);
    return r;
  },

  async deleteVehicle(vehicleId) {
    const url = `${planner.corporate.vehicles}/${vehicleId}`;
    const r = await client.delete(url);
    return r;
  },

  async getDrivers() {
    const r = await client.get(planner.corporate.drivers);
    return r;
  },
  async getExcludedDrivers(orderId) {
    const r = await client.get(`${planner.corporate.excludedDrivers}?orderId=${orderId}`);
    return r;
  },
  async getExcludedRiders(routeId, driverId) {
    const r = await client.get(`${planner.corporate.excludedRiders}?routeId=${routeId}&driverId=${driverId}`);
    return r;
  },

  async saveDriver(payload) {
    const r = await client.post(planner.corporate.drivers, payload);
    return r;
  },

  async deleteDriver(driverId) {
    const url = `${planner.corporate.drivers}/${driverId}`;
    const r = await client.delete(url);
    return r;
  },

  async saveDriverToVehicle(vehicleId, driverId) {
    const r = await client.post(`${planner.corporate.vehicles}/${vehicleId}/drivers`, driverId);
    return r;
  },

  // async getEvents(routeDay, regionId = undefined, depotId = undefined) {
  //   const r = await client.get(planner.corporate.events, { routeDay, regionId, depotId });
  //   return r;
  // },

  // async updateEvents(payload) {
  //   const r = await client.post(planner.corporate.events, payload);
  //   return r;
  // },

  async deleteEvent(eventId) {
    const r = await client.delete(`${planner.corporate.events}/${eventId}`);
    return r;
  },

  async saveResource(payload) {
    const r = await client.post(planner.corporate.resources, payload);
    return r;
  },

  async deleteResource(resourceId) {
    const r = await client.delete(`${planner.corporate.resources}/${resourceId}`);
    return r;
  },

  async removeGaps(routeId) {
    const r = await client.get(planner.corporate.removeGaps.replace(':routeId', routeId));
    return r;
  },

  async calculateTraffic(routeId) {
    const r = await client.get(planner.corporate.calculateTraffic.replace(':routeId', routeId));
    return r;
  },

  async updateFastestSequence(routeId) {
    const r = await client.get(planner.corporate.updateFastestSequence.replace(':routeId', routeId));
    return r;
  },

  async track(regionId, routeDay = undefined, depotId = undefined) {
    const r = await client.get(planner.corporate.track, { regionId, routeDay, depotId });
    return r;
  },

  async approveDraft(resourceId) {
    const r = await client.get(planner.corporate.approveDraft.replace(':resourceId', resourceId));
    return r;
  },

  async getDepots(id, regionId = null) {
    const url = id ? `${planner.corporate.depots}/${id}` : planner.corporate.depots;
    const r = await client.get(url, { regionId });
    return r;
  },

  async saveDepot(payload) {
    const r = await client.post(planner.corporate.depots, payload);
    return r;
  },

  async deleteDepot(id) {
    const r = await client.delete(`${planner.corporate.depots}/${id}`);
    return r;
  },

  async getRegions() {
    const r = await client.get(planner.corporate.regions);
    return r;
  },

  async saveRegion(name) {
    const r = await client.post(planner.corporate.regions, { name });
    return r;
  },
  async getVehicleInspections(startDay, endDay) {
    const r = await client.get(planner.corporate.vehicleInspections, { startDay, endDay });
    return r;
  },
};
