<template>
  <div class="container-xxl dashboard dashboard-background position-relative">
    <div class="row mb-3">
      <div>
        <h6 class="float-start mt-3 grid-header-font-size title">flexiShuttle</h6>
        <search
          class="float-end mt-2"
          @select="refresh"
        />
      </div>
      <div>
      </div>
    </div>

    <flexi-go-cards :route-data="stats.routeStat" :personel-data="stats.personnelStat" :budget-data="stats.budgetStat" />

    <div class="row">
      <div class="col-4">
        <system-status
          :documents="stats.docStat"
          :user-registration="stats.personnelStat"
          :devices="stats.routeStat"
          :vehicle-age-limit="stats.vehicleAgeLimitStat"
          :driver-age-limit="stats.driverAgeLimitStat"
          :routes="routeStates.routeStates"
          @selected-date="changeSystemStatusData" />
      </div>
      <div class="col-8 mb-3 d-flex flex-column justify-content-between">
        <div class="row">
          <div class="col">
            <occupancy-ratio
              :chart-data="occupancy.personnelHistoryCount"
              :localization="localization"
              @selected-date="changeOccupancyRatioData" />
          </div>
        </div>
        <!-- TODO -->
        <div class="row">
          <div class="col">
            <tickets :campus-id="campusId"/>
          </div>
          <div class="col">
            <tasks :task-data="tasks" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <employee-location :campus-id="campusId"/>
      </div>
    </div>
  </div>
</template>

<script>
import FlexiGoCards from '../../components/dashboard/flexiGoCards.vue';
import Tickets from '../../components/dashboard/tickets.vue';
import SystemStatus from '../../components/dashboard/systemStatus.vue';
import Tasks from '../../components/dashboard/tasks.vue';
import Search from '../../components/campusSearch.vue';
import EmployeeLocation from '../../components/dashboard/employeeLocation.vue';
import OccupancyRatio from '../../components/dashboard/occupancyRatio.vue';
import dashboardService from '../../services/dashboard';

export default {
  name: 'Dashboard',
  components: {
    Search, SystemStatus, Tasks, FlexiGoCards, OccupancyRatio, Tickets, EmployeeLocation,
  },
  data() {
    return {
      stats: {},
      tasks: {},
      occupancy: [],
      routeStates: [],
      districtOccupancy: [],
      campusId: undefined,
      localization: 'en',
    };
  },
  async mounted() {
    // this.$i18n.locale = window.location.href.includes('lang=en') ? 'en' : 'tr';
    this.localization = this.$i18n.locale === 'tr' ? 'tr' : 'en';
    await Promise.all([this.getStats(), this.getTasks(), this.getOccupancy(), this.getRouteStates()]);
  },
  methods: {
    async getStats(options) {
      this.stats = await dashboardService.getStats(options);
    },
    async getTasks(options) {
      this.tasks = await dashboardService.getTaskCount(options);
    },
    async getOccupancy(options) {
      this.occupancy = await dashboardService.getOccupancy(options);
    },
    async getRouteStates(options) {
      this.routeStates = await dashboardService.getRouteStates(options);
    },
    changeOccupancyRatioData(data) {
      this.getOccupancy({ fromDate: data.fromDate, toDate: data.toDate });
    },
    changeSystemStatusData(data) {
      this.getStats({ fromDate: data.fromDate, toDate: data.toDate });
      this.getRouteStates({ fromDate: data.fromDate, toDate: data.toDate });
    },
    async refresh(campus) {
      this.campusId = { campusId: campus?.id };
      await Promise.all([this.getStats(this.campusId), this.getTasks(this.campusId), this.getOccupancy(this.campusId), this.getRouteStates(this.campusId)]);
    },
  },
};
</script>

<style>

</style>
