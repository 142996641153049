var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card text-dark h-100"},[_c('div',{staticClass:"card-header dashboard position-relative title-style big"},[_vm._v(" "+_vm._s(_vm.$t('flexigo.dashboard.tickets'))+" "),_c('div',{staticClass:"position-absolute top-0 end-0"},[_c('date',{staticClass:"mt-2 me-2",on:{"date-changed":_vm.onChange},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)]),_c('div',{staticClass:"tickets"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col ticket-table"},[_c('table',{staticClass:"table"},[_c('thead',{staticClass:"table-header-size"},[_c('tr',{staticClass:"border-bottom-none"},[_c('th',{staticClass:"ps-3 pointer",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('flexigo.common.date')))]),_c('th',{staticClass:"text-center pointer down-arrow-after",attrs:{"scope":"col"},on:{"click":function($event){return _vm.sort('openTickets')}}},[_vm._v(" "+_vm._s(_vm.$t('flexigo.common.open'))+" ")]),_c('th',{staticClass:"text-center pointer down-arrow-after",attrs:{"scope":"col"},on:{"click":function($event){return _vm.sort('closedTickets')}}},[_vm._v(" "+_vm._s(_vm.$t('flexigo.common.closed'))+" ")])])]),(_vm.tickets.length)?[_c('tbody',{staticClass:"title-style border-top-0"},_vm._l((_vm.tickets),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"ps-3"},[_c('div',{staticClass:"stepper-circle me-1",class:{
                      red: item.openTickets,
                      green: !item.openTickets,
                    }},[(!item.openTickets)?_c('img',{staticClass:"path-icon mr-1",attrs:{"src":`${_vm.publicPath}icons/dashboard/path.svg`}}):_vm._e(),_c('div',{staticClass:"dashed-line",class:{
                        'dashed-style': item.open,
                        'dashed-line-margin-top': item.openTickets,
                      }})]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.ticketDay))])]),_c('td',{staticClass:"text-center text-decoration-underline"},[_vm._v(_vm._s(item.openTickets))]),_c('td',{staticClass:"text-center text-decoration-underline"},[_vm._v(_vm._s(item.closedTickets))])])}),0),_c('tfoot',[_c('tr',[_c('td',{staticClass:"ps-3"},[_vm._v(_vm._s(_vm.$t('flexigo.common.total')))]),_c('td',{staticClass:"text-center text-decoration-underline"},[_vm._v(_vm._s(_vm.totalOpen))]),_c('td',{staticClass:"text-center text-decoration-underline"},[_vm._v(_vm._s(_vm.totalClosed))])])])]:[_c('div',{staticClass:"border-0 text-center"},[_c('div',{staticClass:"mt-3 empty-ticket-table"},[_c('img',{staticClass:"w-50",attrs:{"src":`${_vm.publicPath}/img/empty-support-requests.svg`}})]),_c('div',{staticClass:"metric-style header-size mb-3 mt-3"},[_vm._v(_vm._s(_vm.$t('flexigo.employeeList.noSupportRequests')))])])]],2),_c('div',{staticClass:"card-footer bg-white text-muted text-center border-0"},[_c('a',{staticClass:"link-style",attrs:{"target":"_parent","href":_vm.getLink(_vm.$i18n.locale, '#/app/route/info')}},[_vm._v(_vm._s(_vm.$t('flexigo.common.viewAll'))+" >")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }