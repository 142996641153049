<template>
  <div class="card text-dark h-100">
    <div class="card-header dashboard title-style big">{{$t('flexigo.dashboard.pendingRequests')}}</div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <user-info
            :subtitle="this.$t('flexigo.dashboard.routeChange')"
            :amount="routeChange"
            :link="this.$router.resolve({ name: 'requestApprovalsGrid',  query: { tab: 'routeChange', lang: this.$i18n.locale } }).href" />
        </div>
        <div class="col">
          <user-info
            :subtitle="this.$t('flexigo.dashboard.reservation')"
            :amount="0"
            :link="getLink($i18n.locale, '#/requestApprovals/requestApprovalsGrid')" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <user-info
            :subtitle="this.$t('flexigo.dashboard.stops')"
            :amount="0"
            :link="this.$router.resolve({ name: 'requestApprovalsGrid',  query: { tab: 'nearbyStop', lang: this.$i18n.locale } }).href" />
        </div>
        <div class="col">
          <user-info
            :subtitle="this.$t('flexigo.dashboard.tasksDocuments')"
            :amount="documents"
            :link="this.$router.resolve({ name: 'requestApprovalsGrid',  query: { tab: 'documents', lang: this.$i18n.locale } }).href" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <user-info
            :subtitle="this.$t('flexigo.dashboard.progressPayment')"
            :amount="progressPayment"
            :link="this.$router.resolve({ name: 'requestApprovalsGrid',  query: { tab: 'payments', lang: this.$i18n.locale } }).href" />
        </div>
        <div class="col">
          <user-info
            :subtitle="this.$t('flexigo.dashboard.driversVehicles')"
            :amount="driversVehicles"
            :link="this.$router.resolve({ name: 'requestApprovalsGrid',  query: { tab: 'vehicleDriverChange', lang: this.$i18n.locale } }).href" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLink } from '@/helpers/utility';
import UserInfo from '../userInfo/userInfo.vue';

export default {
  name: 'Tasks',
  components: { UserInfo },
  props: {
    taskData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    routeChange() {
      return this.taskData.routeApprover || 0;
    },
    driversVehicles() {
      return this.taskData.routeDefinitionApprover || 0;
    },
    documents() {
      return this.taskData.documentApprover || 0;
    },
    progressPayment() {
      return this.taskData.hakedisStarter || 0;
    },
  },
  methods: {
    getLink,
  },
};
</script>

<style>

</style>
