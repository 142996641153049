<template>
  <div class="dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      {{ $t(`flexigo.date.${selectedItem}`) }}
    </button>
    <ul class="dropdown-menu">
      <li v-for="row in dropdownData" :key="row">
        <a class="dropdown-item" href="#" @click.prevent="onLinkClick(row)">{{ $t(`flexigo.date.${row}`) }}</a>
      </li>
    </ul>
  </div>
  <!-- <div class="btn-group">
    <dropdown
      v-model="selectedItem"
      :data="dropdownData"
      :border="border"
      :localization="'flexigo.date'"
    />
  </div> -->
</template>

<script>
import {
  format,
  subDays,
  subMonths,
  lastDayOfMonth,
  startOfMonth,
  startOfWeek,
  addDays,
  endOfWeek,
  addMonths,
} from 'date-fns';

export default {
  props: {
    value: {
      type: String,
    },
    // TODO: Incelenecek
    border: {
      type: Boolean,
      default: true,
    },
    newDays: {
      type: Array,
    },
  },
  data() {
    return {
      selectedItem: 'today',
      clickedItem: undefined,
      days: [
        'today',
        'yesterday',
        'thisMonth',
        'lastSevenDays',
        'lastMonth',
        'lastThirtyDays',
        'thisWeek',
        'customDate',
      ],
    };
  },
  computed: {
    dropdownData() {
      if (this.newDays) {
        return this.newDays;
      }
      return this.days;
    },
  },
  watch: {
    clickedItem(newValue) {
      console.log(newValue);
      this.selectedItem = newValue;
    },
    selectedItem(newValue) {
      const currentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const formatted = {
        fromDate: undefined,
        toDate: undefined,
      };
      switch (newValue) {
        case 'today':
          formatted.fromDate = format(new Date(), 'yyyy-MM-dd');
          formatted.toDate = format(new Date(), 'yyyy-MM-dd');
          break;
        case 'yesterday':
          formatted.fromDate = format(subDays(new Date(), 1), 'yyyy-MM-dd');
          formatted.toDate = format(subDays(new Date(), 1), 'yyyy-MM-dd');
          break;
        case 'lastThirtyDays':
          formatted.fromDate = format(subDays(new Date(), 29), 'yyyy-MM-dd');
          formatted.toDate = format(new Date(), 'yyyy-MM-dd');
          break;
        case 'lastSevenDays':
          formatted.fromDate = format(subDays(new Date(), 6), 'yyyy-MM-dd');
          formatted.toDate = format(new Date(), 'yyyy-MM-dd');
          break;
        case 'thisMonth':
          formatted.fromDate = format(currentMonth, 'yyyy-MM-dd');
          formatted.toDate = format(lastDayOfMonth(new Date()), 'yyyy-MM-dd');
          break;
        case 'lastMonth':
          formatted.fromDate = format(startOfMonth(subMonths(new Date(new Date()), 1)), 'yyyy-MM-dd');
          formatted.toDate = format(lastDayOfMonth(subMonths(new Date(new Date()), 1)), 'yyyy-MM-dd');
          break;
        case 'thisWeek':
          formatted.fromDate = format(addDays(startOfWeek(new Date()), 1), 'yyyy-MM-dd');
          formatted.toDate = format(addDays(endOfWeek(new Date()), 1), 'yyyy-MM-dd');
          break;
        case 'nextWeek':
          formatted.fromDate = format(addDays(startOfWeek(new Date()), 8), 'yyyy-MM-dd');
          formatted.toDate = format(addDays(endOfWeek(new Date()), 8), 'yyyy-MM-dd');
          break;
        case 'nextMonth':
          formatted.fromDate = format(startOfMonth(addMonths(new Date(new Date()), 1)), 'yyyy-MM-dd');
          formatted.toDate = format(lastDayOfMonth(addMonths(new Date(new Date()), 1)), 'yyyy-MM-dd');
          break;
        case 'customDate':
          formatted.fromDate = format(new Date(), 'yyyy-MM-dd');
          formatted.toDate = format(new Date(), 'yyyy-MM-dd');
          this.$emit('show-custom-range', true);
          break;
        default:
      }
      this.$emit('input', newValue);

      if (newValue !== 'customDate') {
        this.$emit('date-changed', formatted);
        this.$emit('show-custom-range', false);
      }
    },
  },
  mounted() {
    this.selectedItem = this.value ?? 'today';
  },
  methods: {
    onLinkClick(row) {
      console.log(row);
      this.selectedItem = row;
    },
  },
};
</script>

<style></style>
