<template>
  <div class="card text-dark h-100">
    <div class="card-header dashboard position-relative title-style big">
      {{ $t('flexigo.dashboard.tickets') }}
      <div class="position-absolute top-0 end-0">
        <date v-model="selectedDate" class="mt-2 me-2" @date-changed="onChange" />
      </div>
    </div>
    <div class="tickets">
      <div class="card-body">
        <div class="col ticket-table">
          <table class="table">
            <thead class="table-header-size">
              <tr class="border-bottom-none">
                <th scope="col" class="ps-3 pointer">{{ $t('flexigo.common.date') }}</th>
                <th scope="col" class="text-center pointer down-arrow-after" @click="sort('openTickets')">
                  {{ $t('flexigo.common.open') }}
                </th>
                <th scope="col" class="text-center pointer down-arrow-after" @click="sort('closedTickets')">
                  {{ $t('flexigo.common.closed') }}
                </th>
              </tr>
            </thead>
            <template v-if="tickets.length">
              <tbody class="title-style border-top-0">
                <tr v-for="(item, index) in tickets" :key="index">
                  <td class="ps-3">
                    <div
                      class="stepper-circle me-1"
                      :class="{
                        red: item.openTickets,
                        green: !item.openTickets,
                      }"
                    >
                      <img
                        v-if="!item.openTickets"
                        class="path-icon mr-1"
                        :src="`${publicPath}icons/dashboard/path.svg`"
                      />
                      <div
                        class="dashed-line"
                        :class="{
                          'dashed-style': item.open,
                          'dashed-line-margin-top': item.openTickets,
                        }"
                      ></div>
                    </div>
                    <div class="date">{{ item.ticketDay }}</div>
                  </td>
                  <td class="text-center text-decoration-underline">{{ item.openTickets }}</td>
                  <td class="text-center text-decoration-underline">{{ item.closedTickets }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="ps-3">{{ $t('flexigo.common.total') }}</td>
                  <td class="text-center text-decoration-underline">{{ totalOpen }}</td>
                  <td class="text-center text-decoration-underline">{{ totalClosed }}</td>
                </tr>
              </tfoot>
            </template>
            <template v-else>
              <div class="border-0 text-center">
                <div class="mt-3 empty-ticket-table">
                  <img class="w-50" :src="`${publicPath}/img/empty-support-requests.svg`" />
                </div>
                <div class="metric-style header-size mb-3 mt-3">{{ $t('flexigo.employeeList.noSupportRequests') }}</div>
              </div>
            </template>
          </table>
          <div class="card-footer bg-white text-muted text-center border-0">
            <a target="_parent" :href="getLink($i18n.locale, '#/app/route/info')" class="link-style"
              >{{ $t('flexigo.common.viewAll') }} ></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { getLink } from '@/helpers/utility';
import { intToDate } from '@/helpers/dateTime';
import Date from '../date.vue';
import dashboardService from '../../services/dashboard';

export default {
  name: 'dashboard-tickets',
  components: { Date },
  props: {
    campusId: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ticketColumns: [
        { headerName: this.$t('flexigo.common.date'), field: 'date' },
        { headerName: this.$t('flexigo.common.open'), field: 'open' },
        { headerName: this.$t('flexigo.common.closed'), field: 'closed' },
      ],
      selectedDate: 'lastSevenDays',
      tickets: [],
      fromDate: undefined,
      toDate: undefined,
    };
  },
  computed: {
    totalOpen() {
      return (
        this.tickets &&
        this.tickets.reduce((acc, current) => {
          acc += current.openTickets; // eslint-disable-line
          return acc;
        }, 0)
      );
    },
    totalClosed() {
      return (
        this.tickets &&
        this.tickets.reduce((acc, current) => {
          acc += current.closedTickets; // eslint-disable-line
          return acc;
        }, 0)
      );
    },
  },
  watch: {
    campusId() {
      this.getTickets({ fromDate: this.fromDate, toDate: this.toDate, campusId: this.campusId.campusId });
    },
  },
  methods: {
    getLink,
    sort(payload) {
      this.tickets.sort((a, b) => (this[payload] ? b[payload] - a[payload] : a[payload] - b[payload]));
      this[payload] = !this[payload];
    },
    async getTickets(options) {
      const response = await dashboardService.getTickets(options);
      this.tickets = response.ticketStat
        .map((el) => {
          const date = intToDate(el.ticketDay);
          const monthName = format(date, 'LLLL', { locale: this.$i18n.locale === 'tr' ? tr : undefined });
          return {
            ...el,
            ticketDay: `${monthName} ${date.getDate()}`,
          };
        })
        .filter((item) => item.openTickets || item.closedTickets);
    },
    onChange(e) {
      this.fromDate = e.fromDate;
      this.toDate = e.toDate;
      this.getTickets({ fromDate: e.fromDate, toDate: e.toDate });
    },
  },
};
</script>

<style scoped lang="scss">
.tickets {
  .card-body {
    padding: 0;
  }
  .table {
    margin: 0;
  }
  .table > :not(caption) > * > * {
    padding: 0.6rem 0;
  }
  thead,
  tbody,
  tfoot,
  tr,
  td {
    border-style: ridge;
  }
}
</style>
