export const eventColor = {
  AWAITING_PLANNING: '#FFE892',
  // CHANGE_REQUEST: '#FC9B45',
  CHANGE_REQUEST: '#F3F4F5',
  PLANNED: '#FF0000',
  ASSIGNED: '#f8c200',
  ACTIVE: '#5667ff',
  ENROUTE_TO_PICKUP: '#73d05a',
  AWAITING_PICKUP: '#16c5d3',
  ENROUTE_TO_DROPOFF: '#161dd3',
  AWAITING_DROPOFF: '#b67ae3',
  COMPLETED: '#535862',
  // CHANGE_REQUEST_CANCELLED: '#B9C4CF',
  CANCELLED: '#9DA7B1',
  NOT_STARTED: '#e04659',
  REQUESTED: '#735ae1',
};

export const eventTypes = Object.keys(eventColor);

export const filterColors = {
  ON_TIME: '#1eb64d',
  NOT_STARTED: '#f5b548',
  DELAYED: '#e04659',
};
