<template>
  <div class="card text-dark mb-3">
    <div class="card-header dashboard title-style big">
      {{$t('flexigo.dashboard.systemStatus')}}
    </div>

    <div class="card-body">
      <div class="row">
        <h6  class="title-style float-start">{{$t('flexigo.common.routes')}}</h6>
        <div class="col position-absolute end-0" style="margin-top: -15px">
          <date
            v-model="selectedDate"
            class="float-end"
            @date-changed="onChange"/>
        </div>
        <div class="row ms-0">
          <toggle-button v-model="toggleButtonValue"  :first-label="$t('flexigo.dashboard.toCampus')" :second-label="$t('flexigo.dashboard.fromCampus')" />
        </div>
      </div>

      <div v-if="toggleButtonValue">
        <div class="row">
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.onTimeArrival')"
              :amount="onTimeArrivalToCampus"
              :link="getLink('app.route.instance.*', 'status=ARRIVED_ON_TIME')" />
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.noArrival')"
              :amount="noArrivalToCampus"
              :link="getLink('app.route.instance.*', 'status=NOT_ARRIVED')" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.lateArrival')"
              :amount="lateArrivalToCampus"
              :link="getLink('app.route.instance.*', 'status=ARRIVED_LATE')" />
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.deviceProblem')"
              :amount="deviceProblemToCampus"
              :link="getLink('app.route.instance.*', 'status=DEVICE_NOT_OK')" />
          </div>
        </div>
        <div class="row">
          <h6  class="title-style float-start">{{$t('flexigo.dashboard.devices')}}</h6>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.availableDevice')"
              :amount="availableDevice"/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.notAvailableDevice')"
              :amount="notAvailableDevice"/>
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.noSignal')"
              :amount="noSignal"/>
          </div>
        </div>
        <div class="row">
          <h6 class="title-style float-start">{{$t('flexigo.dashboard.userRegistration')}}</h6>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.undefinedRoute')"
              :amount="undefinedRoute"
              :color="'#f5b548'"/>
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.addressMissing')"
              :amount="addressMissing"
              :color="'#f5b548'"/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.notUsingShuttle')"
              :amount="notUsingShuttle"/>
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.completed')"
              :amount="completed"/>
          </div>
        </div>
        <div class="route-list-navbar bg-white has-shadow border-0 m-0">
          <ul class="badge-icon">
            <li>
              <a href="#" :class="{active: selectedTab === $t('flexigo.dashboard.vehicles')}" @click.prevent="selectedTab = $t('flexigo.dashboard.vehicles')">
                {{$t('flexigo.dashboard.vehicles')}}
                <span v-if="vehicleAgeLimit" class="red-dot" />
              </a>
            </li>
            <li>
              <a href="#" :class="{active: selectedTab === $t('flexigo.dashboard.drivers')}" @click.prevent="selectedTab = $t('flexigo.dashboard.drivers')">
                {{$t('flexigo.dashboard.drivers')}}
                <span v-if="driverAgeLimit" class="red-dot" />
              </a>
            </li>
            <li>
              <a href="#" :class="{active: selectedTab === $t('flexigo.dashboard.documents')}" @click.prevent="selectedTab = $t('flexigo.dashboard.documents')">
                {{$t('flexigo.dashboard.documents')}}
                <span v-if="missingCount" class="red-dot" />
              </a>
            </li>
          </ul>
        </div>

        <div class="system-status-seperator" />

        <user-info
          v-if="selectedTab === $t('flexigo.dashboard.vehicles')"
          :subtitle="$t('flexigo.dashboard.overTheAgeLimit')"
          :amount="vehicleAgeLimit"
          :link="getLink('app.route.vehicles', 'overAgeLimit=true')"
        />
        <user-info
          v-if="selectedTab === $t('flexigo.dashboard.drivers')"
          :subtitle="$t('flexigo.dashboard.overTheAgeLimit')"
          :amount="driverAgeLimit"
          :link="getLink('app.route.drivers', 'overAgeLimit=true')"
        />
        <user-info
          v-if="selectedTab === $t('flexigo.dashboard.documents')"
          :subtitle="this.$t('flexigo.dashboard.missing')"
          :amount="missingCount"
          :link="getLink('app.route.docs')"
        />
      </div>

      <div v-if="!toggleButtonValue">
        <div class="row">
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.leaveOnTime')"
              :amount="leaveOnTimeFromCampus"
              :link="getLink('app.route.instance.*', 'status=LEAVE_ON_TIME')" />
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.readyToLeave')"
              :amount="readyToLeaveFromCampus"
              :link="getLink('app.route.instance.*', 'status=READY_TO_LEAVE')" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.leaveEarly')"
              :amount="leaveEarlyToCampus"
              :link="getLink('app.route.instance.*', 'status=LEAVE_EARLY')" />
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.deviceProblem')"
              :amount="deviceProblemFromCampus"
              :link="getLink('app.route.instance.*', 'status=DEVICE_NOT_OK')" />
          </div>
        </div>
        <div class="row">
          <h6  class="title-style float-start">{{$t('flexigo.dashboard.devices')}}</h6>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.availableDevice')"
              :amount="availableDevice"/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.notAvailableDevice')"
              :amount="notAvailableDevice"/>
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.noSignal')"
              :amount="noSignal"/>
          </div>
        </div>
        <div class="row">
          <h6 class="title-style float-start">{{$t('flexigo.dashboard.userRegistration')}}</h6>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.undefinedRoute')"
              :amount="undefinedRoute"
              :color="'#f5b548'"/>
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.addressMissing')"
              :amount="addressMissing"
              :color="'#f5b548'"/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.notUsingShuttle')"
              :amount="notUsingShuttle"/>
          </div>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.completed')"
              :amount="completed"/>
          </div>
        </div>
        <div class="row">
          <h6 class="title-style float-start">{{$t('flexigo.dashboard.documents')}}</h6>
          <div class="col">
            <user-info
              :subtitle="this.$t('flexigo.dashboard.missing')"
              :amount="missingCount"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import UserInfo from '../userInfo/userInfo.vue';
import ToggleButton from '../toggleButton/toggleButton.vue';
import Date from '../date.vue';

export default {
  name: 'SystemStatus',
  components: { UserInfo, ToggleButton, Date },
  props: {
    documents: {},
    userRegistration: {},
    devices: {},
    routes: {},
    vehicleAgeLimit: {
      type: Number,
      default: 0,
    },
    driverAgeLimit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedDate: undefined,
      toggleButtonValue: true,
      tabName: 'toCampus',
      selectedTab: this.$t('flexigo.dashboard.vehicles'),
    };
  },
  computed: {
    // USER REGISTRATION
    missingCount() {
      return this.documents ? this.documents.reduce((acc, current) => (acc + current.state === 'MISSING' ? current.count : 0), 0) : 0;
    },
    undefinedRoute() {
      return this.userRegistration && this.userRegistration.find((item) => (item.state === 'NO_ROUTE')) ? this.userRegistration.find((item) => (item.state === 'NO_ROUTE')).count : 0;
    },
    addressMissing() {
      return this.userRegistration && this.userRegistration.find((item) => (item.state === 'NO_HOME')) ? this.userRegistration.find((item) => (item.state === 'NO_HOME')).count : 0;
    },
    notUsingShuttle() {
      return this.userRegistration && this.userRegistration.find((item) => (item.state === 'NOT_USING_ROUTE')) ? this.userRegistration.find((item) => (item.state === 'NOT_USING_ROUTE')).count : 0;
    },
    completed() {
      return this.userRegistration && this.userRegistration.find((item) => (item.state === 'DEFINITION_OK')) ? this.userRegistration.find((item) => (item.state === 'DEFINITION_OK')).count : 0;
    },

    // DEVICES
    availableDevice() {
      return this.devices ? this.devices[0].deviceOk : 0;
    },
    notAvailableDevice() {
      return this.devices ? this.devices[0].noDevice : 0;
    },
    noSignal() {
      return this.devices ? this.devices[0].noSignal : 0;
    },

    // ROUTES
    onTimeArrivalToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'ARRIVED_ON_TIME' && item.routeDirection === 0) ? this.routes.find((item) => item.state === 'ARRIVED_ON_TIME' && item.routeDirection === 0).count : 0;
    },
    leaveOnTimeFromCampus() {
      return this.routes && this.routes.find((item) => item.state === 'LEAVE_ON_TIME' && item.routeDirection === 1) ? this.routes.find((item) => item.state === 'LEAVE_ON_TIME' && item.routeDirection === 1).count : 0;
    },
    noArrivalToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'NOT_ARRIVED' && item.routeDirection === 0) ? this.routes.find((item) => item.state === 'NOT_ARRIVED' && item.routeDirection === 0).count : 0;
    },
    readyToLeaveFromCampus() {
      return this.routes && this.routes.find((item) => item.state === 'READY_TO_LEAVE' && item.routeDirection === 1) ? this.routes.find((item) => item.state === 'READY_TO_LEAVE' && item.routeDirection === 1).count : 0;
    },
    lateArrivalToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'ARRIVED_LATE' && item.routeDirection === 0) ? this.routes.find((item) => item.state === 'ARRIVED_LATE' && item.routeDirection === 0).count : 0;
    },
    leaveEarlyToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'LEAVE_EARLY' && item.routeDirection === 1) ? this.routes.find((item) => item.state === 'LEAVE_EARLY' && item.routeDirection === 1).count : 0;
    },
    deviceProblemToCampus() {
      return this.routes && this.routes.find((item) => item.state === 'DEVICE_NOT_OK' && item.routeDirection === 0) ? this.routes.find((item) => item.state === 'DEVICE_NOT_OK' && item.routeDirection === 0).count : 0;
    },
    deviceProblemFromCampus() {
      return this.routes && this.routes.find((item) => item.state === 'DEVICE_NOT_OK' && item.routeDirection === 1) ? this.routes.find((item) => item.state === 'DEVICE_NOT_OK' && item.routeDirection === 1).count : 0;
    },
  },
  methods: {
    getLink(route, query = '') {
      const link = this.$router.resolve({
        name: 'legacy',
        params: { route },
      }).href;
      return `${link}${query ? `?${query}` : ''}`;
    },
    onChange(payload) {
      this.$emit('selected-date', payload);
    },
  },
};
</script>

<style>

</style>
