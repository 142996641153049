<template>
  <div class="h-100">
    <div class="out-countainer custom-border h-100">
      <div class="container-header p-3"> {{ this.$t('flexigo.dashboard.rideRequests') }}</div>
      <div class="container-content p-3">
        <h6 class="mb-3" style=" font-weight: 600; ">{{ this.$t('flexigo.workgroup.dynamic') }}</h6>
        <div class="row mb-2">
          <div class="col pe-1">
            <div class="p-4 custom-border text-center" :class="{'has-cursor-pointer': !!value.dynamicReceived}"
                 @click="!!value.dynamicReceived ? direct({ workingTypes: 'DYNAMIC', requestType:'REQUESTED'}) : {}">
              <div class="number-value"><u>{{value.dynamicReceived}}</u></div>
              <div class="text-value">{{ this.$t('flexigo.employeeList.received') }}</div>
            </div>
          </div>
          <div class="col ps-1">
            <div class="p-4 custom-border text-center" :class="{'has-cursor-pointer': !!value.dynamicReserved}"
                 @click="!!value.dynamicReserved ? direct({ workingTypes: 'DYNAMIC', requestType:'RESERVED'}) : {}">
              <div class="number-value"><u>{{value.dynamicReserved}}</u></div>
              <div class="text-value">{{ this.$t('flexigo.dashboard.reserved') }}</div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col pe-1">
            <div class="p-4 custom-border text-center" :class="{'has-cursor-pointer': !!value.dynamicFailed}"
                 @click="!!value.dynamicFailed ? direct({ workingTypes: 'DYNAMIC', requestType:'FAILED'}) : {}">
              <div class="number-value"><u>{{value.dynamicFailed}}</u></div>
              <div class="text-value">{{ this.$t('flexigo.dashboard.failed') }}</div>
            </div>
          </div>
          <div class="col ps-1">
            <div class="p-4 custom-border text-center" :class="{'has-cursor-pointer': !!value.dynamicCanceled}"
                 @click="!!value.dynamicCanceled ? direct({ workingTypes: 'DYNAMIC', requestType:'CANCELLED'}) : {}">
              <div class="number-value"><u>{{value.dynamicCanceled}}</u></div>
              <div class="text-value">{{ this.$t('flexigo.dashboard.canceled') }}</div>
            </div>
          </div>
        </div>
        <h6 class="mb-3" style=" font-weight: 600; ">{{ this.$t('flexigo.workgroup.fixed') }}</h6>
        <div class="row">
          <div class="col pe-1">
            <div class="p-4 custom-border text-center" :class="{'has-cursor-pointer': !!value.fixedReserved}"
                 @click="!!value.fixedReserved ? direct({ workingTypes: 'FIXED', requestType:'RESERVED,REGULAR'}) : {}">
              <div class="number-value"><u>{{value.fixedReserved}}</u></div>
              <div class="text-value">{{ this.$t('flexigo.dashboard.reserved') }}</div>
            </div>
          </div>
          <div class="col ps-1">
            <div class="p-4 custom-border text-center" :class="{'has-cursor-pointer': !!value.fixedCanceled}"
                 @click="!!value.fixedCanceled ? direct({ workingTypes: 'FIXED', requestType:'CANCELLED'}) : {}">
              <div class="number-value"><u>{{value.fixedCanceled}}</u></div>
              <div class="text-value">{{ this.$t('flexigo.dashboard.canceled') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RideRequests',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    direct(options) {
      const queries = { ...this.$route.query, ...options, ...{ workgroupType: 'SHUTTLE' } };
      const routeData = this.$router.resolve({ name: 'Reservations', params: { type: 'SHUTTLE' }, query: queries });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style scoped>

.custom-border{
   border: 1px solid #e6eaee;
   border-radius: 4px;
}
.out-countainer{
   background-color: white;
}
.container-header{
   font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e6eaee;
}

.number-value{
  font-size: 18px;
  font-weight: 600;
}
.text-value{
  font-size: 12px;
  font-weight: 500;
}
</style>
