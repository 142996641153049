<template>
  <div class="side-navigation" :class="{ visible: isSideMenuVisible }">
    <div class="side-navigation-close" @click="toggleSideMenu">&#x2715;</div>
    <div class="side-navigation-logo d-none d-md-block">
      <img
        :src="`${baseUrl}/img/flexigo-logo-white.png`"
        :srcset="`${baseUrl}/img/flexigo-logo-white.png, ${baseUrl}/img/flexigo-logo-white@2x.png, ${baseUrl}/img/flexigo-logo-white@3x.png`"
        alt="Flexigo"
      />
    </div>

    <!-- mobile only logout link -->
    <div class="side-navigation-logout d-md-none">
      {{ fullName }} <br>
      <a class="" href="#" @click.prevent="logout">{{ $t('flexigo.employee.dashboard.signOut') }}</a>
      </div>

    <ul class="side-navigation-menu-items">
      <li v-for="item in items.children" :key="`${generateFastRandomString(8)}-${item.text}`">
        <a v-if="item.children" class="collapsed" href="" @click.prevent="onParentClick">
          <span class="icon">
            <img
              :src="getIcon(item.data ? item.data.icon : undefined)"
              :alt="getIcon(item.data ? item.data.icon : undefined)"
            />
          </span>
          <span class="menu-label">
            {{ item.text }}

            <svg width="14px" height="8px" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg" class="menu-arrow">
              <polyline
                fill="none"
                stroke="#FFFFFF"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
                points="1 7, 7 1, 13 7"
              />
            </svg>
          </span>
        </a>

        <router-link v-else :to="getLink(item)" @click.native="onLinkClick">
          <span class="icon">
            <img
              :src="getIcon(item.data ? item.data.icon : undefined)"
              :alt="getIcon(item.data ? item.data.icon : undefined)"
            />
          </span>
          <span class="menu-label">{{ item.text }}</span>
        </router-link>

        <ul v-if="item.children" class="child-items second-level">
          <li v-for="c in item.children" :key="`${generateFastRandomString(8)}-${c.text}`">
            <router-link :to="getLink(c)">
              <span class="menu-label">{{ c.text }}</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { generateFastRandomString } from '@/helpers/utility';
import auth from '@/modules/Flexigo/services/auth';

export default {
  name: 'SideMenu',
  data() {
    return {
      baseUrl: process.env.BASE_URL,
    };
  },
  computed: {
    items() {
      return this.$store.state.mainMenu;
    },
    isSideMenuVisible() {
      return this.$store.state.isSideMenuVisible;
    },
    fullName() {
      return this.$store.getters.userDetails.fullName;
    },
    email() {
      return this.$store.getters.userDetails.email;
    },
  },
  async mounted() {
    console.log('SideMenu mounted');
  },
  methods: {
    generateFastRandomString,
    getIcon(iconName) {
      if (iconName) {
        return `${this.baseUrl}menu/${iconName}.svg`;
      }
      return `${this.baseUrl}menu/icon-flexicar.svg`;
    },
    // getIconSet(iconName) {
    //   if (iconName) {
    //     return `${this.baseUrl}menu/${iconName}.png, ${this.baseUrl}menu/${iconName}@2x.png, ${this.baseUrl}menu/${iconName}@3x.png`;
    //   }
    //   return '';
    // },
    getLink(link) {
      if (link.data?.legacyRoute) {
        return { name: 'legacy', params: { route: link.data.legacyRoute } };
      }
      const route = { name: link.route };
      if (link.data?.typeParam) {
        route.params = { type: link.data.typeParam };
      }
      return route;
    },
    onParentClick($event) {
      $event.preventDefault();
      $event.currentTarget.classList.toggle('collapsed');
    },
    onLinkClick() {
      this.$store.dispatch('SET_IS_SIDE_MENU_VISIBLE', false);
    },
    toggleSideMenu() {
      this.$store.dispatch('toggleSideMenu');
    },
    // this is copied from topbar.vue
    // need to refactor this to a common place
    async logout() {
      try {
        const [base] = window.location.hostname.split('.');
        const urls = {
          localhost: 'http://localhost:8080/admin/flexigo/#/login',
          secure: 'https://secure.flexigo.com?lang=tr',
          portal: 'https://portal.flexigo.com?lang=en',
          secure3: 'https://secure3.vektorteknoloji.com/flexigo/',
          timezone: 'https://timezone.flexigo.com',
        };
        await auth.logout();
        sessionStorage.clear();
        window.location.href = urls[base];
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.side-navigation-logout{
  padding: 0;
  margin: 64px 20px -30px 40px;
  color: white
}
</style>
