import Vue from 'vue';

const state = Vue.observable({
  type: 'alert',
  active: false,
  message: '',
  title: '',
  textOk: 'Ok',
  textCancel: 'Cancel',
  inputType: 'text',
  inputRequired: false,
  html: false,
  userInput: '',
});

// -----------------------------------
// Private Methods
// -----------------------------------
let close; // will hold our promise resolve function
const dialogPromise = () => new Promise((resolve) => {
  close = resolve;
});

const open = (message) => {
  state.message = message;
  state.active = true;
  return dialogPromise();
};

const reset = () => {
  state.type = 'alert';
  state.active = false;
  state.message = '';
  state.title = '';
  state.textOk = 'Ok';
  state.textCancel = 'Cancel';
  state.inputType = 'text';
  state.inputRequired = false;
  state.html = false;
  state.userInput = '';
};

// -----------------------------------
// Public interface
// -----------------------------------
const dialog = {
  get state() {
    return state;
  },

  title(title) {
    state.title = title;
    return this;
  },

  textOk(text) {
    state.textOk = text;
    return this;
  },

  textCancel(text) {
    state.textCancel = text;
    return this;
  },

  inputType(type) {
    state.inputType = type;
    return this;
  },

  html(enabled = true) {
    state.html = enabled;
    return this;
  },

  inputRequired(enabled = true) {
    state.inputRequired = enabled;
    return this;
  },

  alert(message) {
    state.type = 'alert';
    return open(message);
  },

  confirm(message) {
    state.type = 'confirm';
    return open(message);
  },

  prompt(message) {
    state.type = 'prompt';
    return open(message);
  },

  cancel() {
    close(false);
    reset();
  },

  ok(input = true) {
    input = state.type === 'prompt' ? input : true; // eslint-disable-line no-param-reassign
    close(input);
    reset();
  },
  userInput(inputText) {
    state.userInput = inputText;
    return this;
  },
};

export default dialog;
