export const approvalTypes = [
  'NONE',
  'VANPOOL_DRIVER',
  'VANPOOL_USER',
  'DRIVER_AND_USER',
];

export const DELAY_PER_RIDER_IN_SECONDS = 30;

export const ACKNOWLEDGE_GAP_MIN = 120;

export const WALKING_DISTANCE_TRESHOLD = 500; // meters

export default approvalTypes;
