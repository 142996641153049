<template>
  <div>
    <input
      v-model="inputValue"
      type="text"
      class="bold-500 outer-border rounded has-cursor-pointer p-3 w-100"
      :class="{ 'is-invalid': isInvalid }"
      name="email"
      :placeholder="placeholder"
      required
    />
    <p v-if="showError" class="invalid-feedback">
      {{ $t('flexigo.login.validEmail') }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'emailInput',
  model: {
    prop: 'email',
    event: 'change',
  },
  props: {
    email: {
      type: [String, Object],
      required: false,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('flexigo.login.email');
      },
      required: false,
    },
  },
  data() {
    return {
      inputValue: null,
    };
  },
  computed: {
    isInvalid() {
      return !this.validateEmail(this.inputValue) || !this.inputValue;
    },
  },
  watch: {
    inputValue(newVal) {
      this.$emit('change', { value: newVal, isValid: this.validateEmail(newVal) });
    },
    email: {
      handler(newVal) {
        if (typeof newVal === 'object') {
          this.inputValue = newVal.value;
        } else {
          this.inputValue = newVal;
        }
      },
      deep: true,
    },
  },
  methods: {
    validateEmail(email) {
      if (!email) return false;

      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email);
    },
  },
};
</script>

<style>
.display-contents {
  display: contents;
}
</style>
