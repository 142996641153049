<template>
  <div v-if="isLoading" class="loadingApp position-relative mt-3" style="height: 550px">
    <div class="imgContainer">
      <img :src="`${publicPath}flexigo-loader.gif`" />
    </div>
  </div>
  <div v-else class="out-countainer custom-border mt-3">
    <div class="container-header row w-100 m-0">
      <div class="col-8 p-3">{{ this.$t('flexigo.dashboard.occupancyRatiosOverTime') }}</div>
      <div class="col-4 p-2 text-end">
        <dropdown
          :selectedRouteId="selectedRouteId"
          :list-data="value.routes"
          @select="(val) => $emit('occupancyRefresh', (selectedRouteId = val))"
        />
      </div>
    </div>
    <div class="border-0">
      <div class="bg-white p-3 outer-border">
        <highcharts :options="chartOptions" />
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import { format } from 'date-fns';
import { en, tr } from 'date-fns/locale';
import { isLocaleTr, formatPercent } from '@/helpers/utility';
import { intToDate } from '@/helpers/dateTime';
import { defaults } from '@/helpers/appDefaults';
import dropdown from '../../routeDropdown.vue';

let vm;

export default {
  name: 'vanpoolOccupancyGraph',
  components: { highcharts: Chart, dropdown },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      selectedRouteId: undefined,
      selectedXaxisPoint: undefined,
      chartOptions: {
        chart: {
          type: 'spline',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
          // align: 'left',
        },
        xAxis: {
          categories: [],
          gridLineWidth: 1,
        },
        yAxis: [
          {
            allowDecimals: false,
            crosshair: true,
            lineWidth: 1,
            title: {
              text: null,
              margin: 0,
            },
            // labels: {
            //   align: 'left',
            //   step: 1,
            // },
          },
          {
            allowDecimals: false,
            opposite: true,
            lineWidth: 1,
            crosshair: true,
            title: {
              text: '',
              margin: 0,
            },
            // labels: {
            //   align: 'right',
            //   step: 1,
            // },
          },
        ],
        legend: {
          // symbolType: 'square',
          itemStyle: {
            color: '#7f8fa4',
          },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            if (this.points[0].y || this.points[1].y) {
              return `
                <div class="dashboard-graph-tooltip">
                  <div> ${this.points[0].key}</div>
                  <br/>
                  <div> Occupancy Ratios</div>
                  <br/><br/>
                  <div class="d-flex justify-content-between mb-2 pb-1">
                    <span>Planned</span>  
                    <span>${formatPercent(this.points[0].y, this.locale)}</span>  
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Rider checkins</span>  
                    <span>${formatPercent(this.points[1].y, this.locale)}</span>  
                  </div>
                </div>
            `;
            }
            return `
                <div class="dashboard-graph-tooltip">
                  <div> ${this.points[0].key}</div>
                  <br/>
                  <div> No active routes on this day</div>
                </div>
            `;
          },
          shared: true,
          // headerFormat: '',
          backgroundColor: '#363647',
          style: {
            color: 'white',
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            pointPadding: 0,
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click(e) {
                  vm.$data.selectedXaxisPoint = e?.point?.category;
                },
              },
            },
          },
        },
        series: [
          {
            // type: 'spline',
            name: this.$t('flexigo.routeList.planned'),
            color: '#5667ff',
            data: [],
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#5667ff',
              fillColor: 'white',
            },
          },
          {
            // type: 'spline',
            name: this.$t('flexigo.dashboard.riderCheckins'),
            color: '#f6b953',
            data: [],
            connectNulls: true,
            marker: {
              symbol: 'circle',
              lineWidth: 3,
              lineColor: '#f6b953',
              fillColor: 'white',
              width: 150,
            },
          },
        ],
      },
    };
  },
  watch: {
    isLoading(value) {
      if (!value) this.prepareGraphData();
    },
    selectedXaxisPoint(value) {
      const dateIndex = this.chartOptions.xAxis.categories.findIndex((item) => value === item);
      const date = format(intToDate(this.value.data[dateIndex].routeDay), defaults.date);

      const queries = {
        ...this.$route.query,
        ...{
          selectedTab: 'routes',
          fromDate: date,
          toDate: date,
          workgroupType: 'VANPOOL',
        },
      };
      const routeData = this.$router.resolve({ name: 'RouteStatuses', query: queries });
      window.open(routeData.href, '_blank');
    },
  },
  mounted() {
    this.prepareGraphData();
    vm = this;
  },
  methods: {
    formatPercent,
    prepareGraphData() {
      this.chartOptions.xAxis.categories = this.value.data.map((item) =>
        format(intToDate(item.routeDay), 'dd LLL', { locale: isLocaleTr() ? tr : en }),
      );
      this.chartOptions.series[0].data = this.value.data.map((item) => item.plannedRatio);
      this.chartOptions.series[1].data = this.value.data.map((item) => item.riderCheckinsRatio);
    },
  },
};
</script>
<style scoped>
.custom-border {
  border: 1px solid #e6eaee;
  border-radius: 4px;
}
.out-countainer {
  background-color: white;
}
.container-header {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e6eaee;
}

.number-value {
  font-size: 18px;
  font-weight: 600;
}
.text-value {
  font-size: 12px;
  font-weight: 500;
}
</style>
