<template>
  <div class="container-xxl dashboard dashboard-background position-relative">
    <div class="row mb-3">
      <div>
        <h6 class="float-start mt-3 grid-header-font-size title">flexiShuttle</h6>
        <div class="float-end mt-2">
          <div class="d-inline-flex justify-content-center">
            <custom-date
              v-model="selectedDate"
              class="me-2"
              :border="false"
              :newDays="days"
              @date-changed="onChange"
              @show-custom-range="onCustomRangeChange"
            />
            <div v-if="showCustomRange">
              <dateRangePicker
                class="me-2 py-1"
                :start="startDate"
                :end="endDate"
                :locale="$i18n.locale"
                :lastDate="new Date()"
                @selected="dateSelect"
              />
            </div>

            <workgroup-search
              :workgroups="selectedWorkgroups"
              :campusesAndWorkgroups="campusesAndWorkgroups"
              @select="refreshWorkgroups"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- <div style=" font-weight: 600; ">{{ selectedWorkgroupsFormat }}</div> -->

    <dynamic-routes-card v-if="dynamicRoutes && dynamicRoutes.routes !== 0" :value="dynamicRoutes" />
    <fixed-routes-cards v-if="fixedRoutes && fixedRoutes.routes !== 0" :value="fixedRoutes" />
    <devices-and-actions
      v-if="telematicDevices && actionsRequired"
      :telematics="telematicDevices"
      :actions-required="actionsRequired"
    />
    <div class="row mt-3">
      <div class="col-4">
        <ride-requests v-if="rideRequests" :value="rideRequests" />
      </div>
      <div class="col-8">
        <riders-graph
          v-if="Object.keys(numberOfRiders.DYNAMIC).length"
          :value="numberOfRiders"
          :is-loading="isLoading.numberOfRiders"
          @riders-refresh="getNumberOfRiders"
        />
      </div>
    </div>

    <occupancy-graph
      v-if="Object.keys(occupancyRatios.DYNAMIC)"
      :value="occupancyRatios"
      :is-loading="isLoading.occupancyRatios"
      @occupancyRefresh="getOccupancyRatios"
    />

    <performance-graph
      v-if="Object.keys(onTimePerformance.CAMPUS)"
      :value="onTimePerformance"
      :is-loading="isLoading.onTimePerformance"
      :type="'SHUTTLE'"
      @performance-refresh="getOnTimePerformance"
    />
  </div>
</template>

<script>
import { format, lastDayOfMonth } from 'date-fns';
import DateRangePicker from '@vektor/date-picker';
import { defaults } from '@/helpers/appDefaults';
import { cloneObject } from '../../../../helpers/utility';
import workgroupSearch from '../../components/workgroupSearch.vue';
import workgroupService from '../../services/workgroup';
import dashboardService from '../../services/dashboard';
import customDate from '../../components/date.vue';
import dynamicRoutesCard from '../../components/dashboard/dynamicRoutesCards.vue';
import fixedRoutesCards from '../../components/dashboard/fixedRoutesCards.vue';
import devicesAndActions from '../../components/dashboard/devicesAndActions.vue';
import rideRequests from '../../components/dashboard/rideRequests.vue';
import ridersGraph from '../../components/dashboard/ridersGraph.vue';
import occupancyGraph from '../../components/dashboard/occupancyGraph.vue';
import performanceGraph from '../../components/dashboard/performanceGraph.vue';
import toaster from '../../mixins/toaster';

export default {
  name: 'FlexiShuttleDashboardUs',
  components: {
    workgroupSearch,
    customDate,
    dynamicRoutesCard,
    fixedRoutesCards,
    devicesAndActions,
    rideRequests,
    ridersGraph,
    occupancyGraph,
    performanceGraph,
    DateRangePicker,
  },
  mixins: [toaster],
  data() {
    return {
      stats: {},
      tasks: {},
      occupancy: [],
      routeStates: [],
      districtOccupancy: [],
      localization: 'en',

      workgroups: undefined,
      campusesAndWorkgroups: undefined,
      showCustomRange: false,
      selectedDate: 'thisMonth',
      days: ['today', 'thisWeek', 'lastSevenDays', 'thisMonth', 'lastThirtyDays', 'lastMonth', 'customDate'],
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: lastDayOfMonth(new Date()),
      options: {
        campusId: undefined,
        fromDate: format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), defaults.date),
        routeId: undefined,
        toDate: format(lastDayOfMonth(new Date()), defaults.date),
        workgroupIds: undefined,
        workingTypes: 'DYNAMIC,FIXED',
        workgroupType: 'SHUTTLE',
      },
      dynamicRoutes: undefined,
      fixedRoutes: undefined,
      telematicDevices: undefined,
      actionsRequired: undefined,
      occupancyRatios: {
        DYNAMIC: {},
        FIXED: {},
      },
      numberOfRiders: {
        DYNAMIC: {},
        FIXED: {},
        routes: [],
      },
      onTimePerformance: {
        CAMPUS: {},
        STOP: {},
        routes: [],
      },
      rideRequests: undefined,
      isLoading: {
        all: false,
        occupancyRatios: false,
        numberOfRiders: false,
        onTimePerformance: false,
      },
      publicPath: process.env.BASE_URL,
      selectedWorkgroupsFormat: undefined,
      selectedWorkgroups: undefined,
    };
  },
  computed: {
    startDateFormatted() {
      return format(this.startDate, defaults.date);
    },
    endDateFormatted() {
      return format(this.endDate, defaults.date);
    },
    campuses() {
      return this.$store.getters.campuses();
    },
  },
  watch: {
    startDate() {
      this.options.fromDate = this.startDateFormatted;
    },
    endDate() {
      this.options.toDate = this.endDateFormatted;
    },
    selectedDate() {
      this.setQuery();
    },
  },

  async mounted() {
    this.localization = 'en';
    this.setQuery();
    this.initData();
  },
  methods: {
    async initData() {
      try {
        this.getWorkgroups();
        this.getRouteStats();
        this.getTelematicDevices();
        this.getActionsRequired();
        this.getRideRequests();
        await this.getOccupancyRatios();
        await this.getOccupancyRatiosAllRoutes();
        await this.getNumberOfRiders();
        await this.getOnTimePerformance();
      } catch (error) {
        this.getToaster('error', this.$t('flexigo.common.error'), error.error.message);
      }
    },
    setQuery() {
      this.$router
        .push({
          query: {
            lang: this.$i18n.locale,
            date: this.selectedDate,
            fromDate: format(this.startDate, defaults.date),
            toDate: format(this.endDate, defaults.date),
          },
        })
        .catch(() => {});
    },
    async getWorkgroups() {
      this.workgroups = await workgroupService.getWorkgroups('SHUTTLE');
      this.campusesAndWorkgroups = await this.campuses.map((campus) => ({
        campus: {
          id: campus?.id,
          name: campus?.name,
        },
        workgroups: this.workgroups
          .filter((workgroup) => workgroup?.responsibleCampusId === campus?.id)
          .map((workgroup) => ({
            id: workgroup?.id,
            name: workgroup?.name,
            campusId: campus?.id,
          })),
      }));
      this.campusesAndWorkgroups = this.campusesAndWorkgroups.filter((item) => item.workgroups.length);
    },
    async getRouteStats() {
      const payload = this.options;
      const response = await dashboardService.getRouteStats(payload);
      this.dynamicRoutes = response.find((item) => item.type === 'DYNAMIC');
      this.fixedRoutes = response.find((item) => item.type === 'FIXED');
    },
    async getTelematicDevices() {
      const response = await dashboardService.getTelematicDevices(this.options);
      this.telematicDevices = response.telematicDevices;
    },
    async getActionsRequired() {
      this.actionsRequired = await dashboardService.getActionsRequired();
    },
    async getRideRequests() {
      const payload = this.options;
      [this.rideRequests] = await dashboardService.getRideRequests(payload);
    },
    async getOccupancyRatios(routeIds) {
      const payload = cloneObject(this.options);
      payload.routeId = Array.isArray(routeIds) ? routeIds.join(',') : routeIds;

      this.isLoading.occupancyRatios = true;
      const response = await dashboardService.getOccupancyRatios(payload);
      this.occupancyRatios.DYNAMIC.data = response.find((item) => item.type === 'DYNAMIC')?.stats;
      this.occupancyRatios.FIXED.data = response.find((item) => item.type === 'FIXED')?.stats;
      this.isLoading.occupancyRatios = false;
    },
    async getOccupancyRatiosAllRoutes() {
      const payload = this.options;
      const response = await dashboardService.getOccupancyRatiosAllRoutes(payload);
      this.occupancyRatios.DYNAMIC.routes = response.filter((item) => item.type === 'DYNAMIC');
      this.occupancyRatios.FIXED.routes = response.filter((item) => item.type === 'FIXED');
      this.numberOfRiders.routes = response;
      this.onTimePerformance.routes = response;
    },
    async getNumberOfRiders(routeIds) {
      const payload = cloneObject(this.options);
      payload.routeId = Array.isArray(routeIds) ? routeIds.join(',') : routeIds;

      this.isLoading.numberOfRiders = true;
      const response = await dashboardService.getNumberOfRiders(payload);
      this.numberOfRiders.DYNAMIC = response.find((item) => item.type === 'DYNAMIC');
      this.numberOfRiders.FIXED = response.find((item) => item.type === 'FIXED');
      this.isLoading.numberOfRiders = false;
    },
    async getOnTimePerformance(routeIds) {
      const payload = cloneObject(this.options);
      payload.routeId = Array.isArray(routeIds) ? routeIds.join(',') : routeIds;

      this.isLoading.onTimePerformance = true;
      const response = await dashboardService.getOnTimePerformance(payload);
      this.onTimePerformance.CAMPUS = response.find((item) => item.type === 'CAMPUS');
      this.onTimePerformance.STOP = response.find((item) => item.type === 'STOP');
      this.isLoading.onTimePerformance = false;
    },
    async refreshWorkgroups(selectedWorkgroups) {
      if (selectedWorkgroups.length) {
        const formattedWorkgroup =
          selectedWorkgroups.length &&
          selectedWorkgroups.reduce((r, workgroup) => {
            const { campusId, name } = workgroup;

            r[campusId] += [`${name}, `]; // eslint-disable-line

            return r;
          }, {});

        this.selectedWorkgroupsFormat = 'Showing data for';

        const campusIds = Object.keys(formattedWorkgroup);
        campusIds.forEach((item) => {
          this.selectedWorkgroupsFormat += ` ${formattedWorkgroup[item].replace('undefined', '').slice(0, -2)}
              under
              ${this.campuses.find((el) => el.id === Number(item)).name}`;
        });

        this.options.workgroupIds = selectedWorkgroups.map((item) => item.id);
        this.selectedWorkgroups = selectedWorkgroups;
        this.initData();
      } else {
        this.selectedWorkgroupsFormat = '';
        this.options.workgroupIds = undefined;
        this.selectedWorkgroups = undefined;
        this.initData();
      }
    },

    async onChange(e) {
      if (
        !(
          format(this.startDate, defaults.date) === format(new Date(e.fromDate), defaults.date) &&
          format(this.endDate, defaults.date) === format(new Date(e.toDate), defaults.date)
        )
      ) {
        this.startDate = new Date(e.fromDate);
        this.endDate = new Date(e.toDate);
        this.options.fromDate = this.startDateFormatted;
        this.options.toDate = this.endDateFormatted;
        this.initData();
      }
    },
    onCustomRangeChange(data) {
      if (data === true) {
        this.startDate = new Date();
        this.endDate = new Date();
      }
      this.showCustomRange = data;
    },
    async dateSelect(e) {
      this.startDate = e.raw.startDate;
      this.endDate = e.raw.endDate;
      this.options.fromDate = this.startDateFormatted;
      this.options.toDate = this.endDateFormatted;
      this.setQuery();
      this.initData();
    },
  },
};
</script>

<style></style>
