/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

export default {
  state: {
    allWorkgroupInstances: [],
    steps: {
      step1: undefined,
      step2: undefined,
      step3: undefined,
    },
    refreshData: false,
  },

  mutations: {
    CLEAN_STEPS(state) {
      state.steps = {
        step1: undefined,
        step2: undefined,
        step3: undefined,
      };
      state.refreshData = !state.refreshData;
    },
    SET_FORM_STEP(state, payload) {
      const { step, data } = payload;
      state.steps[step] = data;
    },
    SET_ALL_WORKGROUP_INSTANCES(state, payload) {
      state.allWorkgroupInstances = payload;
    },
    SET_TO_TERMINAL_REFERENCES(state, payload) {
      state.steps.step2.toTerminalReferences = payload;
    },
    SET_FROM_TERMINAL_REFERENCES(state, payload) {
      state.steps.step2.fromTerminalReferences = payload;
    },
    SET_CAMPUS_PATH(state, payload) {
      const { type, polyline } = payload;
      state.steps.step3.campusPath[type] = polyline;
    },
  },

  actions: {},

  getters: {
    workgroupInstances(state) {
      return state.allWorkgroupInstances;
    },
    defaultWorkgroupInstance(state) {
      return state.allWorkgroupInstances.filter((w) => w.isDefault);
    },
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
