export const BASEURL = process.env.NODE_ENV === 'development' ? '/flexigo/' : '/commute/';

const APP_PREFIX = 'pool/'; // For FLEXIGO_ADMIN

export const BASE_REST = `/${APP_PREFIX}rest/`;
export const FLEXIGO_LEGACY = `${BASEURL}rest`;
export const FLEXIGO_V3 = `${BASEURL}rest/v3`;
export const FLEXIGO_V2 = `${BASEURL}rest/v2`;
export const POOL = '/pool/rest';
export const CARSHARE = '/carshare/rest';
export const FLEXIGO_PUBLIC = `${BASEURL}public`;

export const UMNG = process.env.NODE_ENV === 'development' ? '/api/umng/rest' : '/umng/rest';
export const CORPORATE = `/${APP_PREFIX}rest/corporate`;
export const PLANNER = process.env.NODE_ENV === 'development' ? '/api/planner/rest/' : '/planner/rest/';
export const PLANNER_CORPORATE = `${PLANNER}corporate`;


export default BASEURL;
