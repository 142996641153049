<template>
  <div>
    <div class="g-recaptcha" :data-sitekey="siteKey" :data-callback="onVerify" :data-expired-callback="onExpired"></div>
  </div>
</template>

<script>
export default {
  name: 'Recaptcha',
  data() {
    return {
      scriptLoaded: false,
      siteKey: '6LcScREUAAAAAE7IaCtfpGhzOPhVYpothMWPHlgT',
    };
  },
  mounted() {
    this.loadRecaptchaScript()
      .then(() => {
        this.scriptLoaded = true;
        // eslint-disable-next-line
        grecaptcha.render(this.$el.querySelector('.g-recaptcha'), {
          sitekey: this.siteKey,
          callback: this.onVerify,
          'expired-callback': this.onExpired,
        });
      })
      .catch((error) => {
        console.error('Failed to load reCAPTCHA script.', error);
      });
  },
  methods: {
    onVerify(token) {
      this.$emit('verified', token);
    },
    onExpired() {
      this.$emit('expired');
    },
    loadRecaptchaScript() {
      return new Promise((resolve, reject) => {
        if (typeof grecaptcha !== 'undefined') {
          resolve();
        } else {
          const script = document.createElement('script');
          script.src = 'https://www.google.com/recaptcha/api.js';
          script.async = true;
          script.defer = true;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        }
      });
    },
  },
};
</script>
