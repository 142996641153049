import { global } from '@/endpoint.flexigo';
import Repository from './repository';

const client = new Repository();

export default {
  async listAll() {
    const r = await client.get(global.listAll);
    return r;
  },
  async reverseGeocode(lat, lng) {
    const r = await client.get(global.reverseGeocode.replace(':lat', lat).replace(':lng', lng));
    return r;
  },
  async getCompanySettings() {
    const r = await client.get(global.getCompanySettings);
    return r;
  },
};
