<template>
  <div v-if="isLoading.all" class="loadingApp">
    <div class="imgContainer">
      <img :src="`${publicPath}flexigo-loader.gif`">
    </div>
  </div>
  <div v-else class="container-xxl dashboard dashboard-background position-relative">
    <div class="row mb-3">
      <div>
        <h6 class="float-start mt-3 grid-header-font-size title">flexiVanpool</h6>
        <div class="float-end mt-2">
          <div class="d-inline-flex justify-content-center">
            <custom-date
              v-model="selectedDate"
              class="me-2"
              :border="false"
              :newDays="days"
              @date-changed="onChange"
              @show-custom-range="onCustomRangeChange"
            />
            <div v-if="showCustomRange">
              <dateRangePicker
                class="me-2 py-1"
                :start="startDate"
                :end="endDate"
                :locale="$i18n.locale"
                :lastDate="new Date()"
                @selected="dateSelect" />
            </div>

            <workgroup-search
              :workgroups="selectedWorkgroups"
              :campusesAndWorkgroups="campusesAndWorkgroups"
              @select="refreshWorkgroups"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- <div style=" font-weight: 600; ">{{ selectedWorkgroupsFormat }}</div> -->
    <vanpool-routes-cards v-if="cardsInfo && cardsInfo.routes !== 0" :value="cardsInfo"/>
    <devices-and-actions v-if="telematicDevices && actionsRequired" :telematics="telematicDevices" :actions-required="actionsRequired"/>

    <occupancy-graph v-if="occupancyRatios.data && occupancyRatios.routes"
                     :value="occupancyRatios"
                     :is-loading="isLoading.occupancyRatios"
                     @occupancyRefresh="getOccupancyRatios"/>
    <!-- <performance-graph v-if="Object.keys(onTimePerformance.CAMPUS)"
                       :value="onTimePerformance"
                       :is-loading="isLoading.onTimePerformance"
                       :type="'VANPOOL'"
                       @performance-refresh="getOnTimePerformance"/> -->

    <vanpool-compliance-graph v-if="compliance.data && compliance.routes && startDateFormatted && endDateFormatted"
                              :value="compliance"
                              :is-loading="isLoading.compliance"
                              :start-date-formatted="startDateFormatted"
                              :end-date-formatted="endDateFormatted"
                              @occupancyRefresh="getCompliance"/>

  </div>
</template>

<script>
import {
  format, lastDayOfMonth,
} from 'date-fns';
import DateRangePicker from '@vektor/date-picker';
import { defaults } from '@/helpers/appDefaults';
import workgroupSearch from '../../components/workgroupSearch.vue';
import workgroupService from '../../services/workgroup';
import dashboardService from '../../services/dashboard';
import customDate from '../../components/date.vue';
import vanpoolRoutesCards from '../../components/dashboard/vanpool/vanpoolRoutesCards.vue';
import devicesAndActions from '../../components/dashboard/devicesAndActions.vue';
import occupancyGraph from '../../components/dashboard/vanpool/vanpoolOccupancyGraph.vue';
import vanpoolComplianceGraph from '../../components/dashboard/vanpool/vanpoolComplianceGraph.vue';
// import performanceGraph from '../../components/dashboard/performanceGraph.vue';
import toaster from '../../mixins/toaster';

export default {
  name: 'FlexiVanpoolDashboard',
  components: {
    workgroupSearch,
    customDate,
    vanpoolRoutesCards,
    devicesAndActions,
    occupancyGraph,
    vanpoolComplianceGraph,
    // performanceGraph,
    DateRangePicker,
  },
  mixins: [toaster],
  data() {
    return {
      stats: {},
      tasks: {},
      occupancy: [],
      routeStates: [],
      districtOccupancy: [],
      localization: 'en',

      workgroups: undefined,
      campusesAndWorkgroups: undefined,
      showCustomRange: false,
      selectedDate: 'thisMonth',
      days: [
        'today',
        'thisWeek',
        'lastSevenDays',
        'thisMonth',
        'lastThirtyDays',
        'lastMonth',
        'customDate',
      ],
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: lastDayOfMonth(new Date()),
      options: {
        campusId: undefined,
        fromDate: format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), defaults.date),
        routeId: undefined,
        toDate: format((lastDayOfMonth(new Date())), defaults.date),
        workgroupIds: undefined,
        // workingTypes: 'DYNAMIC,FIXED',
        workgroupType: 'VANPOOL',
      },
      cardsInfo: undefined,
      telematicDevices: undefined,
      actionsRequired: undefined,
      occupancyRatios: {
        data: undefined,
        routes: undefined,
      },
      // onTimePerformance: {
      //   CAMPUS: {},
      //   STOP: {},
      //   routes: [],
      // },
      compliance: {
        data: undefined,
        routes: undefined,
      },
      isLoading: {
        all: false,
        occupancyRatios: false,
        // onTimePerformance: false,
        compliance: false,
      },
      publicPath: process.env.BASE_URL,
      selectedWorkgroupsFormat: undefined,
      selectedWorkgroups: undefined,
    };
  },
  computed: {
    startDateFormatted() {
      return format(this.startDate, defaults.date);
    },
    endDateFormatted() {
      return format(this.endDate, defaults.date);
    },
    campuses() {
      return this.$store.getters.campuses();
    },
  },
  watch: {
    startDate() {
      this.options.fromDate = this.startDateFormatted;
    },
    endDate() {
      this.options.toDate = this.endDateFormatted;
    },
    selectedDate() {
      this.setQuery();
    },
  },
  async mounted() {
    this.localization = 'en';
    this.setQuery();
    this.initData();
  },
  methods: {
    async initData() {
      try {
        this.isLoading.all = true;
        this.getWorkgroups();
        this.getRouteStats();
        this.getTelematicDevices();
        this.getActionsRequired();
        this.getOccupancyRatios();
        this.getOccupancyRatiosAllRoutes();
        // this.getOnTimePerformance();
        this.getCompliance();
      } catch (error) {
        this.getToaster('error', this.$t('flexigo.common.error'), error.error.message);
      } finally {
        this.isLoading.all = false;
      }
    },
    setQuery() {
      this.$router.push(
        {
          query: {
            lang: this.$i18n.locale,
            date: this.selectedDate,
            fromDate: format(this.startDate, defaults.date),
            toDate: format(this.endDate, defaults.date),
          },
        },
      ).catch(() => {});
    },
    async getWorkgroups() {
      this.workgroups = await workgroupService.getWorkgroups('VANPOOL');
      if (!this.campuses.length) { setTimeout(this.getWorkgroups, 100); }

      this.campusesAndWorkgroups = await this.campuses.map((campus) => (
        {
          campus: {
            id: campus?.id,
            name: campus?.name,
          },
          workgroups: this.workgroups
            .filter((workgroup) => workgroup?.responsibleCampusId === campus?.id)
            .map((workgroup) => (
              {
                id: workgroup?.id,
                name: workgroup?.name,
                campusId: campus?.id,
              }
            )),
        }
      ));
      this.campusesAndWorkgroups = this.campusesAndWorkgroups.filter((item) => item.workgroups.length);
    },
    async getRouteStats() {
      const payload = this.options;
      [this.cardsInfo] = await dashboardService.getRouteStats(payload);
    },
    async getTelematicDevices() {
      this.telematicDevices = await dashboardService.getTelematicDevices(this.options);
    },
    async getActionsRequired() {
      this.actionsRequired = await dashboardService.getActionsRequired();
    },
    async getOccupancyRatios(routeId) {
      const payload = this.options;
      payload.routeId = routeId;

      this.isLoading.occupancyRatios = true;
      const [response] = await dashboardService.getOccupancyRatios(payload);
      this.occupancyRatios.data = response?.stats;
      this.isLoading.occupancyRatios = false;
    },
    async getOccupancyRatiosAllRoutes() {
      const payload = this.options;
      const response = await dashboardService.getOccupancyRatiosAllRoutes(payload);
      this.occupancyRatios.routes = response;
      // this.onTimePerformance.routes = response;
      this.compliance.routes = response;
    },
    // async getOnTimePerformance(routeId) {
    //   const payload = this.options;
    //   payload.routeId = routeId;

    //   this.isLoading.onTimePerformance = true;
    //   const response = await dashboardService.getOnTimePerformance(payload);
    //   this.onTimePerformance.CAMPUS = response.find((item) => item.type === 'CAMPUS');
    //   this.onTimePerformance.STOP = response.find((item) => item.type === 'STOP');
    //   this.isLoading.onTimePerformance = false;
    // },
    async getCompliance(routeId) {
      const payload = this.options;
      payload.routeId = routeId;
      this.isLoading.compliance = true;
      const [response] = await dashboardService.getCompliance(payload);
      this.compliance.data = response?.stats;
      this.isLoading.compliance = false;
    },
    async refreshWorkgroups(selectedWorkgroups) {
      if (selectedWorkgroups.length) {
        const formattedWorkgroup = selectedWorkgroups.length && selectedWorkgroups.reduce((r, workgroup) => {
          const {
            campusId,
            name,
          } = workgroup;

          r[campusId] += [`${name}, `]; // eslint-disable-line

          return r;
        }, {});

        this.selectedWorkgroupsFormat = 'Showing data for';

        const campusIds = Object.keys(formattedWorkgroup);
        campusIds.forEach((item) => {
          this.selectedWorkgroupsFormat += (
            ` ${formattedWorkgroup[item]
              .replace('undefined', '')
              .slice(0, -2)}
              under
              ${this.campuses.find((el) => el.id === Number(item)).name}`);
        });

        this.options.workgroupIds = selectedWorkgroups.map((item) => item.id);
        this.selectedWorkgroups = selectedWorkgroups;
        this.initData();
      } else {
        this.selectedWorkgroupsFormat = '';
        this.options.workgroupIds = undefined;
        this.selectedWorkgroups = undefined;
        this.initData();
      }
    },

    async onChange(e) {
      if (!(format(this.startDate, defaults.date) === format(new Date(e.fromDate), defaults.date)
      && format(this.endDate, defaults.date) === format(new Date(e.toDate), defaults.date)
      )) {
        this.startDate = new Date(e.fromDate);
        this.endDate = new Date(e.toDate);
        this.options.fromDate = this.startDateFormatted;
        this.options.toDate = this.endDateFormatted;
        this.initData();
      }
    },
    onCustomRangeChange(data) {
      this.showCustomRange = data;
    },
    async dateSelect(e) {
      this.startDate = e.raw.startDate;
      this.endDate = e.raw.endDate;
      this.options.fromDate = this.startDateFormatted;
      this.options.toDate = this.endDateFormatted;
      this.setQuery();
      this.initData();
    },
  },
};
</script>

<style>

</style>
