<template>
  <div class="login-content-body-head scale-scroll visible">
    <div class="justify-content-center d-flex flex-row my-3 toggle-container">
      <input id="option2" v-model="selectedTab" type="radio" name="options" class="btn-check" value="ADMIN" />
      <label for="option2" class="has-cursor-pointer toggle-button last">{{ $t('flexigo.login.admin') }}</label>
      <input id="option1" v-model="selectedTab" type="radio" name="options" class="btn-check" value="RIDER" />
      <label for="option1" class="has-cursor-pointer toggle-button first">{{ $t('flexigo.login.rider') }}</label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'userTypeToggle',
  components: {},
  model: {
    prop: 'userType',
    event: 'on-change',
  },
  props: {
    userType: {
      type: String,
      default: 'RIDER',
    },
  },
  data() {
    return {
      selectedTab: 'RIDER',
    };
  },
  watch: {
    selectedTab(newVal) {
      this.$emit('on-change', newVal);
    },
    userType(newVal) {
      this.selectedTab = newVal;
    },
  },
  created() {
    this.selectedTab = this.userType;
  },
  methods: {
    openScreen(screenName) {
      this.selectedFormState = screenName;
    },
  },
};
</script>
