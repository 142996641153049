// const BASEURL = process.env.NODE_ENV === 'development' ? '/flexigo/' : '/';
const BASEURL = process.env.NODE_ENV === 'development' ? '/flexigo/' : '/commute/';

const FLEXIGO_LEGACY = `${BASEURL}rest`;
const FLEXIGO_V3 = `${BASEURL}rest/v3`;
const FLEXIGO_V2 = `${BASEURL}rest/v2`;
// const POOL = '/pool/rest/';
// const FLEXIGO_PUBLIC = `${BASEURL}public`;
// const PLANNER = `${BASEURL}planner/rest`;
// const PLANNER_CORPORATE = `${BASEURL}planner/rest/corporate`;

export const global = {
  listAll: `${FLEXIGO_LEGACY}/organization/listAll`,
  reverseGeocode: `${FLEXIGO_LEGACY}/geocode/reverse?latitude=:lat&longitude=:lng`,
  getCompanySettings: `${FLEXIGO_V3}/company-settings`,
};

export const companies = {
  companies: `${FLEXIGO_V3}/companies`,
  campuses: `${FLEXIGO_V3}/campuses`,
  regions: `${FLEXIGO_V3}/regions`,
  admins: `${FLEXIGO_V3}/admin-accounts`,
  subCompanies: `${FLEXIGO_V3}/companies/subcompanies`,
  companyBasicInfo: `${FLEXIGO_V3}/companies/basic-info`,
  subCompanyBasicInfo: `${FLEXIGO_V3}/companies/subcompanies/basic-info`,
  serviceProviders: `${FLEXIGO_V3}/service-companies`,
  companiesDepartments: `${FLEXIGO_V3}/admin/companies/:companyId/departments`,
  subCompaniesDepartments: `${FLEXIGO_V3} /rest/v3/admin/subcompanies/:subcompanyId/departments`,
  companyDepartments: `${FLEXIGO_V3}/departments`,
  industries: `${BASEURL}prm/values/industry-list`,
  settings: `${FLEXIGO_V3}/company-settings/:tabName`,
  feedback: `${FLEXIGO_V3}/company-settings/feedback`,
};

export const documents = {
  document: `${FLEXIGO_LEGACY}/doc`,
};

export const drivers = {
  drivers: `${FLEXIGO_V3}/drivers`,
};

export const vehicles = {
  vehicles: `${FLEXIGO_V3}/vehicles`,
  vehiclesCreate: `${FLEXIGO_V3}/vehicles/create`,
  vehiclesUpdate: `${FLEXIGO_V3}/vehicles/update`,
  vehiclesPrm: `${FLEXIGO_V3}/vehicles/prm`,
};

export const dashboard = {
  compliance: `${FLEXIGO_V3}/portal/dashboard/compliance`,
  complianceChartRidership: `${FLEXIGO_V3}/portal/dashboard/compliance-chart-ridership`,
  complianceNonCommute: `${FLEXIGO_V3}/portal/dashboard/compliance-chart-non-commute`,
  onTimePerformance: `${FLEXIGO_V3}/portal/dashboard/on-time-performance`,
  numberOfRiders: `${FLEXIGO_V3}/portal/dashboard/number-of-riders`,
  rideRequests: `${FLEXIGO_V3}/portal/dashboard/ride-requests`,
  occupancyRatios: `${FLEXIGO_V3}/portal/dashboard/occupancy-ratios`,
  occupancyRatiosAllRoutes: `${FLEXIGO_V3}/portal/dashboard/occupancy-ratios-all-routes`,
  actionsRequired: `${FLEXIGO_V3}/portal/dashboard/actions-required`,
  telematicdevices: `${FLEXIGO_V3}/portal/dashboard/telematic-devices`,
  route: `${FLEXIGO_V3}/portal/dashboard/route`,
  stats: `${FLEXIGO_V3}/dashboard/stat`,
  taskCount: `${FLEXIGO_LEGACY}/workflow/taskCounts`,
  tickets: `${FLEXIGO_V3}/dashboard/stat/ticket`,
  routeStates: `${FLEXIGO_V3}/dashboard/stat/route/states`,
  occupancy: `${FLEXIGO_V3}/dashboard/stat/occupancy`,
  districtOccupancy: `${FLEXIGO_V3}/dashboard/stat/district/occupancy`,
  districtMapData: `${FLEXIGO_V3}/dashboard/stat/district/personnel/geohash`,
};

export const workgroups = {
  self: `${FLEXIGO_V3}/workgroups`,
  excelUpload: `${FLEXIGO_V3}/workgroups/instance/:instanceId/add-personnel-excel`,
  addPersonnel: `${FLEXIGO_V3}/workgroups/instance/:instanceId/add-personnel-list`,
  getInstance: `${FLEXIGO_V3}/workgroups/instance/:instanceId`,
  instanceWorkbook: `${FLEXIGO_V3}/workgroups/instance/:instanceId/workbooks`,
  removePersonnelList: `${FLEXIGO_V3}/workgroups/instance/:instanceId/remove-personnel-list`,
  listPersonnel: `${FLEXIGO_LEGACY}/personnel/list?isVanPoolUser=:isVanpoolUser`,
  workgroupCancel: `${FLEXIGO_V3}/workgroups/:templateId/cancel`,
  personnelRouteChange: `${FLEXIGO_V3}/workgroups/instance/:instanceId/personnel-route-change`,
  listRoutes: `${FLEXIGO_LEGACY}/route/list`,
  listStops: `${FLEXIGO_LEGACY}/route/manage/stop/list`,
  makeDefault: `${FLEXIGO_V3}/workgroups/instance/:instanceId/make-default`,
  changeStatus: `${FLEXIGO_V3}/workgroups/instance/:instanceId/status`,
  workgroupInstances: `${FLEXIGO_V3}/workgroups/instance/prm`,
  workgroupInstanceCancel: `${FLEXIGO_V3}/workgroups/instances/cancel`,
  archiveWorkgroups: `${FLEXIGO_V3}/workgroups/page?workgroupStatus=:archived&offset=:offset&limit=:limit&sortBy=:short`,
  instancesOfArchiveWorkgroups: `${FLEXIGO_V3}/workgroups/templateId/instances/page?workgroupStatus=:archived&offset=:offset&limit=:limit&sortBy=:short`,
  getRegions: `${FLEXIGO_V3}/regions`,
  getDepots: `${FLEXIGO_V3}/depots`,
};

export const route = {
  tracksById: `${FLEXIGO_LEGACY}/route/tracks`,
  driverVehicleMatch: `${FLEXIGO_V3}/routes/driver-vehicle-schedule`,
  updateVehicleRelation: `${FLEXIGO_V3}/routes/:routeId/update-vehicle-relations`,
  updateVehicleRelationRing: `${FLEXIGO_V3}/routes/ring/:ringId/update-vehicle-relations`,
};
export const onboarding = {
  settings: `${FLEXIGO_V3}/onboarding/travel-settings`,
  defaultSettings: `${FLEXIGO_V3}/onboarding/travel-settings/reset`,
  getStatus: `${FLEXIGO_V3}/onboarding/dashboard/status`,
  getCommuteModes: `${FLEXIGO_V3}/onboarding/dashboard/commute-modes`,
  getSettingsAll: `${FLEXIGO_V3}/onboarding/settings-all`,
  saveSettingsAll: `${FLEXIGO_V3}/onboarding/save-settings-all`,
  listPersonnelItems: `${FLEXIGO_V3}/onboarding/list-personnel-items`,
  workgroupPersonnel: `${FLEXIGO_V3}/onboarding/workgroup/personnel`,
  compare: `${FLEXIGO_V3}/onboarding/dashboard/compare`,
  routeData: `${FLEXIGO_V3}/onboarding/dashboard/route-details`,
  campus: `${FLEXIGO_V3}/onboarding/campus`,
  shift: `${FLEXIGO_V3}/onboarding/workgroup`,
  sendEmail: `${FLEXIGO_V3}/onboarding/workgroup/personnel/send-address-link`,
  getCommuteDirections: `${FLEXIGO_V3}/onboarding/workgroup/personnel/commute-modes?personnelId=:personnelId&workgroupInstanceId=:workgroupId`,
  checkAccount: `${BASEURL}oauth/onboarding/:username/checkAccount`,
  checkCompany: `${BASEURL}oauth/onboarding/:companyName/checkCompany`,
  startBestOptimization: `${FLEXIGO_LEGACY}/potentialCustomer/start-best-optimization?workgroupV2Id=:workgroupId`,
  latestBestOptimization: `${FLEXIGO_LEGACY}/potentialCustomer/latest-best-optimization?workgroupV2Id=:workgroupId`,
};

// these are legacy endpoints
export const registration = {
  industryList: `${BASEURL}prm/values/industry-list`,
  companySizeList: `${BASEURL}prm/values/company-size-list`,
  register: `${BASEURL}oauth/onboarding/register`,
  login: `${BASEURL}oauth/web/session/new`,
  loginPersonnel: `${BASEURL}oauth/personnel/web/login`,
  forgotPassword: `${BASEURL}oauth/admin/forgotPassword`,
  forgotPasswordPersonnel: `${BASEURL}oauth/web/forgotPassword`,
  verify: `${BASEURL}oauth/web/session/verify`,
  checkAndLoginPersonnel: `${BASEURL}oauth/personnel/web/check-domain/login`,
  signupPersonnel: `${BASEURL}oauth/personnel/web/check-domain/signup`,
  checkDomainPersonnel: `${BASEURL}register/personnel/web/check-domain?langCode=:lcode`,
  verifyCompanyCode: `${BASEURL}register/personnel/web/verify-company-auth-code?langCode=:lcode`,
  verifyEmail: `${BASEURL}register/personnel/web/verify-email?langCode=:lcode`,
};

export const employee = {
  surveyQuestion: `${FLEXIGO_V2}/personnel/survey/question/:questionId`,
  surveyAnswer: `${FLEXIGO_V2}/personnel/survey/answer`,
  homeAddress: `${FLEXIGO_V2}/personnel/home/update`,
  nearbyStation: `${FLEXIGO_V2}/personnel/station/list`,
  routePath: `${FLEXIGO_V2}/personnel/route/track`,
  stationUpdate: `${FLEXIGO_V2}/personnel/station/update?personelId=:id`,
  activate: `${FLEXIGO_LEGACY}/personnel/:id/activate`,
  updateCampus: `${FLEXIGO_V3}/personnel/campus/update`,
  updateAgreement: `${FLEXIGO_V2}/personnel/info/update`,
};

export const routeList = {
  route: `${FLEXIGO_V3}/routes`,
  routes: `${FLEXIGO_V3}/workgroups/instance/:instanceId/routes`,
  tickets: `${FLEXIGO_V3}/routes/:routeId/tickets`,
  personnels: `${FLEXIGO_V3}/routes/:routeId/personnels`,
  routeDetails: `${FLEXIGO_V3}/routes/:routeId`,
  activeRoutes: `${FLEXIGO_V3}/routes/:routeId/version/active`,
  draftRoutes: `${FLEXIGO_V3}/routes/:routeId/version/draft`,
  cardIssuance: `${FLEXIGO_V3}/routes/:routeId/card-events?fromDate=:fromDate&toDate=:toDate`,
  journey: `${FLEXIGO_V3}/routes/:routeId/instances?fromDate=:fromDate&toDate=:toDate`,
  accounting: `${FLEXIGO_V3}/routes/:routeId/accounting`,
  serviceCompanies: `${FLEXIGO_V3}/service-companies?lightMode=true`,
  addUpdate: `${FLEXIGO_V3}/routes`,
  accountingList: `${FLEXIGO_LEGACY}/accounting/definition/accountingcenter/list`,
  vehicles: `${FLEXIGO_V3}/vehicles`,
  drivers: `${FLEXIGO_V3}/drivers/prm`,
  transferRoute: `${FLEXIGO_V3}/routes/:routeId/vehicle/history?fromDate=:fromDate&toDate=:toDate`,
  transfer: `${FLEXIGO_V3}/routes/reservation/transfer`,
  deleteStation: `${FLEXIGO_V3}/stations/:stationId`,
  updateDraftRoutes: `${FLEXIGO_V3}/routes/:routeId/version/draft-update`,
  activateDraft: `${FLEXIGO_V3}/routes/:routeId/version/draft-activate`,
  stations: `${FLEXIGO_V3}/stations/multiple`,
  addStation: `${FLEXIGO_V3}/stations`,
  editRoutes: `${FLEXIGO_V3}/workgroup-instances/:instanceId/route-edit`,
  cancelEditRoutes: `${FLEXIGO_V3}/workgroup-instances/:instanceId/route-edit/cancel`,
  saveAndActive: `${FLEXIGO_V3}/workgroup-instances/:instanceId/route-edit/update-activate`,
  sendSelectedToOptimization: `${FLEXIGO_LEGACY}/workbook/newWorkBookForWorkgroupV2/optimizationUrl`,
  routeData: `${FLEXIGO_V3}/workgroup-instances/:instanceId/route-data`,
  updateActivate: `${FLEXIGO_V3}/workgroup-instances/:instanceId/route-data/update-activate`,
  approval: `${FLEXIGO_V3}/vanpool/versioned-route/:versionedRouteId/approval`,
  startApproval: `${FLEXIGO_V3}/vanpool/versioned-route/:versionedRouteId/approval/start-approval`,
  assignPersonnelDriver: `${FLEXIGO_V3}/routes/:routeId/assign-personnel-driver`,
  assignSecondaryDriver: `${FLEXIGO_V3}/routes/:routeId/assign-secondary-driver`,
  deleteSecondaryDriver: `${FLEXIGO_V3}/routes/:routeId/remove-secondary-driver`,
  routesStatus: `${FLEXIGO_V3}/workgroup-instance-monitor`,
  routeRiderStatus: `${FLEXIGO_V3}/workgroup-instance-monitor/riderStatus`,
  startOptimization: `${FLEXIGO_V3}/workgroup-instances/:instanceId/start-optimization`,
  updateRoutes: `${FLEXIGO_V3}/routes/update`,
  updateStationStats: `${FLEXIGO_V3}/stations/updateStationStats`,
  cardEvents: `${FLEXIGO_V3}/routes/:routeId/card-events`,
  hakedisKalemleri: `${FLEXIGO_LEGACY}/hakedisnew/kalem/prm`,
  addRotaKalemi: `${FLEXIGO_LEGACY}/hakedisnew/tarihce/newRotaKalemi`,
  hakedis: `${FLEXIGO_LEGACY}/hakedisnew/tarihce/route_list?routeId=:routeId`,
  updateRoutesRing: `${FLEXIGO_V3}/routes/ring-update`,
  trackingData: `${FLEXIGO_V3}/workgroup-instance-monitor/routes/live`,
  trackingDataDaily: `${FLEXIGO_V3}/workgroup-instance-monitor/routes/daily`,
  trackingVehiclePosition: `${FLEXIGO_V3}/workgroup-instance-monitor/routes/live/vehicle-position`,
  tripCancel: `${FLEXIGO_V3}/workgroup-instance-monitor/route/:routeInstanceId/cancel`,
  tripRevertCancel: `${FLEXIGO_V3}/workgroup-instance-monitor/route/:routeInstanceId/revert-cancel`,
  tripCancelMultiple: `${FLEXIGO_V3}/workgroup-instance-monitor/route/cancel-multiple-instances`,
  tripNotify: `${FLEXIGO_V3}/workgroup-instance-monitor/route/:routeInstanceId/notify`,
  updateMultiHakedisStatus: `${FLEXIGO_LEGACY}/routeInstance/updateMultiHakedisStatus`,
  hakedisMessage: `${FLEXIGO_LEGACY}/routeInstance/getLog?routeInstanceId=:routeInstanceId`,
  originalList: `${FLEXIGO_V3}/route-schedule-change/list-original`,
  temporaryList: `${FLEXIGO_V3}/route-schedule-change/list-temporary`,
  routeScheduleAdd: `${FLEXIGO_V3}/route-schedule-change/add`,
  routeScheduleDelete: `${FLEXIGO_V3}/route-schedule-change/delete/:id`,
  routeScheduleHasOnTheWay: `${FLEXIGO_V3}/route-schedule-change/has-on-the-way`,
  routeScheduleHasConflict: `${FLEXIGO_V3}/route-schedule-change/has-conflict`,
  scheduleChangeNotify: `${FLEXIGO_V3}/workgroup-instance-monitor/route/schedule-change/notify`,
  createMissingInstances: `${FLEXIGO_V3}/workgroup-instance-monitor/routes/create-missing-instances`,
  plannedPersonnel: `${FLEXIGO_V3}/workgroup-instance-monitor/route/:routeInstanceId/planned-personnels`,
  actualPersonnel: `${FLEXIGO_V3}/workgroup-instance-monitor/route/:routeInstanceId/actual-personnels`,
  routeSearch: `${FLEXIGO_V3}/routes/search`,
  reservationPorting: `${FLEXIGO_V3}/workgroup-instance-monitor/routes/reservation-porting`,
  reservationNotify: `${FLEXIGO_V3}/workgroup-instance-monitor/routes/reservation-porting/notify`,
};

export const employeeList = {
  personnel: `${FLEXIGO_V3}/portal/personnels/:personnelId/commute-info`,
  personnelByUmng: `${FLEXIGO_V3}/portal/personnels/umng/:umngAccountId/commute-info`,
  personnels: `${FLEXIGO_V3}/portal/personnels`,
  driverLicence: `${FLEXIGO_V3}/portal/personnels/:personnelId/driver-licence`,
  personnelsPartially: `${FLEXIGO_V3}/portal/personnels/partially`,
  departments: `${FLEXIGO_V3}/departments`,
  regions: `${FLEXIGO_V3}/regions`,
  campuses: `${FLEXIGO_V3}/campuses`,
  campusesOfEmployee: `${FLEXIGO_V3}/campuses?isCampus=true`,
  commuteOptions: `${FLEXIGO_V3}/portal/personnels/commute-options`,
  supportRequests: `${FLEXIGO_V3}/portal/personnels/:personnelId/tickets`,
  commuteInfo: `${FLEXIGO_V3}/portal/personnels/:personnelId/commute-info`,
  commuteInfoOnboarding: `${FLEXIGO_V3}/onboarding/personnels/:personnelId/commute-info`,
  cardReads: `${FLEXIGO_V3}/portal/personnels/:personnelId/card-events?fromDate=:fromDate&toDate=:toDate`,
  sendEmail: `${FLEXIGO_V3}/portal/personnels/send-address-link`,
  getEmailTemplates: `${FLEXIGO_V3}/company-settings/email-templates`,
  getRegion: `${FLEXIGO_V3}/regions/:regionId`,
  getWorkgroups: `${FLEXIGO_V3}/workgroups/instance/prm?campusId=:campusId`,
  getRoutes: `${FLEXIGO_V3}/routes?campusId=:campusId&workgroupInstanceId=:instanceId&lightMode=true`,
  uploadExcel: `${FLEXIGO_V3}/portal/personnels/upload-personnel-items`,
  excelTemplate: `${FLEXIGO_V3}/portal/personnels/download/employee-list-template`,
  stationList: `${FLEXIGO_V3}/portal/personnels/:personnelId/station-list?`,
  walkingPathElevation: `${FLEXIGO_V3}/portal/personnels/:personnelId/update-walking-path-elevation`,
  defaultWorkgroup: `${FLEXIGO_V3}/portal/personnels/:personnelId/default-workgroup`,
};

export const flexiMileage = {
  mileage: `${FLEXIGO_V3}/mileage`,
  updateMileage: `${FLEXIGO_V3}/mileage`,
};

export const carpool = {
  userStatus: `${FLEXIGO_V3}/carpool/user-status`,
  approvedMatches: `${FLEXIGO_V3}/carpool/approved-matches`,
  trips: `${FLEXIGO_V3}/carpool/trips`,
  addTrips: `${FLEXIGO_V3}/carpool/add-trip`,
  drivers: `${FLEXIGO_V3}/carpool/drivers`,
  riders: `${FLEXIGO_V3}/carpool/riders?driverPersonelId=:driverPersonelId`,
};

export const tickets = {
  tickets: `${FLEXIGO_V3}/tickets`,
  comment: `${FLEXIGO_V3}/tickets/:id/comment`,
  assignComment: `${FLEXIGO_V3}/tickets/:id/assign`,
  closeTicket: `${FLEXIGO_V3}/tickets/:id/close-ticket`,
  assignTicket: `${FLEXIGO_V3}/tickets/:id/assign`,
};

export const survey = {
  answers: `${FLEXIGO_V3}/survey/personnel/answers`,
};

export const requestApprovals = {
  count: `${FLEXIGO_V3}/request-approval/counts`,
  routeChanges: `${FLEXIGO_V3}/request-approval/route-changes`,
  driverVehicleChange: `${FLEXIGO_V3}/request-approval/driver-vehicle-changes`,
  documents: `${FLEXIGO_V3}/request-approval/documents`,
  payments: `${FLEXIGO_V3}/request-approval/payments`,
  vanpoolDemandList: `${FLEXIGO_V3}/vanpool-demand/list`,
  approve: `${FLEXIGO_V3}/request-approval/:taskId/completeTask`,
  reject: `${FLEXIGO_V3}/request-approval/:taskId/rejectTask`,
};

export const notifications = {
  notifications: `${FLEXIGO_V3}/notifications`,
  getNotifications: `${FLEXIGO_V3}/notifications/:id`,
  personnel: `${FLEXIGO_V3}/notifications/:id`,
  deleteNotifications: `${FLEXIGO_V3}/notifications/personnel/delete`,
  routes: `${FLEXIGO_V3}/routes`,
  personnelNotifications: `${FLEXIGO_V3}/portal/personnels/send-notification `,
  createNotifications: `${FLEXIGO_V3}/notifications/multi-route`,
};
export const reports = {
  list: `${FLEXIGO_LEGACY}/report/type/list`,
  getReportByType: `${FLEXIGO_LEGACY}:reportType`,
  listRidersAndDrivers: `${FLEXIGO_V3}/portal/personnels/list-riders-and-drivers`,
  routes: `${FLEXIGO_V3}/routes`,
};

export default {
  companies,
  drivers,
  vehicles,
  dashboard,
  workgroups,
  route,
  registration,
  onboarding,
  employee,
  routeList,
  employeeList,
  requestApprovals,
  carpool,
  notifications,
  reports,
};
