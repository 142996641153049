import LogRocket from "logrocket";

function hasPermission(array, permission) {
  if (array.includes(permission)) return true;
  return false;
}

function isFlexiridDashboard(payload) {
  const fields = [
    payload.isCarpoolAdmin,
    payload.isFinanceAdmin,
    payload.isPoolCarAdmin,
    payload.isShuttleAdmin,
    payload.isTaxiAdmin,
    payload.isVanpoolAdmin,
  ];
  if (payload.isFlexiRideAdmin && !fields.some((field) => field)) {
    return true;
  }
  return false;
}

function checkRiderRedirects(userDetails) {
  if (userDetails?.accountType) {
    return false;
  }
  if (!userDetails.aggrementDate && userDetails.kvkkDocUrl?.length > 0) {
    return 'ApproveKvkk';
  }
  const isMainCampusSelected = JSON.parse(sessionStorage.getItem('mainCampusSelected'));
  if (!userDetails.destination?.id && !isMainCampusSelected) {
    return 'SelectMainCampus';
  }
  if (!userDetails.personnel?.homeLocation?.latitude || !userDetails.personnel?.homeLocation?.longitude) {
    const isAddressSkipped = JSON.parse(sessionStorage.getItem('skipAddress'));
    if (isAddressSkipped) return false;
    return 'HomeAddress';
  }
  return false;
}

export default {
  name: 'loginRedirect',
  methods: {
    loginRedirect(userResponse) {
      const redirectedPage = checkRiderRedirects(userResponse);
      if (redirectedPage) {
        this.$router.push({ name: redirectedPage });
        return;
      }

      if (userResponse.mainPage) {
        console.log('redirecting to main page');
        const router = JSON.parse(userResponse.mainPage);

        if (router?.data?.legacyRoute) {
          this.$router.push({ name: 'legacy', params: { route: router.data.legacyRoute } });
          return;
        }
        const routeObject = { name: router.route };
        if (router.data?.typeParam) {
          routeObject.params = { type: router.data.typeParam };
        }

        this.$router.push(routeObject);
        return;
      }

      if (userResponse.isShuttleAdmin) {
        console.log('redirecting to flexiride dashboard becuase isShuttleAdmin is true');
        if (window.location.origin.includes('portal')) {
          this.$router.push({ name: 'FlexiShuttleDashboardUs' });
        } else {
          this.$router.push({ name: 'Home' });
        }
      }
      if (userResponse.isVanpoolAdmin) {
        console.log('redirecting to flexiride dashboard becuase isVanpoolAdmin is true');
        this.$router.push({ name: 'FlexiVanpoolDashboard' });
        return;
      }

      if (isFlexiridDashboard(userResponse) && hasPermission(userResponse.menuPermissionList, 'vser-dashboard')) {
        console.log('redirecting to flexiride dashboard becuase isFlexiridDashboard is true');
        this.$router.push({ name: 'FlexiVanpoolDashboard' });
        return;
      }

      if (userResponse.isFlexiRideAdmin) {
        console.log('redirecting to flexiride dashboard becuase isFlexiRideAdmin is true');
        this.$router.push({ name: 'flexiRideDashboard' });
        return;
      }

      // Redirect Rider
      if (!userResponse.accountType) {
        if (userResponse.personnel?.isSharedRideUser) {
          console.log('redirecting to request becuase isSharedRideUser is true');
          this.$router.push({ name: 'SharedRideRequest' });
          return;
        }
        if (userResponse.personnel?.isFlexirideAssistant) {
          console.log('redirecting to FlexirideRequests becuase isFlexirideAssistant is true');
          this.$router.push({ name: 'FlexirideRequests' });
          return;
        }
        if (
          userResponse.personnel?.isVanpoolUser ||
          userResponse.personnel?.isShuttleUser ||
          userResponse.personnel?.isPoolShuttleUser
        ) {
          console.log('redirecting to Shuttle Information becuase isVanpoolUser or isShuttleUser is true');
          const route = 'app.personel.info'; // TICKET
          this.$router.push({ name: 'legacy', params: { route } });
          return;
        }
      }

      console.log('falling back to default home page');
      LogRocket.error('falling back to default home page');
      this.$router.push({ name: 'Home' });
    },
    checkRiderRedirects,
  },
};

export { checkRiderRedirects };
