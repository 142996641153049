/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { removeFromArray, toLatLng, objectifyByKey } from '@/helpers/utility';
import { intToTimeStr, timeLocalFormat } from '@/helpers/dateTime';
import { distinctColors } from '@/helpers/constants';
// import { decode } from '@/helpers/here/flexipolyline';
import { orderStations, decodePolyline } from '../../components/routeList/utils';

function getPathType(route) {
  return 'routePath';
  // if (route.routePath.pathCoordinates?.length) return 'routePath';
  // if (route.returnRoutePath.pathCoordinates?.length) return 'returnRoutePath';
  // return undefined;
}

function routesFromActive(payload) {
  return payload.map((el, index) => {
    const pathType = getPathType(el.route);
    const stations = orderStations(el.route[pathType]?.stationIds, el.stations);
    const isGhost = !el.route?.routePath?.encodedPolyline;
    return {
      attendingPersonnelCount: el.route.attendingPersonnelCount,
      avgTimeCoefficient: el.route.avgTimeCoefficient,
      avgWalkingDistanceInMeter: el.route.avgWalkingDistanceInMeter,
      driver: `${el.route.driver.name ? el.route.driver.name : ''} ${
        el.route.driver.surname ? el.route.driver.surname : ''
      }`,
      driverId: el.route.driver.id,
      driverPersonnelId: el.route.driver.personnelId,
      encodedPolyline: el.route.routePath.encodedPolyline,
      endTime: intToTimeStr(el.route.endTime),
      id: el.route.id,
      isGhost,
      isActive: el.route.isActive,
      isTransferApplied: el.route.isTransferApplied,
      isEditMode: false,
      name: el.route.name,
      personnelCount: el.route.personnelCount,
      periodStart: el.route.periodStart,
      plate: el.route.vehicle.plateId,
      position: el?.route[pathType]?.encodedPolyline ? decodePolyline(el.route[pathType].encodedPolyline) : [],
      reservationCount: el.route.reservationCount,
      routeColor: distinctColors[index % distinctColors.length],
      routeId: el.route.id,
      routeName: el.route.name,
      showRoute: true,
      startTime: intToTimeStr(el.route.startTime),
      status: el.route.vehicle.deviceStatus || '-',
      title: el.route.title,
      totalCost: el.route.totalCost,
      totalDistance: el.route.totalKm,
      totalDuration: el.route.durationInMin,
      vehicleCapacity: el.route.vehicleCapacity,
      pathType,
      isVisible: false,
      isSelected: false,
      vehicle: el.route.vehicle,
      vehicleId: el.route.vehicle.id,
      serviceCompanyId: el.route.serviceCompanyId,
      workgroupId: el.route.workgroupId,
      accountingCenterId: el.route.accountingCenterId,
      stops: el.route[pathType]?.stationIds ? el.route[pathType].stationIds.length : 0,
      stations,
      hours: stations.map((item) => timeLocalFormat(item.expectedDepartureHour || item.expectedArrivalHour)),
      stationsIds: el.route[pathType]?.stationIds || {},
      usedStationIds: el.route[pathType]?.usedStationIds || [],
      secondaryDrivers: el.route.secondaryDrivers,
      isVisibleForEnabledRidersByAdmin: el.route.isVisibleForEnabledRidersByAdmin,
    };
  });
}

function mergeDraftRoutes(routes, draftRoutes) {
  return routes.map((route) => {
    const draft = draftRoutes[route.id];
    if (!draft) return route;
    const track = 'path'; // multiple edit currently does not support return path
    const { encodedPolyline } = draft.draftVersion.routeDetail[track];
    if (!encodedPolyline) console.warn(`no polyline for route: ${route.id}`);
    const stations = orderStations(draft.draftVersion.routeDetail.path.lstRouteStopIds, draft.stations);
    return {
      ...route,
      draftId: draft?.draftVersion?.id,
      distanceInKm: draft ? draft.draftVersion.routeDetail[track]?.distanceInKm : route.totalDistance,
      durationInMin: draft ? draft.draftVersion.routeDetail[track]?.durationInMin : route.totalDuration,
      stopIds: draft.draftVersion.routeDetail[track]?.lstRouteStopIds,
      stations,
      position: decodePolyline(encodedPolyline),
    };
  });
}

export default {
  state: {
    allRoutes: [],
    allStations: [],
    draftRoutes: {},
    personnelMap: {}, // Map or Object?,
    instancePersonnel: [],
    modifiedRoutes: [],
    currentWorkgroup: {},
    optSelectedUnassigned: [],
    optSelectedRoutes: [],
    routeTypeFilter: 'active',
    isEditMode: false,
    isOptimizationSelectionMode: false,
    isDrawingMode: false,
    optimizeWaypoints: true,
  },

  mutations: {
    SET_ALL_ROUTES(state, payload) {
      console.time('SET_ALL_ROUTES');

      state.allStations = payload.routes;
      // this if block is a temporary solution for test mode mixed with real mode
      if (payload.routeDrafts && Object.keys(payload.routeDrafts).length) {
        const { routeDrafts, routes } = payload;
        state.allRoutes = routesFromActive(routes);
        state.draftRoutes = routeDrafts;
      } else {
        console.warn('this usage is deprecated, use SET_ALL_ROUTES_WITH_DRAFTS instead');
        state.allRoutes = routesFromActive(payload?.routes);
      }

      state.allRoutes.forEach((r) => {
        if (r.isGhost) {
          if (state.draftRoutes[r.id]?.draftVersion?.routeDetail?.path?.encodedPolyline)
            r.position = decodePolyline(state.draftRoutes[r.id]?.draftVersion?.routeDetail?.path?.encodedPolyline);
          r.stations = orderStations(
            state.draftRoutes[r.id]?.draftVersion.routeDetail.path.lstRouteStopIds,
            state.draftRoutes[r.id]?.stations,
          );
          r.stationIds = state.draftRoutes[r.id]?.draftVersion.routeDetail.path.lstRouteStopIds;
        }
      });
      console.timeEnd('SET_ALL_ROUTES');
    },
    TOGGLE_VISIBILITY(state, routeId) {
      const routeIndex = state.allRoutes.findIndex((el) => el.id === routeId);
      if (routeIndex > -1) {
        state.allRoutes[routeIndex].isVisible = !state.allRoutes[routeIndex].isVisible;
      }
    },
    SET_VISIBILITY_FOR_ALL_ROUTES(state, isVisible) {
      state.allRoutes.forEach((r) => {
        r.isVisible = isVisible;
      });
    },
    TOGGLE_SELECTED(state, routeId) {
      const routeIndex = state.allRoutes.findIndex((el) => el.id === routeId);
      if (routeIndex > -1) {
        state.allRoutes[routeIndex].isSelected = !state.allRoutes[routeIndex].isSelected;
      }
    },
    SELECT_ROUTE(state, routeId) {
      const routeIndex = state.allRoutes.findIndex((el) => el.id === routeId);
      if (routeIndex > -1) {
        state.allRoutes[routeIndex].isSelected = true;
      }
    },
    UNSELECT_ROUTE(state, routeId) {
      const routeIndex = state.allRoutes.findIndex((el) => el.id === routeId);
      if (routeIndex > -1) {
        state.allRoutes[routeIndex].isSelected = false;
      }
    },
    SET_INSTANCE_PERSONNELS(state, payload) {
      state.instancePersonnel = payload;
    },
    SET_PERSONNEL_MAP(state, personnels) {
      const p = {};
      personnels.forEach((el) => {
        p[el.personnel.id] = el;
      });
      state.personnelMap = p;
    },
    ADD_MODIFIED_ROUTE(state, routeId) {
      const ids = new Set(state.modifiedRoutes);
      ids.add(routeId);
      state.modifiedRoutes = [...ids];
    },
    REMOVE_MODIFIED_ROUTE(state, routeId) {
      removeFromArray(state.modifiedRoutes, routeId);
    },
    SET_MODIFIED_ROUTES(state, payload) {
      state.modifiedRoutes = payload;
    },
    SET_MAIN_CAMPUS(state, payload) {
      state.mainCampus = payload;
    },
    SET_CURRENT_WORKGROUP(state, payload) {
      state.currentWorkgroup = payload;
    },
    ADD_TO_OPT_LIST(state, payload) {
      const { list, id } = payload;
      if (!state[list].includes(id)) state[list].push(id);
    },
    REMOVE_FROM_OPT_LIST(state, payload) {
      const { list, id } = payload;
      state[list] = removeFromArray(state[list], id);
    },
    ADD_STATION_TO_ROUTE(state, payload) {
      const { routeId, station } = payload;
      if (station.personnels === undefined) station.personnels = [];
      if (station.personnelIds === undefined) station.personnelIds = [];
      if (!state.draftRoutes[routeId]?.stations) state.draftRoutes[routeId].stations = [];
      state.draftRoutes[routeId].stations.push(station);
      state.draftRoutes[routeId].draftVersion.routeDetail.path.lstRouteStopIds.push(station.id);
    },
    ADD_DRAFT_ROUTE(state, payload) {
      const { id, draft } = payload;
      // Object spread used for keeping vue reactivity intact
      state.draftRoutes = {
        ...state.draftRoutes,
        [id]: draft,
      };
    },
    UPDATE_DRAFT_STATION(state, payload) {
      const { routeId, stationIndex, stationId, oldStationId, location } = payload;

      if (state.draftRoutes[routeId].stations[stationIndex]) {
        const sidx = state.draftRoutes[routeId].draftVersion.routeDetail.path.lstRouteStopIds.findIndex(
          (sid) => sid === oldStationId,
        );
        state.draftRoutes[routeId].draftVersion.routeDetail.path.lstRouteStopIds[sidx] = stationId;
        state.draftRoutes[routeId].stations[stationIndex].id = stationId;
        state.draftRoutes[routeId].stations[stationIndex].location = location;
      } else {
        state.draftRoutes[routeId].stations.push({ location, id: stationId });
      }
    },
    SET_ROUTE_TYPE_FILTER(state, payload) {
      state.routeTypeFilter = payload;
    },
    SET_EDIT_MODE(state, payload) {
      state.isEditMode = payload;
    },
    SET_OPTIMIZATION_SELECTION_MODE(state, payload) {
      state.isOptimizationSelectionMode = payload;
    },
    UPDATE_DRAFT_ROUTE_LINE(state, payload) {
      const { routeId, encodedPolyline, distance, duration } = payload;

      // const lstTrackPositions = path.map((c) => ({ latitude: c.lat, longitude: c.lng }));
      const distanceInKm = distance / 1000;
      const durationInMin = duration / 60;
      state.draftRoutes[routeId].draftVersion.routeDetail.path = {
        ...state.draftRoutes[routeId].draftVersion.routeDetail.path,
        encodedPolyline,
        distanceInKm,
        durationInMin,
      };
      state.draftRoutes[routeId].draftVersion.updateTime = new Date().getTime();
    },

    UPDATE_DRAFT_ROUTE_LINE_DURATION(state, payload) {
      const { routeId, duration } = payload;
      state.draftRoutes[routeId].draftVersion.routeDetail.path.durationInMin = duration / 60;
    },

    UPDATE_DRAFT_ROUTE_STATIONS(state, payload) {
      const { routeId, stations } = payload;
      state.draftRoutes[routeId].stations = stations;
      state.draftRoutes[routeId].draftVersion.routeDetail.path.lstRouteStopIds = stations.map((s) => s.id);
    },
    UPDATE_DRAFT_ROUTE_LEGS(state, payload) {
      const { routeId, legs } = payload;
      state.draftRoutes[routeId].draftVersion.routeDetail.path.legs = legs.map((l) => l?.duration?.value);
    },
    ASSIGN_PERSONNEL_TO_STOP_DRAFT(state, payload) {
      const { routeId, stationId, personnelId } = payload;
      const pIndex = state.instancePersonnel.findIndex((el) => el.personnel.id === personnelId);

      if (!state.draftRoutes[routeId].draftVersion.routeDetail.pathPersonnelStations) {
        state.draftRoutes[routeId].draftVersion.routeDetail.pathPersonnelStations = {};
      }

      state.draftRoutes[routeId].draftVersion.routeDetail.pathPersonnelStations[personnelId] = stationId;

      const personnel = state.instancePersonnel[pIndex];
      personnel.station.id = stationId;
      personnel.route.id = routeId;
      state.instancePersonnel[pIndex] = personnel;
    },
    SET_OPTIMIZE_WAYPOINTS_CHECK(state, value) {
      state.optimizeWaypoints = value;
    },
    TOGGLE_DRAWING_MODE(state) {
      state.isDrawingMode = !state.isDrawingMode;
    },
    SET_DRAWING_MODE(state, payload) {
      state.isDrawingMode = payload;
    },
    ADD_NEW_ROUTE(state, route) {
      const pathType = 'routePath';
      // const lastColor = state.allRoutes[state.allRoutes.length - 1].routeColor;
      state.allRoutes.push({
        ...route,
        driver: `${route.driver.name ? route.driver.name : ''} ${route.driver.surname ? route.driver.surname : ''}`,
        position: route[pathType]?.pathCoordinates
          ? route[pathType].pathCoordinates.map((item) => ({
              lat: item[0],
              lng: item[1],
            }))
          : [],
        isGhost: true,
        isVisible: false,
        isSelected: false,
        routeColor: distinctColors[(state.allRoutes.length + 1) % distinctColors.length],
        endTime: intToTimeStr(route.endTime),
        startTime: intToTimeStr(route.startTime),
        stations: [],
        stationIds: [],
      });
    },
  },

  actions: {},

  getters: {
    isRing(state) {
      return state.currentWorkgroup?.template?.direction === 'RING';
    },
    routeListMap(state) {
      return new Map(state.allRoutes.map((el) => [el.id, el]));
    },
    activeRoutes(state) {
      return state.allRoutes.filter((el) => el.isActive);
    },
    passiveRoutes(state) {
      return state.allRoutes.filter((el) => !el.isActive);
    },
    routeListSelected(state) {
      return state.allRoutes.filter((el) => el.isSelected);
    },
    allRoutesMerged(state, getters) {
      const routes = state.routeTypeFilter === 'passive' ? getters.passiveRoutes : getters.activeRoutes;
      if (state.isEditMode) {
        return mergeDraftRoutes(routes, state.draftRoutes, state.personnelMap);
      }
      return routes;
    },
    routeListVisible(state, getters) {
      if (state.currentWorkgroup?.template?.direction === 'RING') {
        return getters.allRoutesMerged.slice(0, 1);
      }
      return getters.allRoutesMerged.filter((el) => el.isVisible);
    },
    personnelByStation(state) {
      const routes = {};
      state.allRoutes.forEach((route) => {
        if (!routes[route.id]) routes[route.id] = {};
        if (!route.stations) route.stations = [];
        route.stations.forEach((station) => {
          if (station && station.personnelIds) {
            if (!routes[route.id][station.id]) routes[route.id][station.id] = [];
            station.personnelIds.forEach((id) => {
              routes[route.id][station.id].push(state.personnelMap[id]);
            });
          }
        });
      });
      return routes;
    },
    personnelByRoute(state) {
      const routes = {};
      state.instancePersonnel.forEach((p) => {
        routes[p.route.id] = routes[p.route.id] || [];
        routes[p.route.id].push(p);
      });
      return routes;
    },
    personnelWithStationOnDraft(state) {
      const routeIds = Object.keys(state.draftRoutes);
      return routeIds
        .map((rid) => Object.keys(state.draftRoutes[rid].draftVersion.routeDetail.pathPersonnelStations))
        .flat();
    },
    routeListModifiedRoutes(state) {
      return state.modifiedRoutes;
    },
    currentWorkgroup(state) {
      return state.currentWorkgroup;
    },
    personnelWithoutRoute(state, getters, rootState) {
      if (!rootState?.routeList?.currentWorkgroup) return [];
      if (!getters.personnelByRoute?.null) return [];
      const { workgroupType } = rootState.routeList.currentWorkgroup.template;
      if (state.isEditMode) {
        const filteredIds = Object.keys(state.personnelMap).filter(
          (id) => !getters.personnelWithStationOnDraft.includes(id) && !state.personnelMap[id]?.route?.id,
        );
        return filteredIds.map((id) => state.personnelMap[id]);
      }
      if (workgroupType === 'SHUTTLE') return getters.personnelByRoute.null;
      return getters.personnelByRoute.null.filter((p) => !p.personnel.isVanpoolDriver);
    },
    // DEPRECATED use currentWorkgroupCampuses instead
    mainCampus(state, getters, rootState) {
      if (!state.currentWorkgroup?.template) return {};
      const { template } = state.currentWorkgroup;
      const where = template.fromType === 'CAMPUS' ? 'fromTerminalReferenceId' : 'toTerminalReferenceId';
      const campus = rootState.campuses.find((el) => el.id === template[where]);
      if (!campus) return {};
      return {
        id: campus.id,
        name: campus.name,
        title: campus.title,
        position: {
          lat: campus.location.latitude,
          lng: campus.location.longitude,
        },
      };
    },
    // THIS AND RELATED FIELDS NEED TO CHANGE IF THERE ARE CAMPUS TO CAMPUS WORKGROUPS
    currentWorkgroupCampuses(state, getters, rootState) {
      if (!state.currentWorkgroup?.template) return [];
      const { template } = state.currentWorkgroup;
      const where = template.fromType === 'CAMPUS' ? 'fromTerminal' : 'toTerminal';
      const lookup = template[`${where}References`].length
        ? new Set(template[`${where}References`].map((e) => e.destinationId))
        : new Set([template[`${where}ReferenceId`]]);
      const campuses = objectifyByKey([...rootState.campuses], 'id');

      return [...lookup].map((id) => ({
        ...campuses[id],
        position: {
          lat: campuses[id].location.latitude,
          lng: campuses[id].location.longitude,
        },
      }));
      // console.log(rootState.campuses);
      // console.log('campuses', campuses);
      // return campuses.map((campus) => ({
      //   ...campus,
      //   position: {
      //     lat: campus.location.latitude,
      //     lng: campus.location.longitude,
      //   },
      // }));
    },
    // campusPath(state) {
    //   if (!state.currentWorkgroup?.template?.campusPath) return {};
    //   const { path, returnPath } = state.currentWorkgroup?.template?.campusPath;
    //   const pathDecoded = path ? decode(path) : { polyline: [] };
    //   const returnPathDecoded = returnPath ? decode(returnPath) : { polyline: [] };
    //   return {
    //     path: pathDecoded.polyline.map((p) => ({ lat: p[0], lng: p[1] })),
    //     returnPath: returnPathDecoded.polyline.map((p) => ({ lat: p[0], lng: p[1] })),
    //   };
    // },
    optSelectedPersonnel(state, getters) {
      return state.optSelectedRoutes.map((rid) => {
        const r = getters.routeListMap.get(rid);
        const personnelByRoute = getters.personnelByRoute[rid] || [];

        return {
          route: {
            id: r.id,
            name: r.name,
          },
          personnel: personnelByRoute.map((p) => ({
            id: p.personnel.id,
            name: p.personnel.name,
            lastName: p.personnel.surname,
          })),
        };
      });
    },
    optSelectedPersonnelWithoutRoute(state) {
      return state.optSelectedUnassigned.map((pid) => ({
        id: state.personnelMap[pid].personnel.id,
        name: state.personnelMap[pid].personnel.name,
        lastName: state.personnelMap[pid].personnel.surname,
      }));
    },
    optSelectedPersonnelCount(state, getters) {
      const p = getters.optSelectedPersonnel.reduce((pre, cur) => pre + cur.personnel.length, 0);
      const pNoRoute = getters.optSelectedPersonnelWithoutRoute.length;
      return p + pNoRoute;
    },
    allStations(state) {
      return state.allStations;
    },
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
