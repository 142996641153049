<template>
  <component :is="layout" />
</template>

<script>
import blank from './Blank.vue';
import flexigo from './Flexigo.vue';

export default {
  name: 'ShellChooser',
  components: { blank, flexigo },
  computed: {
    layout() {
      return this.$store.getters.shell;
    },
  },
};
</script>

<style>

</style>
